const Dropdown: React.FC<any> = ({
  setSelectedOptionTimePeriod,
  selectedOptionTimePeriod,
}: any) => {
  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOptionTimePeriod(e.target.value)
  }

  return (
    <div className='relative inline-block'>
      <select
        className='block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline'
        value={selectedOptionTimePeriod}
        onChange={handleOptionChange}
      >
        <option value='Weekly'>Weekly</option>
        <option value='Daily'>Daily</option>
        <option value='Monthly'>Monthly</option>
      </select>
      {/* <DynamicPopup/> */}
      <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
        <svg
          className='fill-current h-4 w-4'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 20 20'
        >
          <path d='M10 12L4 6h12l-6 6z' className='text-gray-600'></path>
        </svg>
      </div>
    </div>
  )
}

export default Dropdown
