import clsx from 'clsx'

export const TruncatedText = ({ text, maxNum, className }: any) => {
  if (!text) {
    return null
  }

  return (
    <p className={clsx(className && className)}>
      {text.length > maxNum ? `${text.slice(0, maxNum)}...` : text}
    </p>
  )
}
