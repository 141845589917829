import clsx from 'clsx'

type InputWithIconProps = {
  type?: string
  img?: string
  imgAlt?: string
  placeholder?: string
  id?: number | string
  className?: string
}

const InputWithIcon = (props: InputWithIconProps) => {
 
  return (
    <div
      className={clsx(
        'h-10  border rounded-md bg-white center relative',
        props.className ? props.className : 'w-[330px] ',
      )}
    >
      <div className='absolute left-5'>
        <img className='w-5 h-5' src={props.img} alt={props.imgAlt} />
      </div>
      <input
        className={clsx(
          'w-full h-full pl-14 rounded-md focus:outline-none ',
          props.className && props.className,
        )}
        type={props.type}
        placeholder={props.placeholder}
      />
    </div>
  )
}

export default InputWithIcon
