import clsx from 'clsx'
import React from 'react'

type ButtonLoginProps = {
  text: string
  id: string
  className?: string
  onClick?: () => void
}

const ButtonLogin = (props: ButtonLoginProps) => {
  return (
    <button
      onClick={props.onClick}
      id={props.id}
      className={clsx(
        'text-white  py-2.5 px-8 bg-[#2563EB] rounded-md',
        props.className && props.className,
      )}
    >
      {props.text}
    </button>
  )
}

export default ButtonLogin
