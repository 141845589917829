import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import {ApiBaseUrl} from '../../app/environment'


export const fetchTimeDataForProject = createAsyncThunk(
  'fetchTimeDataForProject',
  async function (projectId: any, { rejectWithValue }) {
    try {
      const projectFromLocalStorage = localStorage.getItem('selectedProject')
      let id
      if (projectFromLocalStorage) {
        const project = JSON.parse(projectFromLocalStorage)
        if (project && project._id) {
          id = project._id
        }
      }
      const url = `https://52.28.131.182/api/get/project/analytics/wasted-and-spent-time/${
        projectId || id
      }`
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const timeMeasurementSlice = createSlice({
  name: 'timeMeasurementSlice',
  initialState: {
    data: [],
    error: null,
    status: 'idle',
  },
  reducers: {},
  extraReducers: {
    [fetchTimeDataForProject.pending.type](state, action) {
      state.status = 'pending'
      state.error = null
    },
    [fetchTimeDataForProject.fulfilled.type](state, action) {
      state.status = 'fulfilled'
      state.data = action.payload
      state.error = null
    },
    [fetchTimeDataForProject.rejected.type](state, action) {
      state.status = 'rejected'
      state.error = action.payload
      state.data = []
    },
  },
})

export default timeMeasurementSlice.reducer
