interface reportBlockInterface {
  id: number
  name: string
}

export const reportBlock1: reportBlockInterface[] = [
  { id: 1, name: 'Defects' },
  { id: 2, name: 'Humidity' },
  { id: 3, name: 'Temperature' },
  { id: 4, name: 'Pressure' },
  { id: 5, name: 'Noise' },
]
export const reportBlock2: reportBlockInterface[] = [
  { id: 1, name: 'Safety & Health Level' },
  { id: 2, name: 'Weather Forecast' },
  { id: 3, name: 'Site Health' },
  { id: 4, name: 'Defects' },
  { id: 5, name: 'Noise' },
]

export const reportBlock3: reportBlockInterface[] = [
  { id: 1, name: 'Safety & Health Level' },
  { id: 2, name: 'Humidity' },
  { id: 3, name: 'Temperature' },
  { id: 4, name: 'Pressure' },
  { id: 5, name: 'Noise' },
]

export const reportBlock4: reportBlockInterface[] = [
  { id: 1, name: 'Team1' },
  { id: 2, name: 'Team2' },
  { id: 3, name: 'Team3' },
  { id: 4, name: 'Team4' },
  { id: 5, name: 'Team5' },
]
