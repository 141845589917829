export const defects = [
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
  { name: 'Roof cracks', amount: 22 },
  { name: 'Leaking plumbing', amount: 12 },
  { name: 'Faulty electrical systems', amount: 33 },
  { name: 'Defective external wall finishes', amount: 31 },
]

export const defects2 = [
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
  { name: 'Leaking plumbing', amount: 55 },
  { name: 'Roof cracks', amount: 44 },
  { name: 'Defective external wall finishes', amount: 33 },
  { name: 'Faulty electrical systems', amount: 11 },
]

export const defects3 = [
  { name: 'Faulty electrical systems', amount: 322 },
  { name: 'Leaking plumbing', amount: 111 },
  { name: 'Defective external wall finishes', amount: 221 },
  { name: 'Roof cracks', amount: 23 },
  { name: 'Faulty electrical systems', amount: 322 },
  { name: 'Leaking plumbing', amount: 111 },
  { name: 'Defective external wall finishes', amount: 221 },
  { name: 'Roof cracks', amount: 23 },
  { name: 'Faulty electrical systems', amount: 322 },
  { name: 'Leaking plumbing', amount: 111 },
  { name: 'Defective external wall finishes', amount: 221 },
  { name: 'Roof cracks', amount: 23 },
  { name: 'Faulty electrical systems', amount: 322 },
  { name: 'Leaking plumbing', amount: 111 },
  { name: 'Defective external wall finishes', amount: 221 },
  { name: 'Roof cracks', amount: 23 },
  { name: 'Faulty electrical systems', amount: 322 },
  { name: 'Leaking plumbing', amount: 111 },
  { name: 'Defective external wall finishes', amount: 221 },
  { name: 'Roof cracks', amount: 23 },

  { name: 'Faulty electrical systems', amount: 322 },
  { name: 'Leaking plumbing', amount: 111 },
  { name: 'Defective external wall finishes', amount: 221 },
  { name: 'Roof cracks', amount: 23 },
]

export const defects4 = [
  { name: 'Faulty electrical systems', amount: 123 },
  { name: 'Defective external wall finishes', amount: 323 },
  { name: 'Leaking plumbing', amount: 32 },
  { name: 'Roof cracks', amount: 321 },
  { name: 'Faulty electrical systems', amount: 123 },
  { name: 'Defective external wall finishes', amount: 323 },
  { name: 'Leaking plumbing', amount: 32 },
  { name: 'Roof cracks', amount: 321 },
  { name: 'Faulty electrical systems', amount: 123 },
  { name: 'Defective external wall finishes', amount: 323 },
  { name: 'Leaking plumbing', amount: 32 },
  { name: 'Roof cracks', amount: 321 },
  { name: 'Faulty electrical systems', amount: 123 },
  { name: 'Defective external wall finishes', amount: 323 },
  { name: 'Leaking plumbing', amount: 32 },
  { name: 'Roof cracks', amount: 321 },
  { name: 'Faulty electrical systems', amount: 123 },
  { name: 'Defective external wall finishes', amount: 323 },
  { name: 'Leaking plumbing', amount: 32 },
  { name: 'Roof cracks', amount: 321 },
  { name: 'Faulty electrical systems', amount: 123 },
  { name: 'Defective external wall finishes', amount: 323 },
  { name: 'Leaking plumbing', amount: 32 },
  { name: 'Roof cracks', amount: 321 },
]

export const defectDisplay = [
  {
    id: 1,
    name: 'Roofing cracks',
    date: '01/02/2023 13:30am',
    assignee: 'Lazar Jevric',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 2,
    name: 'Leaking plumbing',
    date: '09/02/2023 13:30am',
    assignee: 'Ralph Rust',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 3,
    name: 'Faulty electrical systems',
    date: '15/02/2023 13:30am',
    assignee: 'Anton Le',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 4,
    name: 'Leaking plumbing',
    date: '11/02/2023 13:30am',
    assignee: 'Wolter White',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 5,
    name: 'Faulty electrical systems electrical systems',
    date: '14/02/2023 13:30am',
    assignee: 'Sebastian Rust',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 6,
    name: 'Cracked foundation',
    date: '18/02/2023 13:30am',
    assignee: 'Emma Johnson',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 7,
    name: 'Mold growth in the basement',
    date: '25/02/2023 13:30am',
    assignee: 'Oliver Reed',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 8,
    name: 'Broken window',
    date: '07/03/2023 13:30am',
    assignee: 'Sophia Martinez',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 9,
    name: 'Malfunctioning HVAC system',
    date: '15/03/2023 13:30am',
    assignee: 'Jackson Carter',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 10,
    name: 'Clogged drains',
    date: '22/03/2023 13:30am',
    assignee: 'Isabella Lee',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 11,
    name: 'Cracked tiles in the bathroom',
    date: '02/04/2023 13:30am',
    assignee: 'Ethan Wilson',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 12,
    name: 'Flickering lights in the living room',
    date: '08/04/2023 13:30am',
    assignee: 'Ava Thompson',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 13,
    name: 'Water stains on the ceiling',
    date: '15/04/2023 13:30am',
    assignee: 'Liam Davis',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 14,
    name: 'Squeaky floorboards in the hallway',
    date: '21/04/2023 13:30am',
    assignee: 'Mia Wilson',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
  {
    id: 15,
    name: 'Faulty door lock',
    date: '28/04/2023 13:30am',
    assignee: 'Noah Thompson',
    status: 'completed',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipis  dolor sit amet, consectetur adipisdolor sit amet, consectetur adipisdolor sit amet, consectetur adipis',
  },
]
