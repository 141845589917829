import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { store } from './app/store'
import { Provider } from 'react-redux'
// import 'antd/dist/antd.css';  // Import CSS for the whole library
// import 'antd/dist/antd-reset.css'; // Import reset.css
// test
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
)
