import { X } from 'lucide-react'
import doneIcon from 'images/tasks/done.svg'
import conflictIcon from 'images/tasks/conflict.svg'
import GoogleMapsZoneArea from '@components/tasksComponents/GoogleMapsZoneArea'

import { useJsApiLoader } from '@react-google-maps/api'
import GoogleMaps from '@components/mapsComponents/GoogleMaps'
export const defaultCenter = {
  lat: 59.419961,
  lng: 24.711284,
}
const libraries: ('places' | 'visualization')[] = ['places']
const API_KEY = process.env.REACT_APP_API_KEY as string

const GeneralProjectInfoTabContent = ({ selectedTaskInfo, selectedTaskSkills }: any) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries,
  })
  return (
    <div>
      <div className='w-[340px] mb-5'>
        <div className='flex justify-between mb-2'>
          <label className='' htmlFor='project-input'>
            Project name:
          </label>
          <div>
            <button className='text-blue border-b-[2px] border-blue leading-4'>Edit name</button>
          </div>
        </div>
        <div>
          <input
            className='bg-white w-full py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
            placeholder='ex. Project 1'
          />
        </div>
      </div>
      <div className='flex justify-between mb-12'>
        <div className='w-[57%] '>
          <div className='flex justify-between items-center mb-2'>
            <h5 className='font-medium'>Description</h5>
            <button className='text-blue leading-4 border-b-[2px] border-blue'>
              Edit description
            </button>
          </div>
          <div className='bg-white rounded-md h-[230px]'>
            <p className='px-2 py-4'>{selectedTaskInfo && selectedTaskInfo.task_description}</p>
          </div>
        </div>
        <div className='w-[40%] h-[230px]'>
          <div className='flex justify-between items-center mb-2'>
            <h5 className='font-medium'>Task Location</h5>
            <button className='text-blue leading-4 border-b-[2px] border-blue'>
              Change Location
            </button>
          </div>
          {isLoaded ? (
            <GoogleMaps center={defaultCenter} />
          ) : (
            <h2 className='center text-2xl'>Loading...</h2>
          )}
        </div>
      </div>
    </div>
  )
}

export default GeneralProjectInfoTabContent
