import React, { useState, useEffect } from 'react';
import './calendar.css';
import Sidebar from '@components/reusable/sidebar/Sidebar';
import Header from '@components/reusable/header/Header';

const Calendar = () => {
    const isLeapYear = (year: number): boolean => {
        return (
            (year % 4 === 0 && year % 100 !== 0 && year % 400 !== 0) ||
            (year % 100 === 0 && year % 400 === 0)
        );
    };

    const getFebDays = (year: number): number => {
        return isLeapYear(year) ? 29 : 28;
    };

    const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth());
    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
    const [calendarDays, setCalendarDays] = useState<(number | string)[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    useEffect(() => {
        generateCalendar(currentMonth, currentYear);
    }, [currentMonth, currentYear]);

    const generateCalendar = (month: number, year: number): void => {
        const daysOfMonth = [
            31, getFebDays(year), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31
        ];

        const firstDay = new Date(year, month, 1).getDay();
        const daysArray = [];

        for (let i = 0; i < firstDay; i++) {
            daysArray.push("");
        }

        for (let i = 1; i <= daysOfMonth[month]; i++) {
            daysArray.push(i);
        }

        setCalendarDays(daysArray);
    };

    const changeYear = (direction: number): void => {
        setCurrentYear((prev) => prev + direction);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const getCurrentFormattedDate = (): string => {
        return currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long'
        });
    };

    const getCurrentFormattedTime = (): string => {
        return currentDate.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    };

    return (
        <div className="container font-inter h-screen w-screen text-[#374151]" >
            <nav>
                <Sidebar />
            </nav>
            <div className="flex flex-col w-[calc(100%-121px)] relative left-[330px]">
                <Header />
            </div>
            <div style={{ marginLeft: "330px", width: "74vw" }} className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="faq-title text-center">
                            <h2 style={{ marginTop: "20px", marginBottom: "35px"}}>Calendar</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ width: "73vw", marginLeft: "330px" }} className='calendarContainer'>
                <div style={{ left: "250px" }} className="calendar w-[calc(100%-330px)] relative left-[330px]">
                    <div className="calendar-header flex-auto my-12">
                        <div className="year-picker">
                            <span className="year-change" onClick={() => changeYear(-1)}>&lt;</span>
                            <span>{currentYear}</span>
                            <span className="year-change" onClick={() => changeYear(1)}>&gt;</span>
                        </div>
                    </div>

                    <div className="calendar-body">
                        <div className="calendar-week-days">
                            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
                                <div key={day}>{day}</div>
                            ))}
                        </div>
                        <div className="calendar-days">
                            {calendarDays.map((day, index) => (
                                <div key={index} className={day === currentDate.getDate() && currentYear === currentDate.getFullYear() && currentMonth === currentDate.getMonth() ? 'current-date' : ''}>
                                    {day}
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="date-time-formate">
                        <div className="day-text-formate">TODAY</div>
                        <div className="date-time-value">
                            <div className="time-formate">{getCurrentFormattedTime()}</div>
                            <div className="date-formate">{getCurrentFormattedDate()}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Calendar;
