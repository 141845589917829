import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Tabs from '../Tabs'
import defaultUserImg from 'images/profile/defaultUserImg.webp'
import phaseIcon from 'images/tasks/phaseIcon.svg'
import DynamicPopup from '@components/reusable/popup/DynamicPopup'
import { getPhases } from 'feature/phasesSlice'
import arrowDownWithoutFrames from 'images/navbar/arrowDownWithoutFrames.svg'
import plusIcon from 'images/tasks/plusIcon.svg'
import { createTask, getSelectedTask } from 'feature/tasksSlice'
import GeneralTaskInfoCreateTaskTabContent from './CreateGeneralTaskInfoTabContent'
import clsx from 'clsx'
import { Button } from 'antd'
import { PenSquare } from 'lucide-react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

// tabs
export const tabs = [
  {
    id: 0,
    name: 'General Task Information',
  },
  {
    id: 1,
    name: 'Assignes',
  },
  {
    id: 2,
    name: 'Tools',
  },
]

const CreateTask = () => {
  const dispatch: any = useDispatch()
  const navigate = useNavigate()
  const [activeTabId, setActiveTabId] = useState<number>(tabs[0].id)

  const phasesData = useSelector((state: any) => state.phases.data)
  const selectedProject = useSelector((state: any) => state.projects.selectedProject?.comp_prj_id)
  const selectedTaskSkills = useSelector(
    (state: any) => state.tasksInfo.selectedTask[0]?.task_skills_requires,
  )
  const selectedTaskInfo = useSelector((state: any) => state.tasksInfo.selectedTask[0])

  const selectedTaskId = sessionStorage.getItem('taskId')

  useEffect(() => {
    if (selectedProject) {
      dispatch(getSelectedTask({ taskId: selectedTaskId, projectId: selectedProject }))
      dispatch(getPhases(selectedProject))
    }
  }, [selectedProject, selectedTaskId])

  //  ?  -------

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>()

  const onCreateTask = (data: any) => {
    if (selectedProject) {
      try {
        const taskData = {
          data,
          selectedProject,
        }
        dispatch(createTask(taskData))
        navigate('/tasks')
        reset()
      } catch (error) {
        console.error('Error:', error)
      }
    }
  }

  return (
    <div className='flex font-inter h-screen w-screen text-[#374151] '>
      <Sidebar />
      <div className='flex flex-col w-[calc(100%-330px)]  relative left-[330px] h-screen'>
        <Header />

        <form className='flex-auto'>
          <div className='w-full max-w-[1200px] mx-auto my-0'>
            <div className='my-12'>
              <Breadcrumbs items={['Tasks', 'Create a new task']} />
            </div>

            <div className='mb-8'>
              <div className='flex justify-between'>
                <div className='flex'>
                  <input
                    placeholder='Write a task name'
                    className='bg-[#EFF6FF] text-2xl font-medium  text-black rounded-md focus:outline-none mr-4'
                    {...register('task_name', { required: true })}
                  />
                  {errors.task_name?.type === 'required' && (
                    <p className='text-[#8b2d2d] py-2' role='alert'>
                      Task name is required
                    </p>
                  )}

                  <button type='button' className=''>
                    <PenSquare color='#2265E8' />
                  </button>
                </div>

                <div>
                  <DynamicPopup
                    topicNameItems={phasesData}
                    initialTextSelectDynamic='Add a phase'
                    dropdownPosition='top-[60px] left-[-20px]'
                    selectedTextStyle='pl-2 pr-4'
                    img1Dynamic={plusIcon}
                    img2Dynamic={phaseIcon}
                    img2={arrowDownWithoutFrames}
                  />
                  <Button
                    onClick={handleSubmit(onCreateTask)}
                    style={{ backgroundColor: '#2265E8', fontSize: '15px' }}
                    type='primary'
                  >
                    Create a task
                  </Button>
                </div>
              </div>
              <div className='relative mb-8'>
                <div className='bg-white px-3 py-2 flex flex-col absolute right-[20px] rounded-md'>
                  <div className='bg-[#EBE2FF] mb-2 px-12 py-1 rounded-md font-medium'>
                    <label htmlFor='start-date'>Start:</label>

                    <input
                      id='start-date'
                      type='date'
                      className='bg-[#EBE2FF]'
                      {...register('start_date', { required: true })}
                    />
                    {errors.start_date?.type === 'required' && (
                      <p className='text-[#8b2d2d] py-2' role='alert'>
                        Start data is required
                      </p>
                    )}
                  </div>

                  <div className='bg-[#AEFFF5] mb-2 px-12 py-1 rounded-md font-medium'>
                    <label htmlFor='finish_date'>End:</label>
                    <input
                      id='finish_date'
                      type='date'
                      className='bg-[#AEFFF5]'
                      {...register('finish_date', { required: true })}
                    />
                    {errors.finish_date?.type === 'required' && (
                      <p className='text-[#8b2d2d] py-2' role='alert'>
                        End data is required
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className='flex items-center'>
                <div className='w-12'>
                  <img src={defaultUserImg} alt='user-icon' />
                </div>
                <div className='ml-2'>
                  <p className='text-base'>Lazar Jevric</p>
                  <p className='text-base text-[#6C6C6C]'>Project Manager @Phare Technologies</p>
                </div>
              </div>
            </div>

            <div className='py-5 rounded-lg bg-white pl-5 mb-12'>
              <Tabs tabs={tabs} activeTabId={activeTabId} setActiveTabId={setActiveTabId} />
            </div>

            {/* General Task Info Tab */}

            <div className={clsx('w-full', activeTabId === 0 ? 'block' : 'hidden')}>
              <GeneralTaskInfoCreateTaskTabContent
                selectedTaskInfo={selectedTaskInfo}
                selectedTaskSkills={selectedTaskSkills}
              />
            </div>

            <div className={clsx('w-full', activeTabId === 1 ? 'block' : 'hidden')}>
              {/* <AssigneTabContent selectedTaskInfo={selectedTaskInfo} /> */}assigne
            </div>

            <div className={clsx('w-full', activeTabId === 2 ? 'block' : 'hidden')}>Tools</div>
          </div>
        </form>

        <Footer />
      </div>
    </div>
  )
}

export default CreateTask
