import { X } from 'lucide-react'
import doneIcon from 'images/tasks/done.svg'
import conflictIcon from 'images/tasks/conflict.svg'
import GoogleMapsZoneArea from '@components/tasksComponents/GoogleMapsZoneArea'
import { Button, Modal } from 'antd'
import { useJsApiLoader } from '@react-google-maps/api'
import { useState } from 'react'

const libraries: ('places' | 'visualization')[] = ['places']
const API_KEY = process.env.REACT_APP_API_KEY as string

const CreateGeneralTaskInfoTabContent = ({ selectedTaskInfo, selectedTaskSkills }: any) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries,
  })

  return (
    <div>
      <div className='flex justify-between mb-12'>
        <div className='w-[57%] '>
          <div className='flex justify-between items-center mb-2'>
            <h5 className='font-medium'>Description</h5>
            <button className='text-blue leading-4 border-b-[2px] border-blue'>
              Edit description
            </button>
          </div>
          <div className='bg-white rounded-md h-[230px]'>
            <p className='px-2 py-4'>{selectedTaskInfo && selectedTaskInfo.task_description}</p>
          </div>
        </div>
        <div className='w-[40%] h-[230px]'>
          <div className='flex justify-between items-center mb-2'>
            <h5 className='font-medium'>Task Location</h5>
            <button className='text-blue leading-4 border-b-[2px] border-blue'>
              Edit Location
            </button>
          </div>
          {isLoaded && selectedTaskInfo ? (
            <GoogleMapsZoneArea zoom={19} zoneArea={selectedTaskInfo.task_defaultCenter} />
          ) : (
            <h2 className='center text-2xl'>Loading...</h2>
          )}
        </div>
      </div>
      <div>
        <div className='flex justify-between'>
          <h5 className='font-medium mb-2'>Skills</h5>
          <div>
            <button className='text-blue leading-4 border-b-[2px] border-blue'>Edit skills</button>
          </div>
        </div>
        <div className='bg-white rounded-md h-[200px] p-5 overflow-auto mb-5'>
          {selectedTaskSkills &&
            selectedTaskSkills.map((skill: any, index: number) => {
              return (
                <div
                  style={{ background: skill.skill_color }}
                  className='inline-block relative px-2 py-1 text-black rounded-lg m-3 font-medium'
                  key={index}
                >
                  <div className='flex'>
                    <p className='mr'>{skill.skill_name}</p>
                    <p className='mr-2'>({`${skill.hours_req}h`})</p>
                    <button className=''>
                      <X color='#1e1a1a' />
                    </button>
                  </div>
                  {Number(skill.hours_req) - Number(skill.hours_covered) == 0 ? (
                    <div className='absolute flex right-0 top-9'>
                      <img className='mr-1' src={doneIcon} alt='done' />
                      <p className='text-[#009721] text-sm'>Done</p>
                    </div>
                  ) : (
                    <div className='absolute flex right-0 top-9'>
                      <img className='mr-1' src={conflictIcon} alt='conflict' />
                      <p className='text-[#FF0000] text-sm'>
                        Conflict {Number(skill.hours_req) - Number(skill.hours_covered)} hours
                      </p>
                    </div>
                  )}
                </div>
              )
            })}
        </div>
      </div>
      {/* dependancies */}
      <div className='bg-white rounded-md h-[320px] px-5 pt-8 mb-32'>
        <div className='flex justify-between items-center mb-3'>
          <h5 className='font-medium'>Dependency</h5>
          <button className='text-blue leading-4 border-b-[2px] border-blue'>
            Edit dependency
          </button>
        </div>

        <div className='flex'>
          <div className='w-3/6'>
            <div className='w-full h-[50px] border border-[#B6B6B6B]'>
              <p className='font-medium pl-3 pt-3'>Deadline: 12/04/2023</p>
            </div>
            <div className='flex'>
              <p className='w-4/6 border border-[#B6B6B6B] py-3 pl-3'>Task name</p>
              <p className='w-2/6 border border-[#B6B6B6B] py-3 pl-3'>Dependency status</p>
            </div>
            <div className='flex'>
              <p className='w-4/6 border border-[#B6B6B6B] py-3 pl-3'>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores eius impedit
                eos doloremque
              </p>
              <p className='w-2/6 border border-[#B6B6B6B] py-3 pl-3'>In progress</p>
            </div>
          </div>
          <div className='w-3/6'>
            <div className='w-full h-[50px] border border-[#B6B6B6B]'>
              <p className='font-medium pl-3 pt-3'>Deadline: 12/04/2023</p>
            </div>
            <div className='flex'>
              <p className='w-4/6 border border-[#B6B6B6B] py-3 pl-3'>Task name</p>
              <p className='w-2/6 border border-[#B6B6B6B] py-3 pl-3'>Dependency status</p>
            </div>
            <div className='flex'>
              <p className='w-4/6 border border-[#B6B6B6B] py-3 pl-3'>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Asperiores eius impedit
                eos doloremque
              </p>
              <p className='w-2/6 border border-[#B6B6B6B] py-3 pl-3'>In progress</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateGeneralTaskInfoTabContent
