import clsx from 'clsx'

interface CheckboxProps {
  id: number
  name: string
  checked?: boolean
  onChange: (id: number, checked: boolean) => void
  className?: string
}

function Checkbox({ id, name, className, checked = false, onChange }: CheckboxProps) {
  return (
    <div
      className={clsx(
        'w-[350px] h-[60px] mb-4 flex items-center bg-white',
        className && `${className}`,
      )}
      key={id}
    >
      <div className=' ml-5 center'>
        <input
          type='checkbox'
          className='h-5 w-5 mr-3'
          id={id.toString()}
          checked={checked}
          onChange={(e) => onChange(id, e.target.checked)}
        />
        <label className='form-check-label ms-2'>{name}</label>
      </div>
    </div>
  )
}

export default Checkbox
