import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiBaseUrl } from 'app/environment'
import axios from 'axios'
import axiosInstance from 'axiosInstance'

interface initialStateType {
  data: any
  loading: boolean
  error: null
}
const initialState: initialStateType = {
  data: [],
  loading: false,
  error: null,
}

export const getEmployyes = createAsyncThunk(
  'getEmployyes/employyesSlice',
  async (id: any, { rejectWithValue }) => {
    // console.log('id >', id)
    try {
      const ida = '64672c7af59ec80e22024917'
      const url = `/api/company/${id}/employees`
      const response = await axiosInstance.get(url)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const employyesSlice = createSlice({
  name: 'employyes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployyes.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getEmployyes.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(getEmployyes.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as null
        state.data = []
      })
  },
}) 

export default employyesSlice.reducer
