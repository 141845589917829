import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'

export const getSkills = createAsyncThunk('getSkills/skillsSlice', async (id: string) => {
  try {
    const url = `api/projects/${id}/skills`
    const response = await axiosInstance.get(url)

    return response.data
  } catch (error) {
    console.log(error)
  }
})

const initialState = {
  data: [],
  error: null,
  loading: false,
}

const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSkills.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
      state.error = null
    })
  },
})

export default skillsSlice.reducer
