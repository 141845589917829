import { Pie } from '@ant-design/plots'

const SafetyPieChart = () => {
  const data = [
    {
      type: 'Equipment Safety',
      value: 67,
    },
    {
      type: 'Electrical Safety',
      value: 33,
    },
  ]
  const config: any = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    color: ['#F94144', '#F3722C'],
    radius: 0.8,
    legend: 'bottom',
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
  }
  return <Pie {...config} />
}

export default SafetyPieChart
