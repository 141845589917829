import { useState } from 'react'
import clsx from 'clsx'

import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'

import defectsImg from 'images/temporaryDrafts/defects.svg'
import { tabs } from '@components/defectsComponents/DefectsConstants'
import DefectsTabContent from '@components/defectsComponents/DefectsTabContent'
import AddNewDeffect from '@components/modalProjectInfo/AddNewDeffect'

const DefectsPage = () => {
  const [activeTabId, setActiveTabId] = useState(0)

  const handleClick = (tabId: any) => {
    setActiveTabId(tabId)
  }

  return (
    <div>
      <nav>
        <Sidebar />
      </nav>
      <div className='flex flex-col w-[calc(100%-330px)] relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto mt-12'>
          <div className='w-full max-w-[1200px] mx-auto my-0 '>
            <img className='w-full' src={defectsImg} alt='defectsImg' />
            <AddNewDeffect />
      
            <div className='my-12'>
              <div className='my-12'>
                {/* tabs */}
                <nav className='flex mb-5'>
                  {tabs.map((tab) => (
                    <button
                      className={clsx(
                        'border px-4 py-3 flex rounded-md mr-5 hover:bg-[#B5CEFD] cursor-pointer',
                        tab.id === activeTabId ? 'bg-[#B5CEFD]' : '',
                      )}
                      key={tab.id}
                      onClick={() => handleClick(tab.id)}
                    >
                      <img
                        className='mr-2'
                        src={tab.img}
                        alt={tab.name}
                        style={{ fill: '#B5CEFD' }}
                      />
                      <p>{tab.name}</p>
                    </button>
                  ))}
                </nav>

                <DefectsTabContent activeTabId={activeTabId} />
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default DefectsPage
