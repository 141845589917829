import iconDefault from 'images/profile/defaultUserImg.webp'
import doneMarkIcon from 'images/tasks/doneMark.svg'
import arrowDownIcon from 'images/navbar/arrowDown.svg'
import exclamationMarkIcon from 'images/tasks/exclamationMark.svg'
import { Plus, X } from 'lucide-react'
import { useSelector } from 'react-redux'
import SkillsDropdown from './SkillsDropdown'
import { useEffect, useState } from 'react'
import { Pagination } from 'antd'
import AddAssigneModal from './edit/EditAddAssigneModal'

const AssigneTabContent = ({ selectedTaskInfo }: any) => {
  const [isOpen, setIsOpen] = useState(false)
  const [topicName, setTopicName] = useState<any>('Skills')
  const [filteredAssignees, setFilteredAssignees] = useState<any[]>([])
  const [value, setValue] = useState(0)
  const [paginationItems, setPaginationItems] = useState<any[]>([])

  const [total, setTotal] = useState<any>('')
  const [page, setPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(5)

  const selectedTaskSkills = useSelector(
    (state: any) => state.tasksInfo.selectedTask[0]?.task_skills_requires,
  )

  useEffect(() => {
    // Function to filter and sort assignees based on the selected topicName
    const filterAndSortAssignees = () => {
      if (selectedTaskInfo && selectedTaskSkills) {
        if (topicName === 'Skills') {
          setFilteredAssignees(selectedTaskInfo.task_assignees)
        } else {
          const filtered = selectedTaskInfo.task_assignees.filter((assignee: any) => {
            return assignee.assignee_skill === topicName
          })

          // // Sort the filtered assignees based on some criteria (you can customize this)
          // const sorted = filtered.sort((a: any, b: any) => {
          //   // Customize the sorting logic here based on your needs
          //   // For example, you can sort by assignee name or hours
          //   // This example sorts by assignee name in ascending order
          //   return a.assignee_name.localeCompare(b.assignee_name)
          // })

          setFilteredAssignees(filtered)
        }
      }
    }

    filterAndSortAssignees()
  }, [selectedTaskInfo, selectedTaskSkills, topicName])

  // Pagination functionality
  useEffect(() => {
    if (filteredAssignees) {
      setPaginationItems(filteredAssignees)
      setTotal(filteredAssignees.length)
    }
  }, [filteredAssignees, value])

  // Calculate pagination indexes and list of paginationItems for current page

  const indexOfLastPage = postPerPage * page
  const indexOfFirstPage = indexOfLastPage - postPerPage
  const displayedPaginationItems = paginationItems.slice(indexOfFirstPage, indexOfLastPage)

  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }

  return (
    <>
      <div className='mb-24'>
        <div className='w-full h-[120px] rounded-md bg-white mb-5 flex items-center px-6 justify-between'>
          <div className='flex-shrink max-w-[250px]'>
            <SkillsDropdown
              topicNameItems={selectedTaskSkills}
              dropdownPosition='top-[60px] left-[0px]'
              img2={arrowDownIcon}
              topicName={topicName}
              setTopicName={setTopicName}
              prefix='skill'
            />
          </div>
          {/* <AddAssigneModal isOpen={isOpen} setIsOpen={setIsOpen} /> */}
        </div>
        {/* red toaster-conflict */}
        <div className='border border-[#FF0000] rounded-md bg-[#F8E3E7] w-[55%] px-3 py-3 flex mb-8'>
          <div className='mr-4'>
            <button className=''>
              <img className='w-8' src={exclamationMarkIcon} alt='exclamation' />
            </button>
          </div>
          <div>
            <div className='flex items-center justify-between mb-4'>
              <div className='flex items-center'>
                <p className='mr-5 font-medium'>There is conflict 2h for skill</p>
                <p className='font-medium'>Carpentry</p>
              </div>
              <button className='bg-white rounded-full p-1 center'>
                <X size={16} />
              </button>
            </div>
            <div>
              <p>
                Some message that explains that more manpower would be needed for this spcific skill
                or any other action resolve it.
              </p>
            </div>
          </div>
        </div>
        {/* green toaster-resolved-conflict */}
        <div className='border border-[#009721] rounded-md bg-[#D9EEEB] w-[55%] px-3 py-3 flex mb-8'>
          <div className='mr-4'>
            <button className=''>
              <img className='w-8' src={doneMarkIcon} alt='done' />
            </button>
          </div>
          <div>
            <div className='flex items-center justify-between mb-4'>
              <div className='flex items-center'>
                <p className='mr-5 font-medium'>Conflict is resolved for skill</p>
                <p className='font-medium'>Carpentry</p>
              </div>
              <button className='bg-white rounded-full p-1 center'>
                <X size={16} />
              </button>
            </div>
            <div>
              <p>
                Some message that explains that conflict is resolved and in case we have more then 1
                skill, name all the skills..
              </p>
            </div>
          </div>
        </div>

        {/* header */}
        <div className='flex items-center bg-white py-6 pl-4 font-medium border-b'>
          <div className='flex-shrink w-10 '>
            <input className='w-6 h-4' type='checkbox' />
          </div>
          <div className='flex-shrink w-24 ml-1'>Image</div>
          <div className='flex-1'>Full name</div>
          <div className='flex-1'>Position</div>
          <div className='flex-1 '>Company</div>
          <div className='flex-1'>Skills</div>
          <div className='flex-shrink w-32'>Hours</div>
        </div>

        {/* body */}

        {displayedPaginationItems && (
          <ul className=''>
            {displayedPaginationItems.map((assigne: any, index: number) => {
              return (
                <li key={index} className='w-full py-8 bg-white border-b flex items-center'>
                  <input className='flex-shrink w-6 h-4 ml-4 mr-5' type='checkbox' />
                  <div className='w-10 flex-shrink mr-[53px]'>
                    <img src={iconDefault} className='w-full' alt='Assigne-icon' />
                  </div>
                  <p className='flex-1 pr-2'>
                    {assigne.assignee_name} {assigne.assignee_surname}
                  </p>
                  <p className='flex-1 pr-2'>{assigne.assignee_position}</p>
                  <p className='flex-1 pr-2'>{assigne.assignee_company_name}</p>
                  <div className='flex-1 pr-1'>
                    <p
                      style={{ background: assigne.assignee_color }}
                      className='inline-block relative px-2 py-1 text-black rounded-lg font-medium'
                    >
                      {assigne.assignee_skill}
                    </p>
                  </div>

                  <p className='flex-shrink w-32 pr-2'>{assigne.assignee_hours}</p>
                </li>
              )
            })}
          </ul>
        )}
        <div className='text-right mt-10'>
          <Pagination
            className='text-base'
            onChange={(value: any) => setPage(value)}
            pageSize={postPerPage}
            total={total}
            onShowSizeChange={onShowSizeChange}
            current={page}
          />
        </div>
      </div>
    </>
  )
}

export default AssigneTabContent
