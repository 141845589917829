import clsx from 'clsx'
import { useOnClickOutside } from 'hooks/useOnClickOutside '
import { useRef } from 'react'
import { createPortal } from 'react-dom'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
}

const Modal = ({ isOpen, onClose, children, className }: ModalProps) => {
 
  const ref = useRef<HTMLDivElement>(null)

  useOnClickOutside(ref, onClose)

  return isOpen
    ? createPortal(
        <div
        className={clsx(
          'fixed top-0 left-0 w-full h-[600] bg-[#1E1E1E] bg-opacity-75 flex justify-center items-center z-[10000]',
        )}
        
        >
          <div
            ref={ref}
            className={clsx(
              'bg-white rounded-lg shadow-lg p-6 h-[650px] overflow-auto w-[700px] relative',
              className,
            )}
          >
            {children}
          </div>
        </div>,
        document.body,
      )
    : null
}

export default Modal
