import { Pie, measureTextWidth } from '@ant-design/plots'
import { useEffect, useState } from 'react'

// interface projectsTabDataType {
//   type: string
//   value: number
// }
interface Props {
  projectsDonatData: { name: string; percentage: number }[]
}

const Donat = (props: Props) => {
  const [data, setData] = useState<{ name: string; percentage: number }[]>([])

  useEffect(() => {
    setData(props.projectsDonatData)
  }, [props.projectsDonatData])


  function renderStatistic(containerWidth: any, text: any, style: any) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style)
    const R = containerWidth / 2 // r^2 = (w / 2)^2 + (h - offsetY)^2
    let scale = 1

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
        ),
        1,
      )
    }

    const textStyleStr = `width:${containerWidth}px;`
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };">${text}</div>`
  }

  const config: any = {
    appendPadding: 10,
    data,
    angleField: 'percentage',
    colorField: 'name',
    radius: 0.7,
    innerRadius: 0.8,
    color: ['#EF4444', '#10B981', '#F59E0B', '#3B82F6', '#6366F1'],
    meta: {
      value: {
        formatter: (v: any) => `${v} %`,
      },
    },

    style: {
      background: '#ffffff',
      borderRadius: 8,
      width: 485,
    },

    legend: {
      position: 'right',

      marker: {
        symbol: 'circle',
        size: 15,
        fill: '#f00',
      },
    },

    label: {
      fontSize: 50,
      color: '#34d011',
    },

    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container: any, view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect()
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2))
          const text = datum ? datum.name : 'Status'
          return renderStatistic(d, text, {
            fontSize: 23,
          })
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '32px',
        },
        customHtml: (container: any, view: any, datum: any, data: any) => {
          const { width } = container.getBoundingClientRect()
          const text = datum
            ? ` ${datum.percentage} %`
            : ` ${data.reduce((r: any, d: any) => r + d.percentage, 0)} %`
          return renderStatistic(width, text, {
            fontSize: 32,
          })
        },
      },
    },

    interactions: [
      {
        name: 'element-selected',
      },
      {
        name: 'element-active',
      },
      {
        name: 'pie-statistic-active',
      },
    ],
  }
  return (
    <div className='w-[485px] h-[400px]'>
      <Pie {...config} />
    </div>
  )
}

export default Donat
