import React, { useEffect, useState } from 'react'
import { Gauge } from '@ant-design/plots'
import clsx from 'clsx'

interface Measurement {
  measurement: number
}

// const GaugePlot = (props: Measurement) => {
const GaugePlot = () => {
  const [percent, setPercent] = useState<any>(0.2)
  // useEffect(() => {
  //   setPercent(props.measurement / 100)
  // }, [props])
  const config = {
    width: 400,
    height: 200,
    percent,
    style: {
      borderRadius: '10%',
      //   borderColor: 'red', // add border color
      //   borderWidth: '5px', // add border width
    },
    range: {
      color: '#6CB540',
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: undefined,

    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontWeight: 700,
          fontSize: '40px',
          color: '#6B7280',
        },
        // formatter: (value: any) => `${(value.percent * 100).toFixed(0)}%`,
      },
    },
  }

  return (
    <div className='relative max-w-[300px]'>
      <Gauge {...config} />
      <span
        className={clsx(
          'text-4xl text-[#6B7280] font-bold absolute top-[120px]',
          percent >= 1 ? 'left-[110px]' : 'left-[145px]',
        )}
      >
        {percent * 10}
      </span>
      <div className='flex justify-between text-base font-bold mt-[-5px]'>
        <p>Low</p>
        <p>High</p>
      </div>
    </div>
  )
}

export default GaugePlot
