// main

import React,{ useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
// components

import WeatherApp from '@components/weatherApp/WeatherApp'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import PerformanceIndicator from '@components/reusable/charts/performanceIndicator/PerformanceIndicator'
import { Area } from '@ant-design/plots'
import toast, { Toaster } from 'react-hot-toast'
// images
import authConfig from 'authConfig'
import dashboard from '../../images/dashboard/dashboard.svg'
import arrowDown from '../../images/navbar/arrowDown.svg'
import Donat from '@components/reusable/charts/donat/Donat'
import DonatForTeams from '@components/reusable/charts/donat-for-teams/DonatForTeams'
import ChartDividerLine from '@components/dashboardComponents/ChartDividerLine'
import { useJsApiLoader } from '@react-google-maps/api'
import GoogleMaps from '@components/mapsComponents/GoogleMaps'
import GaugePlot from '@components/reusable/charts/gaugePlot/GaugePlot'
import Loader from '@components/reusable/loader/Loader'

//  PerformanceIndicator props
const target = 50,
  percentageChanged = 6,
  actualPercentage = 36.04,
  inTotal = 70

const defectsDonatData = [
  {
    name: 'Zero Defects',
    percentage: 14,
  },
  {
    name: 'Few Defects',
    percentage: 12,
  },
  {
    name: 'Acceptable Defects',
    percentage: 15,
  },
  {
    name: 'Needs Improvement',
    percentage: 16,
  },
  {
    name: 'Requires Rework',
    percentage: 17,
  },
]

const donatForTeamData = [
  {
    type: 'Delayed',
    value: 8,
  },
  {
    type: 'Canceled',
    value: 5,
  },
  {
    type: 'Overdue',
    value: 9,
  },
  {
    type: 'Upcoming',
    value: 30,
  },
  {
    type: 'Active',
    value: 25,
  },
]
// google map
interface center {
  lat: number
  lng: number
}

const defaultCenter: center = {
  lat: 59.419961,
  lng: 24.711284,
}

const libraries: ('places' | 'visualization')[] = ['places']
const API_KEY = process.env.REACT_APP_API_KEY as string

const DashboardPage = ({ auth, setAuth, userManager, userInfo, setUserInfo }: any) => {
  useEffect(() => {
    if (auth === null) {
      userManager
        .signinRedirectCallback()
        .then((user: any) => {
          if (user) {
            setAuth(true)
            const accessToken = user.access_token
            // Make a request to the user info endpoint using the access token
            fetch(authConfig.userinfo_endpoint, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
              .then((response) => response.json())
              .then((userInfo) => {
                setUserInfo(userInfo)
              })
          } else {
            setAuth(false)
          }
        })
        .catch((error: any) => {
          setAuth(false)
        })
    }
  }, [auth, userManager, setAuth])

  const projects = useSelector((state: any) => state.projects)

  const notify = () => toast('WIP')

  const location = useSelector((state: any) => state.projects.selectedProject?.location)
  const selectedProjectLocation = useSelector(
    (state: any) => state.selectedProjectData?.data[0]?.prj_location,
  )
  // console.log("DashboardPage ~ selectedProjectLocation >", selectedProjectLocation)

  // google maps
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries,
  })
  if (auth === true && userInfo) {
    return (
      <div className=' font-inter h-screen w-screen text-[#374151] '>
        <nav>
          <Sidebar />
        </nav>
        <div className='flex flex-col w-[calc(100%-330px)] relative left-[330px] h-screen'>
          <Header />
          <main className='flex-auto my-12'>
            <div onClick={notify} className='mb-12 center cursor-pointer'>
              <img className='h-[115px] ' src={dashboard} alt='dashboard' />
            </div>

            <div className='w-full max-w-[1200px] mx-auto my-0 grid grid-cols-2 gap-12'>
              <ChartDividerLine title='Project-info' />

              <div className='relative'>
                <div className='absolute left-7 top-4'>
                  <p className='text-md font-semibold'>Task status</p>
                </div>
                <button className='absolute left-[260px] text-[#3B82F6] top-4 cursor-pointer'>
                  See Details
                </button>
                <DonatForTeams donatForTeamData={donatForTeamData} />
              </div>

              <div className='flex gap-x-6'>
                <div>
                  <PerformanceIndicator
                    percentageChanged={percentageChanged}
                    actualPercentage={actualPercentage}
                    inTotal={88}
                    target={95}
                    classNameProps='mb-8'
                    text={'Active Team'}
                  />
                  <PerformanceIndicator
                    text={'Team Absentee'}
                    percentageChanged={percentageChanged}
                    actualPercentage={actualPercentage}
                    inTotal={5}
                    target={5}
                    classNameProps=''
                  />
                </div>
                <div className=' w-44 h-[318px] bg-white shadow-md rounded-lg  flex flex-col items-center justify-center'>
                  <div className='flex justify-between mb-1'>
                    <p className='text-md font-semibold'>Weather</p>

                    <img src={arrowDown} alt='arrow' />
                  </div>
                  <div className='mb-4'>
                    {selectedProjectLocation && (
                      <p>{`${selectedProjectLocation.city}, ${selectedProjectLocation.country}`}</p>
                    )}
                  </div>
                  <WeatherApp location={location} />
                </div>
              </div>

              <ChartDividerLine title='Safety & Health' />
              <div className='relative'>
                <div className='absolute left-7 top-4'>
                  <h5 className='font-bold'>Project Deficiencies</h5>
                  <p className='font-bold'>as of today</p>
                </div>
                <Donat projectsDonatData={defectsDonatData} />
              </div>
              <div className='w-[320px] h-[300px] relative bg-white rounded-lg shadow-md'>
                <div className='mb-6 ml-7 mt-6'>
                  <h5 className='font-bold'>Safety & Health Risk</h5>
                </div>

                <hr className='w-5/6 mx-auto my-0' />

                <div className='center'>
                  <GaugePlot />
                </div>
              </div>
              <ChartDividerLine title='Other' />
            </div>
          </main>
          <Footer />
        </div>
      </div>
    )
  } else {
    return (
      <Loader/>
    )
  }
}

export default DashboardPage