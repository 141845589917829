// import { useEffect, useState } from 'react'

import Checkbox from '@components/reusable/checkbox/Checkbox'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import { useEffect, useState } from 'react'
import reportsImg from '../../images/reports/reportsImg.webp'
import projectImg from '../../images/reports/project.svg'
import envImg from '../../images/reports/environment.svg'
import healthImg from '../../images/reports/health.svg'
import toast, { Toaster } from 'react-hot-toast'
import {
  reportBlock1,
  reportBlock2,
  reportBlock3,
  reportBlock4,
} from '../../data/reportsPage/ReportsData'

interface ReportBlock {
  id: number
  name: string
  isChecked?: boolean
}

const blockTitles = ['Environment', 'Safety & Health', 'Projects', 'Teams']
function ReportsPage() {
  // Define state variable "blocks" using useState hook, initialized to an empty object with four properties
  const [blocks, setBlocks] = useState<Record<string, ReportBlock[]>>({
    block1: [],
    block2: [],
    block3: [],
    block4: [],
  })
  console.log('ReportsPage ~ blocks >', blocks)

  // Define an effect hook to run once when the component mounts, which sets the "blocks" state variable to a new object
  // with the report blocks for each block key passed as props to the component
  useEffect(() => {
    setBlocks({
      block1: reportBlock1,
      block2: reportBlock2,
      block3: reportBlock3,
      block4: reportBlock4,
    })
  }, [])

  // Define a function "handleCheckboxChange" that takes an ID, boolean value, and block key as arguments, and updates
  // the "blocks" state variable using the "setBlocks" function and the "prevState" argument provided by the useState hook
  const handleCheckboxChange = (id: number, checked: boolean, blockKey: string) => {
    setBlocks((prevState) => {
      const updatedBlock = prevState[blockKey].map((blockItem) =>
        blockItem.id === id ? { ...blockItem, isChecked: checked } : blockItem,
      )
      return { ...prevState, [blockKey]: updatedBlock }
    })
  }

  const notify = () => toast('WIP')

  return (
    <div>
      <nav>
        <Sidebar />
      </nav>
      <div className='flex flex-col w-[calc(100%-330px)] relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto mt-12'>
          <div className='w-full max-w-[1200px] mx-auto my-0 '>
            <div className='cursor-pointer' onClick={notify}>
              <img src={reportsImg} alt='reports' />
            </div>

            <div className='grid grid-cols-2 gap-y-24 mt-12'>
              {Object.entries(blocks).map(([blockKey, blockItems], title) => (
                <div key={blockKey}>
                  <div className='flex mb-8'>
                    <img
                      className='mr-2'
                      src={
                        blockKey === 'block1'
                          ? envImg
                          : blockKey === 'block2'
                          ? healthImg
                          : projectImg
                      }
                      alt={blockKey}
                    />

                    <h3 className='text-base font-semibold'>
                      {blockTitles[parseInt(blockKey.slice(-1)) - 1]}
                    </h3>
                  </div>
                  <form>
                    <div>
                      <Checkbox
                        id={0}
                        className='bg-transparent'
                        name='Select All'
                        checked={!blockItems.some((blockItem) => !blockItem?.isChecked)}
                        onChange={(id, checked) => {
                          const updatedBlock = blockItems.map((blockItem) => ({
                            ...blockItem,
                            isChecked: checked,
                          }))
                          setBlocks((prevState) => ({ ...prevState, [blockKey]: updatedBlock }))
                        }}
                      />
                    </div>
                    {blockItems.map((blockItem) => (
                      <Checkbox
                        key={`${blockKey}-${blockItem.id}`}
                        id={blockItem.id}
                        name={blockItem.name}
                        checked={blockItem?.isChecked || false}
                        onChange={(id, checked) => handleCheckboxChange(id, checked, blockKey)}
                      />
                    ))}
                  </form>
                </div>
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </div>
      {/* <Toaster
        toastOptions={{
          icon: '🙃',
          className: 'bg-black',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }}
      /> */}
    </div>
  )
}
export default ReportsPage
