import InputLogin from '@components/reusable/inputLogin/InputLogin'
import React from 'react'
import message from '../../images/inputLogin/mail.svg'
import lock from '../../images/inputLogin/lockLogin.svg'
import ButtonLogin from '@components/reusable/buttonLogin/ButtonLogin'
import google from '../../images/inputLogin/google.svg'
import facebook from '../../images/inputLogin/facebook.svg'
import person from '../../images/inputLogin/person.svg'
import { Link } from 'react-router-dom'
import cover from '../../images/different/overlay.webp'
import magnusImg from '../../images/different/magnus.webp'

const ResetPasswordPage = () => {
  return (
    <div className='flex w-screen h-screen'>
      <div className='bg-white w-3/6 h-full'>
        <div className='center h-full flex-col'>
          <div>
            <h2 className='text-[#374151] text-2xl font-bold mb-2'>Don&#39;t worry</h2>
            <p className='text-[#9CA3AF] mb-10  w-96'>
              We are here to help you to recover your password. Enter the email address you used
              when you joined and we&#39;ll send you instructions to reset your password.
            </p>

            <div className='mb-6'>
              <p>Email</p>
              <InputLogin
                type='text'
                placeholder='Enter email address'
                img={message}
                imgAlt='message'
              />
            </div>

            <ButtonLogin className='mb-6' text='Send' id='2131233' />
          </div>
        </div>
      </div>
      <div className='w-3/6 h-full  bg-[#0F0F0F] relative'>
        <img className='w-full h-full' src={cover} alt='magnusImg' />
        <img className='absolute top-[20px] right-[90px] w-[80%]' src={magnusImg} alt='tipster' />
      </div>
    </div>
  )
}

export default ResetPasswordPage
