import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { ApiBaseUrl } from '../../app/environment'


interface EmployeeState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  employees: any[]
  error: ''
}

export const fetchEmployee = createAsyncThunk(
  'employees/fetchEmployee',
  async function (_, { rejectWithValue }) {
    try {
      // const response = await axios.get('https://52.28.131.182/api/get/users/in/project')
      const response = await axios.get(`${ApiBaseUrl}/api/get/users/in/project`)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.message)
    }
  },
)

const employeeSlice = createSlice({
  name: 'employee',
  initialState: {
    status: 'idle',
    employees: [],
    error: '',
  } as EmployeeState,
  reducers: {},
  extraReducers: {
    [fetchEmployee.pending.type](state: any) {
      state.status = 'loading'
      state.error = null
    },
    [fetchEmployee.fulfilled.type](state: any, action: any) {
      state.status = 'succeeded'
      state.employees = action.payload
      state.error = null
    },
    [fetchEmployee.rejected.type](state: any, action: any) {
      state.status = 'failed'
      state.employees = []
      state.error = action.payload
    },
  },
})

export default employeeSlice.reducer
