import React from 'react'

import homeIcon from 'images/reusable/homeIcon.svg'
import arrowRight from 'images/reusable/arrowRight.svg'

interface ReusableBreadcrumbProps {
  items: string[]
}

const Breadcrumbs: React.FC<ReusableBreadcrumbProps> = ({ items }) => {
  return (
    <div className='flex items-center'>
      <img className='mr-5 w-[24px]' src={homeIcon} alt='Home-Icon' />
      {items.map((item: any, index: number) => (
        <React.Fragment key={index}>
          <img className='mr-5 ' src={arrowRight} alt='arrowRight' />
          <p className='mr-5'>{item}</p>
        </React.Fragment>
      ))}
    </div>
  )
}

export default Breadcrumbs
