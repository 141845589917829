import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Tabs from '../Tabs'
// import GoogleMaps from '@components/mapsComponents/GoogleMaps'
import { useJsApiLoader } from '@react-google-maps/api'
import defaultUserImg from 'images/profile/defaultUserImg.webp'
import phaseIcon from 'images/tasks/phaseIcon.svg'
import DynamicPopup from '@components/reusable/popup/DynamicPopup'
import { getPhases } from 'feature/phasesSlice'
import arrowDownWithoutFrames from 'images/navbar/arrowDownWithoutFrames.svg'
import plusIcon from 'images/tasks/plusIcon.svg'
import { getSelectedTask, updateTask } from 'feature/tasksSlice'
import { X } from 'lucide-react'
import doneIcon from 'images/tasks/done.svg'
import conflictIcon from 'images/tasks/conflict.svg'
import GoogleMapsZoneArea from '@components/tasksComponents/GoogleMapsZoneArea'
import EditGeneralTaskInfoTabContent from './EditGeneralTaskInfoTabContent'
import clsx from 'clsx'
import AssigneTabContent from '../AssigneTabContent'
import { useForm } from 'react-hook-form'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getSkills } from 'feature/skillsSlice'

interface center {
  lat: number
  lng: number
}

const defaultCenter: center = {
  lat: 59.419961,
  lng: 24.711284,
}
// tabs
export const tabs = [
  {
    id: 0,
    name: 'General Task Information',
  },
  {
    id: 1,
    name: 'Assignes',
  },
  {
    id: 2,
    name: 'Tools',
  },
]

const EditTask = () => {
  const dispatch: any = useDispatch()
  const navigate = useNavigate()
  const [activeTabId, setActiveTabId] = useState<number>(tabs[0].id)
  const [isEditing, setIsEditing] = useState(false)

  const phasesData = useSelector((state: any) => state.phases.data)

  const selectedProject = useSelector((state: any) => state.projects.selectedProject?.comp_prj_id)
  // console.log("EditTask ~ selectedProject >", selectedProject)
  const selectedTaskSkills = useSelector(
    (state: any) => state.tasksInfo.selectedTask[0]?.task_skills_requires,
  )
  const selectedTaskInfo = useSelector((state: any) => state.tasksInfo.selectedTask[0])
  // console.log('EditTask ~ selectedTaskInfo >', selectedTaskInfo)

  const selectedTaskId = sessionStorage.getItem('taskId')
  const taskForEd = JSON.parse(sessionStorage.getItem('taskForEdit') ?? '[]')

  useEffect(() => {
    if (selectedProject) {
      dispatch(getSelectedTask({ taskId: selectedTaskId, projectId: selectedProject }))
      dispatch(getPhases(selectedProject))
      dispatch(getSkills(selectedProject))
    }
  }, [selectedProject, selectedTaskId])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>()

  const [editedTask, setEditedTask] = useState({ task_name: '', start_date: '', finish_date: '' })
  // console.log('EditTask ~ editedTask >', editedTask)

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setEditedTask((prevTask) => ({
      ...prevTask,
      [name]: value,
    }))
  }
  const taskNameInputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    if (taskForEd) {
      setEditedTask(taskForEd[0])
    }
  }, [selectedTaskInfo])
  // Function to enable editing when the "Edit" button is clicked

  const handleEditClick = () => {
    setIsEditing(true)
    if (taskNameInputRef.current) {
      taskNameInputRef.current.focus()
    }
  }

  const onUpdateTask = (data: any) => {
    if (taskForEd[0] && selectedProject) {
      const taskId = taskForEd[0]._id

      try {
        const dataToDispatch = {
          selectedProject,
          editedTask,
          taskId,
        }
        dispatch(updateTask(dataToDispatch))
        navigate('/tasks')
      } catch (error) {
        console.log(1)
      }
    }
  }

  function formatDateToYYYYMMDD(dateString: string) {
    const dateObject = new Date(dateString)

    if (isNaN(dateObject.getTime())) {
      // Handle invalid date strings, e.g., display an error message or return the input string as is
      return dateString
    }

    const formattedDate = dateObject.toISOString().split('T')[0]
    return formattedDate
  }
  return (
    <>
      {editedTask ? (
        <div className='flex font-inter h-screen w-screen text-[#374151] '>
          <Sidebar />
          <div className='flex flex-col w-[calc(100%-330px)]  relative left-[330px] h-screen'>
            <Header />

            <main className='flex-auto'>
              <Button
                onClick={handleSubmit(onUpdateTask)}
                style={{ backgroundColor: '#2265E8', fontSize: '15px' }}
                type='primary'
              >
                Save
              </Button>
              <div className='w-full max-w-[1200px] mx-auto my-0'>
                <div className='my-12'>
                  <Breadcrumbs items={['Tasks']} />
                </div>

                <div className='mb-8'>
                  <div className='flex justify-between'>
                    <input
                      value={editedTask.task_name}
                      className='bg-[#EFF6FF] text-2xl font-medium  text-black rounded-md focus:outline-none mr-4'
                      {...register('task_name', { required: false })}
                      ref={taskNameInputRef}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    />
                    {errors.task_name?.type === 'required' && (
                      <p className='text-[#8b2d2d] py-2' role='alert'>
                        Task name is required
                      </p>
                    )}
                    <button type='button' onClick={handleEditClick} disabled={isEditing}>
                      Edit
                    </button>
                    <div>
                      <DynamicPopup
                        topicNameItems={phasesData}
                        initialTextSelectDynamic='Add a phase'
                        dropdownPosition='top-[60px] left-[-20px]'
                        selectedTextStyle='pl-2 pr-4'
                        img1Dynamic={plusIcon}
                        img2Dynamic={phaseIcon}
                        img2={arrowDownWithoutFrames}
                      />
                    </div>
                  </div>
                  <div className='relative mb-8'>
                    <div className='bg-white px-3 py-2 flex flex-col absolute right-[20px] rounded-md'>
                      <div className='bg-[#EBE2FF] mb-2 px-12 py-1 rounded-md font-medium'>
                        <label htmlFor='start_date'>Start:</label>

                        <input
                          id='start_date'
                          type='date'
                          value={formatDateToYYYYMMDD(editedTask.start_date)}
                          className='bg-[#EBE2FF]'
                          {...register('start_date', { required: false })}
                          onChange={handleInputChange}
                        />
                        {errors.start_date?.type === 'required' && (
                          <p className='text-[#8b2d2d] py-2' role='alert'>
                            Start data is required
                          </p>
                        )}
                      </div>

                      <div className='bg-[#AEFFF5] mb-2 px-12 py-1 rounded-md font-medium'>
                        <label htmlFor='finish_date'>Start:</label>

                        <input
                          id='finish_date'
                          type='date'
                          value={formatDateToYYYYMMDD(editedTask.finish_date)}
                          className='bg-[#AEFFF5]'
                          {...register('finish_date', { required: false })}
                          onChange={handleInputChange}
                        />
                        {errors.finish_date?.type === 'required' && (
                          <p className='text-[#8b2d2d] py-2' role='alert'>
                            Finish data is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div className='w-12'>
                      <img src={defaultUserImg} alt='user-icon' />
                    </div>
                    <div className='ml-2'>
                      <p className='text-base'>Lazar Jevric</p>
                      <p className='text-base text-[#6C6C6C]'>
                        Project Manager @Phare Technologies
                      </p>
                    </div>
                  </div>
                </div>

                <div className='py-5 rounded-lg bg-white pl-5 mb-12'>
                  <Tabs tabs={tabs} activeTabId={activeTabId} setActiveTabId={setActiveTabId} />
                </div>

                {/* General Task Info Tab */}

                <div className={clsx('w-full', activeTabId === 0 ? 'block' : 'hidden')}>
                  <EditGeneralTaskInfoTabContent
                    selectedTaskInfo={selectedTaskInfo}
                    selectedTaskSkills={selectedTaskSkills}
                  />
                </div>

                <div className={clsx('w-full', activeTabId === 1 ? 'block' : 'hidden')}>
                  <AssigneTabContent selectedTaskInfo={selectedTaskInfo} />
                </div>

                <div className={clsx('w-full', activeTabId === 2 ? 'block' : 'hidden')}>Tools</div>
              </div>
            </main>

            <Footer />
          </div>
        </div>
      ) : (
        <span>Loading</span>
      )}
    </>
  )
}

export default EditTask
