import { useState } from 'react'
import Modal from './Modal'
import addNewMemberIcon from '../../images/teams/plusAddNewMember.svg'
import clsx from 'clsx'
import Popup from '@components/analyticsComponents/Popup'

const AddNewDeffect = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const renderInputField = (
    label: string,
    id: string,
    type: string,
    name: string,
    placeholder: string,
    className?: string,
  ) => (
    <div className={className || 'flex flex-col'}>
      <label className='mb-1' htmlFor={id}>
        {label}
      </label>
      {name === 'description' ? (
        <textarea
          className='bg-[#F1F1F1] w-full py-3 pl-3 text-black'
          id={id}
          name={name}
          placeholder={placeholder}
        />
      ) : (
        <input
          className='bg-[#F1F1F1] w-5/6 py-3 pl-3 text-black'
          id={id}
          type={type}
          name={name}
          placeholder={placeholder}
        />
      )}
    </div>
  )

  return (
    <div className=''>
      <button onClick={handleClick} className='w-24 h-16 text-white bg-[#3a65f0]'>
        +
      </button>
      <Modal className='w-[1010px] h-[700px]' isOpen={isOpen} onClose={handleClose}>
        <button className='absolute right-5' onClick={handleClose}>
          <img
            className='mr-2 w-5 transform rotate-45'
            src={addNewMemberIcon}
            alt='add new team member icon'
          />
        </button>

        <main className='mt-12'>
          <form>
            {/* <Popup topicNameItems='sd'/> */}
            <div className='grid grid-cols-2 gap-y-6 ml-8 mb-12'>
              {renderInputField('Defect name', 'name-input', 'text', 'name', 'ex. Roof cracking')}
              {renderInputField(
                'Defect status',
                'status-input',
                'text',
                'status',
                'ex. in-progress',
              )}
              {renderInputField(
                'Defect description',
                'description-input',
                'description',
                'description',
                'ex. Dripping faucet',
                'col-span-2',
              )}
              {renderInputField(
                'Assignee',
                'assignee-input',
                'text',
                'assignee',
                'ex. Erling Haaland',
              )}
              {renderInputField(
                'Type of defect',
                'type-input',
                'text',
                'type',
                'ex. Material Defects',
              )}
              {renderInputField('Elimination date', 'date-input', 'date', 'date', 'ex. 12-05-2023')}
            </div>
            <div className='ml-80 flex'>
              <button className='text-blue-600 border border-blue-600 rounded-lg px-14 py-2 mr-6'>
                Close
              </button>
              <button className='text-white border bg-blue border-blue-600 rounded-lg px-16 py-2'>
                Add
              </button>
            </div>
          </form>
        </main>
      </Modal>
    </div>
  )
}

export default AddNewDeffect
