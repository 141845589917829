import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import ManageProjects from '@components/manageProjectsComponents/ManageProjects'
import { Plus } from 'lucide-react'
import manageProjectIcon from 'images/projects/manageProjectIcon.svg'
import { Link } from 'react-router-dom'

const ManageProjectPage = () => {
  
  return (
    <div className='flex font-inter h-screen w-screen'>
      <Sidebar />
      <div className='flex flex-col w-[calc(100%-330px)] relative left-[330px] h-screen'>
        <Header />
        <main className='flex-auto mt-10 mb-7'>
          <div className='wrapper'>
            <div className='mb-4'>
              <div className='my-12'>
                <Breadcrumbs items={['Projects','Manage Projects']} />
              </div>
            </div>
            <div className='flex items-center'>
              <div className='flex items-center mr-5'>
                <div className='w-12 h-12 bg-white border rounded-xl center mr-3'>
                  <img src={manageProjectIcon} alt='Project-Icon' />
                </div>
                <p className='text-2xl font-semibold'>Manage Projects</p>
              </div>
              <Link to='/create-new-project'>
                <button className='h-12 w-12 bg-blue center rounded-xl'>
                  <Plus color='white' />
                </button>
              </Link>
            </div>
            <ManageProjects />
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default ManageProjectPage
