import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedCompany } from 'feature/userInfoSlice'
import arrawDownImg from 'images/popup/arrawDown.svg'
import arrowDownWithoutFrames from 'images/navbar/arrowDownWithoutFrames.svg'
import toast from 'react-hot-toast'

type Props = {
  topicNameItems: string[]
  img1?: string
  img2?: string
}

const Popup: React.FC<Props> = ({ topicNameItems, img1, img2 }) => {
  const companyInfo = useSelector((state: any) => state.userInfo.data.user_companies)
  const selectedCompany = useSelector((state: any) => state.userInfo.selectedCompany)

  const [topicNames, setTopicNames] = useState('')

  const [open, setOpen] = useState(false)

  const node = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    const selectedCompanyFromLocalStorage = localStorage.getItem('selectedCompany')

    if (selectedCompanyFromLocalStorage) {
      const selectedCompany = JSON.parse(selectedCompanyFromLocalStorage)
      setTopicNames(selectedCompany.company_name)

    }
  }, [dispatch])

  useEffect(() => {
    if (selectedCompany && selectedCompany.company_name) {
      setTopicNames(selectedCompany.company_name)
    }
  }, [selectedCompany])

  useEffect(() => {
    if (topicNameItems.length > 0) {
      setTopicNames(selectedCompany.company_name)
    }
  }, [])

  // ------- switch projects
  const handleChange = (selectedValue: any) => {
    const choosenCompany = companyInfo.filter(
      (company: any) => selectedValue === company.company_name,
    )
    // console.log("handleChange ~ choosenCompany >", choosenCompany[0])
    localStorage.setItem('selectedCompany', JSON.stringify(choosenCompany[0]))

    dispatch(setSelectedCompany(choosenCompany[0]))

    setTopicNames(choosenCompany[0].company_name)

    setOpen((prev) => !prev)
    if (open) {
      setOpen((prev) => !prev)
    }

    toast((t) => (
      <span>
        Select a Project
      </span>
    ),)
  }

  // ------- open list of items
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen((prev) => !prev)
  }

  // ------- click outside
  const handleClickOutside = (e: MouseEvent) => {
    if (node.current?.contains(e.target as Node)) {
      return
    }
    setOpen((prev) => !prev)
  }


  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])
  return (
    <div ref={node} className='flex'>
      <button
        onClick={handleClick}
        className='center h-16 w-[200px] px-1 py-1 rounded-3xl mr-5'
      >
        {img1 && <img className='mx-2' src={img1} alt='img1' />}
        {topicNames !== null && topicNames ? (
          <p style={{ fontSize: "14px" }} className='text-textDefaultColor w-[90%] text-base font-medium'>
            {topicNames.length > 55 ? `${topicNames.slice(0, 55)}...` : topicNames}
          </p>
        ) : (
          <p className='ml-4 text-textDefaultColor w-[90%] text-base font-medium'>Select Company</p>
        )}
        <div className='center w-[12px] ml-2'>
          <img
            className={clsx('w-full h-full ', open && 'transform rotate-180')}
            src={arrowDownWithoutFrames}
            alt='arrow-down'
          />
        </div>
        <ul
          className={clsx(
            open
              ? 'flex flex-col absolute top-[105px] bg-white w-[300px] rounded-t-xl rounded-xl shadow-2xl drop-shadow-2xl z-10'
              : 'hidden',
          )}
        >
          {topicNameItems.map((opt, i) => {
            return (
              <li
                onClick={() => handleChange(opt)}
                key={i}
                // className='first:bg-[#EFEEF1] py-2 border-b last:mb-0'
                className='hover:bg-[#EFEEF1] py-4 border-b last:mb-0'
              >
                {opt}
              </li>
            )
          })}
        </ul>

        {img2 && <img src={img2} alt='img2' />}
      </button>
    </div>
  )
}

export default React.memo(Popup)
