import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiBaseUrl } from 'app/environment'
import axios from 'axios'
import axiosInstance from 'axiosInstance'

interface InitialStateType {
  data: []
  error: null
  loading: boolean
}

const initialState: InitialStateType = {
  data: [],
  error: null,
  loading: false,
}

export const getResources = createAsyncThunk(
  'getResources/resourcesSlice',
  async (_, { rejectWithValue }) => {
    try {
      const id = '636b981a9410a61ab2f848c5'
      const url = `/api/projects/${id}/resources`
      const response = await axiosInstance.get(url)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResources.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(getResources.rejected, (state, action) => {
        state.error = action.payload as null
        state.loading = false
      })
  },
})

export default resourcesSlice.reducer
