import Modal from '../modalProjectInfo/Modal'
import addNewMemberIcon from '../../images/teams/plusAddNewMember.svg'
import axios from 'axios'
import { useForm, SubmitHandler } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import { Plus } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { ApiBaseUrl } from 'app/environment'
import { createContractor } from 'feature/partiesSlice'

const AddContractorModal = ({ isOpen, setIsOpen, isCreate }: any) => {
  const dispatch: any = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>()

  const notifySeccess = () => toast('Contractor created')
  const notifyFailier = () => toast('Something is wrong')
  const projectId = useSelector((state: any) => state.projects.selectedProject?.comp_prj_id)
  const onSubmit = (data: any) => {
    if (projectId) {
      try {
        const requestData = {
          data,
          projectId,
        }
        dispatch(createContractor(requestData))
        notifySeccess()
        setIsLoading(false) // Hide loading state
        reset() // Reset the form fields
        handleClose() // Close the modal
      } catch (error) {
        notifyFailier()
        console.error('Error:', error)
        setIsLoading(false)
      }
    }
  }
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  // 647edfccb5abe786b6feafba  subcontractor
  return (
    <div>
      {/* <Toaster
        toastOptions={{
          icon: '🙃',
          className: 'bg-black z-[1000000]',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
            zIndex: 1000000,
          },
        }}
      /> */}
      <button onClick={handleOpen} className='h-12 w-12 bg-blue center rounded-xl'>
        <Plus color='white' />
      </button>

      <Modal isOpen={isOpen} onClose={handleClose}>
        {isLoading ? (
          <p className='text-2xl center'>Loading...</p>
        ) : (
          <>
            <button className='absolute right-5' onClick={handleClose}>
              <img
                className='mr-2 w-5 transform rotate-45'
                src={addNewMemberIcon}
                alt='add new team member icon'
              />
            </button>

            <h4 className='text-black font-semibold text-xl mt-10 ml-8 mb-6 '>
              Add a new contractor
            </h4>

            <main>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-2 gap-y-6 ml-8 mb-12'>
                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='name-input'>
                      Name:
                    </label>
                    <input
                      // value={taskData?.task_name}
                      // onChange={(e) => setSelectedTask({ ...taskData, task_name: e.target.value })}
                      className='bg-[#F1F1F1] w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      {...register('prj_collab_name', { required: false, maxLength: 55 })}
                    />
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='type-input'>
                      type:
                    </label>
                    <input
                      className='bg-[#F1F1F1] w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      {...register('prj_collab_type', { required: false, maxLength: 55 })}
                    />
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='_id-input'>
                      _id:
                    </label>
                    <input
                      className='bg-[#F1F1F1] w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      {...register('_id', { required: false, maxLength: 55 })}
                    />
                  </div>
                </div>
                <div className='ml-96'>
                  <button
                    onClick={handleClose}
                    type='button'
                    className='text-blue-600 border border-blue-600 rounded-lg px-6 py-2 mr-6'
                  >
                    Cancel
                  </button>
                  <button onClick={handleSubmit(onSubmit)}>Finish</button>
                </div>
              </form>
            </main>
          </>
        )}
      </Modal>
    </div>
  )
}
export default AddContractorModal
