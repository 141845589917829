import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiBaseUrl } from 'app/environment'
import axios from 'axios'
import axiosInstance from 'axiosInstance'

interface initialStateType {
  data: any
  loading: boolean
  error: null
}
const initialState: initialStateType = {
  data: [],
  error: null,
  loading: false,
}

export const getSelectedProjectData = createAsyncThunk(
  'getSelectedProjectData/selectedProjectData',
  async (id: any, { rejectWithValue }) => {
  
    try {
      const url = `/api/project/${id}/info`
      const response = await axiosInstance.get(url)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const selectedProjectDataSlice = createSlice({
  name: 'selectedProjectData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSelectedProjectData.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(getSelectedProjectData.fulfilled, (state, action) => {
        const selectedProjectLocalStorageJSON = localStorage.getItem('selectedProject')
        const selectedProjectFromLocalStorage = selectedProjectLocalStorageJSON
          ? JSON.parse(selectedProjectLocalStorageJSON)
          : null

        const selectedCompanyIdFromLocalStorage = localStorage.getItem('companyIdForProjects')

        if (
          selectedProjectFromLocalStorage &&
          selectedProjectFromLocalStorage.comp_id &&
          selectedCompanyIdFromLocalStorage &&
          selectedProjectFromLocalStorage.comp_id === selectedCompanyIdFromLocalStorage
        ) {
          // console.log('code works');
          state.loading = false
          state.data = action.payload
          state.error = null
        } else {
          state.loading = false
          state.error = null
          state.data = [] // or reset to initial state
        }
      })
      .addCase(getSelectedProjectData.rejected, (state, action) => {
        state.error = action.payload as null
        state.loading = false
        state.data = []
      })
  },
})

export default selectedProjectDataSlice.reducer
