/* eslint-disable react/prop-types */

import addNewMemberIcon from 'images/teams/plusAddNewMember.svg'
import { useEffect, useState } from 'react'
import Modal from '@components/modalProjectInfo/Modal'
import { Button, Pagination } from 'antd'
import { useSelector } from 'react-redux'

interface AddAssignModalProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  isCreate?: boolean
}

const EditAddAssigneModal: React.FC<AddAssignModalProps> = ({ isOpen, setIsOpen }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredSkills, setFilteredSkills] = useState([])
  console.log('filteredSkills >', filteredSkills)
  const [total, setTotal] = useState<any>('')
  const [page, setPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(6)

  const [isTaskSkills, setIsTaskSkills] = useState(true)
  console.log('isTaskSkills >', isTaskSkills)

  const skillsData = useSelector((state: any) => state.skills.data)

  const selectedTaskSkills = useSelector(
    (state: any) => state.tasksInfo.selectedTask[0]?.task_skills_requires,
  )

  // Functuion for filter
  useEffect(() => {
    if (selectedTaskSkills && skillsData) {
      if (isTaskSkills) {
        const filtered = skillsData.filter((skill: any) =>
          skill.skill_name.toLowerCase().startsWith(searchQuery.toLowerCase()),
        )
        setFilteredSkills(filtered)
      } else {
        const filtered = selectedTaskSkills.filter((skill: any) =>
          skill.skill_name.toLowerCase().startsWith(searchQuery.toLowerCase()),
        )
        setFilteredSkills(filtered)
      }
    }
  }, [isOpen, searchQuery, isTaskSkills, selectedTaskSkills])

  // Function for highlight matched query

  function highlightMatch(skillName: any, searchQuery: any) {
    const regex = new RegExp(searchQuery, 'gi')
    return skillName.replace(
      regex,
      (match: any) => `<span class="bg-[#b5ceff] py-1">${match}</span>`,
    )
  }

  // Pagination functions

  useEffect(() => {
    if (filteredSkills) {
      setTotal(filteredSkills.length)
    }
  }, [filteredSkills])

  const indexOfLastPage = postPerPage * page
  const indexOfFirstPage = indexOfLastPage - postPerPage
  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }

  const paginationList = filteredSkills.slice(indexOfFirstPage, indexOfLastPage)

  // Other functions

  const handleClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (!isOpen) {
      setIsTaskSkills(false)
    }
  }, [isOpen])

  const switchSkillsList = () => {
    setIsTaskSkills((prev) => !prev)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        className='bg-white rounded-lg shadow-lg py-6 pl-20 pr-6 h-[850px] overflow-auto w-[1200px] relative'
      >
        <button className='absolute right-5' onClick={handleClose}>
          <img
            className='mr-2 w-5 transform rotate-45'
            src={addNewMemberIcon}
            alt='add new team member icon'
          />
        </button>
        <main>
          <div>
            <div>
              <div>
                {isTaskSkills ? (
                  <h2 className='font-medium text-2xl text-[#222222]'>Back to skills</h2>
                ) : (
                  <h2 className='font-medium text-2xl text-[#222222]'>Add Skills</h2>
                )}
                <p className='text-[#343A40] my-5'>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Id error, ipsa deserunt
                  neque rem qua
                </p>
              </div>
              <div className=''>
                <input
                  className='bg-[#F1F1F1] w-[450px] py-3 pl-3 mr-[320px] text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                  placeholder='Search for a skills...'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />

                {isTaskSkills ? (
                  <Button
                    onClick={switchSkillsList}
                    style={{ background: '#2265E8', width: '170px' }}
                    type='primary'
                  >
                    Back
                  </Button>
                ) : (
                  <Button
                    onClick={switchSkillsList}
                    style={{ background: '#2265E8', width: '170px' }}
                    type='primary'
                  >
                    Add skills
                  </Button>
                )}
              </div>
            </div>
            <div className=''>
              <div className='font-medium text-base text-[#333] flex mt-8 mb-2'>
                <h5 className='mr-[255px]'>Skill</h5>
                <h5 className='mr-[238px]'>Status</h5>
                <h5 className='flex-1'>Hours</h5>
              </div>
              <ul>
                {paginationList.map((skill: any, i: number) => (
                  <li key={i} className='w-full flex justify-between items-center mb-3'>
                    <div className='flex justify-between w-[70%]'>
                      <div className='w-[195px] bg-[#EEEEEE] rounded-md h-[68px] center pl-1'>
                        <p
                          style={{ backgroundColor: skill.skill_color }}
                          className='text-[#222] text-base font-medium py-1 px-2 rounded-lg'
                          dangerouslySetInnerHTML={{
                            __html: highlightMatch(skill.skill_name, searchQuery),
                          }}
                        />
                      </div>
                      <div className='w-[195px] bg-[#EEEEEE] rounded-md h-[68px] center pl-1'>
                        <p>sort</p>
                      </div>
                      <div className='w-[195px] bg-[#EEEEEE] rounded-md h-[68px] center pl-1'>
                        <p>8</p>
                      </div>
                    </div>
                    <div className='w-[28%]'>
                      <Button style={{ background: '#2265E8', marginRight: '10px' }} type='primary'>
                        Save
                      </Button>

                      {!isTaskSkills && (
                        <Button style={{ background: '#2265E8' }} type='primary'>
                          Remove
                        </Button>
                      )}
                    </div>
                  </li>
                ))}
                <Pagination
                  className='absolute right-16 bottom-10'
                  onChange={(value: any) => setPage(value)}
                  pageSize={postPerPage}
                  total={total}
                  onShowSizeChange={onShowSizeChange}
                  current={page}
                />
              </ul>
            </div>
          </div>
        </main>
      </Modal>
    </>
  )
}

export default EditAddAssigneModal
