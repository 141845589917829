import { useState, useEffect } from 'react'

function Drafts() {
  const [listening, setListening] = useState(false)
  const [processId, setProcessId] = useState('')
  const [message, setMessage] = useState('')
  const [tipsterDataDict, setTipsterDataDict] = useState<any>({}) // Dictionary to store tipster data by tip_user_id
  // console.log('Drafts ~ tipsterDataDict >', tipsterDataDict)

  const tipsterDataUrl = 'http://localhost:4000/api/project/636b981a9410a61ab2f848c5/tipsterdata'

  useEffect(() => {
    const eventSource = new EventSource(tipsterDataUrl)

    eventSource.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      let tipUserId: string

      switch (parsedData.message) {
        case 'init':
          setProcessId(parsedData.processId)
          break
        case 'heartbeat':
          setMessage(parsedData.message)
          break
        case 'tipster':
          setMessage(parsedData.message)
          tipUserId = parsedData.tipster_data.tip_user_id

          // Update or add the tipster data in the dictionary
          setTipsterDataDict((prevDict: any) => ({
            ...prevDict,
            [tipUserId]: parsedData.tipster_data,
          }))
          break
      }
    }

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error)
      eventSource.close()
    }

    return () => {
      console.log('Connection closed.')
      eventSource.close()
    }
  }, [])

  return (
    <div>
      <div>
        <button onClick={() => setListening(!listening)}>Toggle Listening</button>
        <p>Process ID: {processId}</p>
        <p>Message: {message}</p>
        {/* Render tipster data from the dictionary */}
        {Object.keys(tipsterDataDict).length > 0
          ? Object.values(tipsterDataDict).map((tipsterData, index) => (
              <div key={index}>
                {/* <p className='text-4xl'>Tip User ID: {tipsterData.tip_user_id}</p>
              <p className='text-4xl'>Air-quality: {tipsterData.env_air_quality}AQI</p>
              <p className='text-4xl'>Noise: {tipsterData.env_noise}dB</p>
              <p className='text-4xl'>Pressure: {tipsterData.env_pressure}hPa</p>
              <p className='text-4xl'>Temperature: {tipsterData.env_temperature}C</p>
              <p className='text-4xl'>Humidity: {tipsterData.env_humidity}%</p> */}
              </div>
            ))
          : 'No data'}
      </div>
    </div>
  )
}

export default Drafts
