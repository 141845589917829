import InputLogin from '@components/reusable/inputLogin/InputLogin'
import React, { useState } from 'react'
import message from '../../images/inputLogin/mail.svg'
import lock from '../../images/inputLogin/lockLogin.svg'
import ButtonLogin from '@components/reusable/buttonLogin/ButtonLogin'
import google from '../../images/inputLogin/google.svg'
import locationImg from '../../images/different/location-city.svg'
import facebook from '../../images/inputLogin/facebook.svg'
import person from '../../images/inputLogin/person.svg'
import { Link } from 'react-router-dom'
import cover from '../../images/different/overlay.webp'
import magnusImg from '../../images/different/magnus.webp'

import clsx from 'clsx'

const SignUpPage = () => {
  const [activeTabId, setActiveTabId] = useState<number>(1)

  const handleTabClick = (tabId: number) => {
    setActiveTabId(tabId)
  }
  return (
    <div className='flex w-screen h-screen'>
      <div className='bg-white w-3/6 h-full'>
        <div className='center h-full flex-col'>
          <nav className='flex mb-10 ml-[-140px]'>
            <button
              className={clsx(
                'py-2 px-4 mr-4',
                activeTabId === 1 && 'border-b-2 border-blue text-blue font-bold',
              )}
              onClick={() => handleTabClick(1)}
            >
              User
            </button>
            <button
              className={clsx(
                'py-2 px-4',
                activeTabId === 2 && 'border-b-2 border-blue text-blue font-bold',
              )}
              onClick={() => handleTabClick(2)}
            >
              Company
            </button>
          </nav>

          {/* register user */}
          <div className={clsx(activeTabId === 1 ? 'block' : 'hidden')}>
            <h2 className='text-[#374151] text-2xl font-bold mb-2'>Register</h2>
            <p className='text-[#9CA3AF] mb-10'>See your growth and get consulting support!</p>

            <div className='mb-6'>
              <p>Full name</p>
              <InputLogin
                type='text'
                placeholder='Enter full name'
                img={person}
                imgAlt='Full name'
                // className=""
              />
            </div>
            <div className='mb-6'>
              <p>Email</p>
              <InputLogin type='text' placeholder='Log in' img={message} imgAlt='message' />
            </div>

            <div className='mb-6'>
              <p>Password</p>
              <InputLogin
                type='text'
                placeholder='Enter your password'
                img={lock}
                imgAlt='message'
              />
            </div>

            <div className='mb-6'>
              <p>Confirm password</p>
              <InputLogin
                type='text'
                placeholder='Confirm password'
                img={lock}
                imgAlt='Confirm password'
              />
            </div>

            <div className='center mb-6'>
              <span className='h-px w-24 bg-[#D1D5DB]'></span>
              <p className='mx-2 text-sm'>Sign in via social</p>
              <span className='h-px w-24 bg-[#D1D5DB]'></span>
            </div>

            <div className='flex mb-6'>
              <button className='center py-2 px-8 border rounded-md mr-4'>
                <div>
                  <img className='mr-1' src={facebook} alt='facebook' />
                </div>
                <p className='mt-px'>Facebook</p>
              </button>
              <button className='center py-2 px-10 border rounded-md'>
                <div>
                  <img className='mr-1' src={google} alt='google' />
                </div>
                <p className='mt-px'>Google</p>
              </button>
            </div>

            <ButtonLogin className='mb-6' text='Register' id='2131233' />

            <p>
              You have already account?{' '}
              <Link className='text-[#3B82F6]' to='/'>
                Login
              </Link>
            </p>
          </div>

          {/* register company */}
          <div className={clsx(activeTabId === 2 ? 'block' : 'hidden')}>
            <h2 className='text-[#374151] text-2xl font-bold mb-2'>Register</h2>
            <p className='text-[#9CA3AF] mb-10'>See your growth and get consulting support!</p>

            <div className='mb-6'>
              <p>Full name</p>
              <InputLogin
                type='text'
                placeholder='Enter full name'
                img={person}
                imgAlt='Full name'
                // className=""
              />
            </div>
            <div className='mb-6'>
              <p>Email</p>
              <InputLogin type='text' placeholder='Log in' img={message} imgAlt='message' />
            </div>
            <div className='mb-6'>
              <p>Company Location</p>
              <InputLogin
                type='text'
                placeholder='Enter your location'
                img={locationImg}
                imgAlt='locationImg'
              />
            </div>
            <div className='mb-6'>
              <p>Password</p>
              <InputLogin
                type='text'
                placeholder='Enter your password'
                img={lock}
                imgAlt='message'
              />
            </div>

            <div className='mb-6'>
              <p>Confirm password</p>
              <InputLogin
                type='text'
                placeholder='Confirm password'
                img={lock}
                imgAlt='Confirm password'
              />
            </div>

            <div className='center mb-6'>
              <span className='h-px w-24 bg-[#D1D5DB]'></span>
              <p className='mx-2 text-sm'>Sign in via social</p>
              <span className='h-px w-24 bg-[#D1D5DB]'></span>
            </div>

            <div className='flex mb-6'>
              <button className='center py-2 px-8 border rounded-md mr-4'>
                <div>
                  <img className='mr-1' src={facebook} alt='facebook' />
                </div>
                <p className='mt-px'>Facebook</p>
              </button>
              <button className='center py-2 px-10 border rounded-md'>
                <div>
                  <img className='mr-1' src={google} alt='google' />
                </div>
                <p className='mt-px'>Google</p>
              </button>
            </div>

            <ButtonLogin className='mb-6' text='Register' id='2131233' />

            <p>
              You have already account?{' '}
              <Link className='text-[#3B82F6]' to='/'>
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className='w-3/6 h-full  bg-[#0F0F0F] relative'>
        <img className='w-full h-full' src={cover} alt='magnusImg' />
        <img className='absolute top-[20px] right-[90px] w-[80%]' src={magnusImg} alt='tipster' />
      </div>
    </div>
  )
}

export default SignUpPage
