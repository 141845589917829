import { useAppDispatch, useAppSelector } from 'app/hooks';
import clsx from 'clsx';
import React from 'react';
import { object, string } from 'zod';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
// import { login } from '../../../feature/auth';
// import { InputLoginProps } from "types";

type InputLoginProps = {
  type?: string;
  img?: string;
  imgAlt?: string;
  placeholder?: string;
  id?: number | string;
  className?: string;
};

const loginSchema = object({
  email: string({ required_error: 'email is required' }).min(
    1,
    'Email can not be empty'
  ),
  password: string({ required_error: 'password is required' }).min(
    1,
    'Password can not be empty'
  ),
});

// type LoginTpe = TypeOf<typeof loginSchema>
interface FormValues {
  email: string;
  password: string;
}

const InputLogin = (props: InputLoginProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const { register, formState: { errors }, handleSubmit } = useFormAction<FormValues>({
  //     resolver: zodResolver(loginSchema)
  // })
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(loginSchema),
  });

  // const { error }: any = useAppSelector((state) => state.user);

 
  return (
    <div
      className={clsx(
        'h-10  border border-[#D1D5DB] rounded-md bg-white center relative',
        props.className ? props.className : 'w-[330px] '
      )}
    >
      <div className="absolute left-5">
        <img className="w-5 h-5" src={props.img} alt={props.imgAlt} />
      </div>
      <input
        className="w-full h-full pl-14 rounded-md focus:outline-none"
        type={props.type}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default InputLogin;
