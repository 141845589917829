import clsx from 'clsx'
import React from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
// import arrow from "../../images/different/arrowUp.svg";

import arrow from '../../../../images/different/arrowUp.svg'

type PerformanceIndicatorProps = {
  target?: number
  percentageChanged?: number
  actualPercentage?: number
  inTotal: number
  classNameProps?: string
  text?: string
}

const PerformanceIndicator = ({
  text,
  target,
  percentageChanged,
  actualPercentage,
  classNameProps,
  inTotal,
}: PerformanceIndicatorProps) => {
  const inTotalNum = Math.ceil(inTotal)
  return (
    <div>
      <div className={clsx('w-72 h-36 shadow-md bg-white rounded-md  center', classNameProps)}>
        <div className='mr-6 w-4/6'>
          <div className='ml-6'>
            <h4 className='text-md font-semibold mb-1'>{text}</h4>
            <p className='text-[#9CA3AF] text-sm mb-1'>
              Target: <span className='text-[#6B7280]'>{target}%</span>
            </p>
          </div>
          <div className='center'>
            <h3 className='text-lg font-medium mr-2 '>{actualPercentage}%</h3>
            <div className='center rounded-xl w-14 h-6 bg-finished'>
              <img className='w-2 h-2 mr-1' src={arrow} alt='arrow' />
              <span>{percentageChanged}%</span>
            </div>
          </div>
        </div>
        <div className='w-2/6'>
          <CircularProgressbarWithChildren
            value={inTotal}
            styles={{
              text: {
                fill: '##374151',
              },
              path: {
                transition: 'stroke-dashoffset 0.5s ease 0s',
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              background: {
                fill: '#3B82F6',
              },
            }}
          >
            <div style={{ marginBottom: '-5px' }}>
              <h2 className='text-2xl  font-bold center'>{inTotalNum}%</h2>
              <p className='center text-base'>Total</p>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </div>
  )
}

export default PerformanceIndicator
