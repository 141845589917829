import { WebBaseUrl } from './app/environment'
type AuthConfig = {
  authority: string
  client_id: string
  redirect_uri: string
  response_type: string
  scope: string
  post_logout_redirect_uri: string
  userinfo_endpoint: string
  response_mode: 'query' | 'fragment' | undefined
  code_challenge_method: string
}

const authConfig: AuthConfig = {
  authority: 'https://magnusorigin-y5o3fu.zitadel.cloud', // Replace with your issuer URL
  client_id: '214174434041331969@project2', // Replace with your client id
  redirect_uri: `${WebBaseUrl}/dashboard`, // Replace with your redirect
  response_type: 'code',
  scope: 'openid profile email urn:zitadel:iam:user:metadata',
  post_logout_redirect_uri: `${WebBaseUrl}`,
  userinfo_endpoint: 'https://magnusorigin-y5o3fu.zitadel.cloud/oidc/v1/userinfo', // Replace with your user-info endpoint
  response_mode: 'query',
  code_challenge_method: 'S256',
}
export default authConfig
