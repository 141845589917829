export const weekly = [
  {
    date: 'Mon',
    scales: 11,
  },
  {
    date: 'Tue',
    scales: 33,
  },
  {
    date: 'Wen',
    scales: 13,
  },
  {
    date: 'Thu',
    scales: 44,
  },
  {
    date: 'Fri',
    scales: 33,
  },
  {
    date: 'Sat',
    scales: 55,
  },
  {
    date: 'Sun',
    scales: 18,
  },
]

export const hourly = [
  {
    date: '01:00',
    scales: 4,
  },
  {
    date: '02:00',
    scales: 13,
  },
  {
    date: '03:00',
    scales: 44,
  },

  {
    date: '04:00',
    scales: 22,
  },
  {
    date: '05:00',
    scales: 17,
  },
  {
    date: '06:00',
    scales: 29,
  },
  {
    date: '07:00',
    scales: 38,
  },
  {
    date: '08:00',
    scales: 50,
  },
  {
    date: '09:00',
    scales: 4,
  },
  {
    date: '10:00',
    scales: 19,
  },
  {
    date: '11:00',
    scales: 36,
  },
  {
    date: '12:00',
    scales: 41,
  },
  {
    date: '13:00',
    scales: 55,
  },
  {
    date: '14:00',
    scales: 33,
  },
  {
    date: '15:00',
    scales: 28,
  },
  {
    date: '16:00',
    scales: 77,
  },
  {
    date: '17:00',
    scales: 39,
  },
  {
    date: '18:00',
    scales: 52,
  },
  {
    date: '19:00',
    scales: 43,
  },
  {
    date: '20:00',
    scales: 25,
  },
  {
    date: '21:00',
    scales: 31,
  },
  {
    date: '22:00',
    scales: 11,
  },
  {
    date: '23:00',
    scales: 37,
  },
]
export const monthly = [
  {
    date: '01',
    scales: 66,
  },
  {
    date: '02',
    scales: 44,
  },
  {
    date: '03',
    scales: 13,
  },
  {
    date: '04',
    scales:99,
  },
  {
    date: '05',
    scales: 5,
  },
  {
    date: '06',
    scales: 33,
  },
  {
    date: '07',
    scales: 29,
  },
  {
    date: '08',
    scales: 38,
  },
  {
    date: '09',
    scales: 50,
  },
  {
    date: '10',
    scales:77,
  },
  {
    date: '11',
    scales: 19,
  },
  {
    date: '12',
    scales: 36,
  },
  {
    date: '13',
    scales: 22,
  },
  {
    date: '14',
    scales: 55,
  },
  {
    date: '15',
    scales: 33,
  },
  {
    date: '16',
    scales: 4,
  },
  {
    date: '17',
    scales: 47,
  },
  {
    date: '18',
    scales: 39,
  },
  {
    date: '19',
    scales: 77,
  },
  {
    date: '20',
    scales: 43,
  },
  {
    date: '21',
    scales: 25,
  },
  {
    date: '22',
    scales: 31,
  },
  {
    date: '23',
    scales: 48,
  },
  {
    date: '24',
    scales: 37,
  },
  {
    date: '25',
    scales: 25,
  },
  {
    date: '26',
    scales: 31,
  },
  {
    date: '27',
    scales: 48,
  },
  {
    date: '28',
    scales: 3,
  },
  {
    date: '29',
    scales: 25,
  },
  {
    date: '30',
    scales: 1,
  },
]
