import DashboardPage from 'pages/dashboard/DashboardPage';
import MapsPage from 'pages/maps/MapsPage';
import ResetPasswordPage from 'pages/resetPassword/ResetPasswordPage';
import SignUpPage from 'pages/signUp/SignUpPage';
// import TeamLeadProjectsPage from 'pages/manageProjects/ManageProjectPage'
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import AnalyticsPage from 'pages/analytics/AnalyticsPage';
import TeamsPage from 'pages/teams/TeamsPage';
import ReportsPage from 'pages/reports/ReportsPage';
import Login from '@components/Login';
import authConfig from 'authConfig';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import DefectsPage from 'pages/defects/DefectsPage';
// import Example2 from 'pages/exm/Example2';
import Example from 'pages/exm/Example';
import Sidebar from '@components/reusable/sidebar/Sidebar';
import { useSelector, useDispatch } from 'react-redux';

import { getUserInfo } from 'feature/userInfoSlice';
import TasksPage from 'pages/task/TasksPage';
import CreateNewProject from 'pages/createProject/CreateNewProject';
import ResourcesPage from 'pages/resources/ResourcesPage';
import ContractorsPage from 'pages/contractors/ContractorsPage';
import Drafts from 'pages/exm/Drafts';
// import OnEditOrCreate from '@components/tasksComponents/OnEditOrCreateTask'
import { ApiBaseUrl } from 'app/environment';
import ManageProjectPage from 'pages/manageProjects/ManageProjectPage';
import EditTask from '@components/tasksComponents/edit/EditTask';
import OnEditOrCreateProject from '@components/manageProjectsComponents/OnEditOrCreateProject';
import CreateTask from '@components/tasksComponents/create/CreateTask';
import Faq from 'pages/faq/faq';
import Calendar from 'pages/Calendar/calendar';

function App() {
  // const data = useSelector((state: any) => state.employyes.data)

  const dispatch: any = useDispatch()
  const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    ...authConfig,
  })

  function authorize() {
    userManager.signinRedirect({ state: 'a2123a67ff11413fa19217a9ea0fbad5' })
  }

  // ------------------
  const [authenticated, setAuthenticated] = useState<any>(null)

  const [userInfo, setUserInfo] = useState<any>(
    JSON.parse(localStorage.getItem('userInfo') || 'null'),
  )
  // console.log('App ~ apiBaseUrl >', ApiBaseUrl)

  // useEffect(() => {
  //   dispatch(getEmployyes('1'))
  //   console.log('app runs')
  //   // dispatch(getEnvData('2'))
  // }, [])

  useEffect(() => {
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  }, [userInfo, authenticated])

  useEffect(() => {
    if (userInfo) {
      dispatch(getUserInfo(userInfo.sub))
      localStorage.setItem('userID', JSON.stringify(userInfo.sub))
    }
  }, [userManager])

  // ------------------

  useEffect(() => {
    userManager.getUser().then((user) => {
      if (user) {
        setAuthenticated(true)
      } else {
        setAuthenticated(false)
      }
    })
  }, [userManager])

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* <Route path='/manage-projects' element={<TeamLeadProjectsPage />} /> */}
        <Route path='/manage-projects' element={<ManageProjectPage />} />
        <Route path='/sign-up' element={<SignUpPage />} />
        <Route path='/reset-password' element={<ResetPasswordPage />} />
        <Route path='/outline/defects' element={<DefectsPage />} />

        <Route path='/teams' element={<TeamsPage />} />
        <Route path='/reports' element={<ReportsPage />} />
        <Route path='/maps' element={<MapsPage />} />

        <Route path='/analytics' element={<AnalyticsPage />} />
        <Route path='/example' element={<Example />} />
        <Route path='/drafts' element={<Drafts />} />
        <Route path='/tasks' element={<TasksPage />} />
        <Route path='/create-new-project' element={<CreateNewProject />} />
        <Route path='/resources' element={<ResourcesPage />} />
        <Route path='/contractors' element={<ContractorsPage />} />
        <Route path='/edit-task' element={<EditTask />} />
        <Route path='/create-task' element={<CreateTask />} />
        <Route path='/edit-create-project' element={<OnEditOrCreateProject />} />
        <Route path='/' element={<Login auth={authenticated} handleLogin={authorize} />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/calendar' element={<Calendar />} />
        <Route
          path='/dashboard'
          element={
            <DashboardPage
              auth={authenticated}
              setAuth={setAuthenticated}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              userManager={userManager}
            />
          }
        />
      </Route>
    </Routes>
  )
}

export default App
