const ToolsTabContent = ({ data }: any) => {

  return (
    <>
      <div className='w-full mx-auto font-semibold bg-white'>
        <div className='flex w-full h-[80px]'>
          <div className='flex-shrink w-[100px] flex-col border center'>
            <p className='text-center'>No</p>
          </div>
          <div className='flex-1 border center'>
            <p className='text-center'>Name</p>
          </div>
          <div className='flex-1 border center'>
            <p className='text-center'>Zone</p>
          </div>
          <div className='flex-1 border center'>
            <p className='text-center'>Safety level out of 10</p>
          </div>
        </div>
      </div>
      <div>
        {data.map((object: any, index: number) => {
          return (
            <div key={index} className='w-full mx-auto bg-white'>
              <div className='flex w-full'>
                <div className='flex-shrink flex flex-col border w-[100px] items-start min-h-[115px] max-h-32'>
                  <div className='pl-3 py-5'>
                    <p className='text-center'>{index + 1}</p>
                  </div>
                </div>
                <div className='flex-1 border'>
                  <div className='pl-3 py-5'>
                    <p className=''>{object.prj_res_name}</p>
                  </div>
                </div>
                <div className='flex-1 border'>
                  <div className='pl-3 py-5'>
                    <p className=''>{object.prj_res_zone}</p>
                  </div>
                </div>
                <div className='flex-1 border'>
                  <div className='pl-3 py-5'>
                    <p className=''>{object.prj_res_risk_level}</p>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default ToolsTabContent
