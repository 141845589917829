let webBaseUrl
let apiBaseUrl

webBaseUrl = "http://localhost:3000"
apiBaseUrl = "http://localhost:4000"

if (process.env.REACT_APP_ENVIRONMENT === 'deployment') {
  webBaseUrl = "https://magnus.plus"
  apiBaseUrl = "api.magnus.plus"
  // apiBaseUrl = "https://api.magnus.plus"
  // apiBaseUrl = "http://ec2-54-93-46-76.eu-central-1.compute.amazonaws.com:4000"
  // apiBaseUrl = "https://opy4ueeaomhkh7lab2cwzzlrli0cfgjw.lambda-url.eu-central-1.on.aws"
}

export const WebBaseUrl = webBaseUrl
export const ApiBaseUrl = apiBaseUrl