import projectImg from '../../../images/reusable/project/project.svg'
import dots from '../../../images/reusable/project/3dots.svg'
import clsx from 'clsx'
import { Popover } from 'antd'
import { PenLine, Pencil, Mail } from 'lucide-react'

type Person = {
  projectValue?: object
  status?: string
  num?: number
  _id?: string
  createdAt?: string
  companyId?: string
  name?: string
  projectManager?: string
  projectSize?: string
  subContructors?: string
  teams?: string
  updatedAt?: string
  __v?: number
}

const Project = (props: Person) => {
  console.log(props.status);
  const stat = props.status;
  localStorage.setItem("status-", JSON.stringify(stat));

  console.log(localStorage.getItem("status-"))
  return (
    <div className='bg-white w-[400px] h-[160px] border border-[#E5E7EB] shadow-lg rounded-md text-sm '>
      <div className=' py-4 px-4 '>
        <div className='flex w-full mx-auto'>
          <div className='flex items-start flex-1'>
            <div className='mr-2'>
              <img src={projectImg} alt='project-icon' />
            </div>
            <div className='w-3/5'>
              <p>{props.name}</p>
            </div>
          </div>
          <div className='flex items-start justify-between flex-shrink w-32'>
            <div
              className={clsx(
                'py-2 rounded-xl w-24 font-medium px-1',
                props.status == 'Stopped' && 'bg-stopped text-[#991B1B]',
                props.status == 'In progress' && 'bg-inProgress text-[#92400E]',
                props.status == 'Finished' && 'bg-finished text-[#065F46]',
              )}
            >
              {/* <div className='w-[80px]'> */}
              <p className='w-full center'>{props.status}</p>
              {/* </div> */}
            </div>
            <div className='cursor-pointer'>
              <Popover
                placement='rightTop'
                content={
                  <div className='px-3'>
                    <button
                      type='button'
                      // onClick={() => onEdit(tasksInfo[index]._id)}
                      className='center mb-1 '
                    >
                      <PenLine width={15} />
                      <p className='ml-1'>Edit</p>
                    </button>
                    <button className='center mb-1'>
                      <Mail width={15} strokeWidth={1.5} />
                      <p className='ml-1'>Archive</p>
                    </button>
                    <button className='center mb-1'>
                      <Pencil width={15} />
                      <p className='ml-1'>Change status</p>
                    </button>
                  </div>
                }
                trigger='click'
                overlayClassName=''
              >
                <button type='button' className=' bg-white w-5'>
                  <img className='w-full ' src={dots} alt='dots' />
                </button>
              </Popover>
            </div>
          </div>
        </div>
        <div className='flex flex-col my-1 text-[#9CA3AF]'>
          {/* <p>Project size: {props.projectSize}</p>
          <br /> */}
          <p>Created on: {props.createdAt}</p>
          <br />
          {/* <p>Updated on: {props.updatedAt}</p> */}
        </div>
        {/* <p className='overflow-ellipsis overflow-hidden  h-16'>{props.text}</p> */}
      </div>
    </div>
  )
}

export default Project
