import { Pie, measureTextWidth } from '@ant-design/plots'
import { useEffect, useState } from 'react'

// interface projectsTabDataType {
//   type: string
//   value: number
// }
interface Props {
  donatForTeamData: { type: string; value: number }[]
}

const DonatForTeams = (props: Props) => {
  const [data, setData] = useState<{ type: string; value: number }[]>([])

  useEffect(() => {
    setData(props.donatForTeamData)
  }, [props.donatForTeamData])

  function renderStatistic(containerWidth: any, text: any, style: any) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style)
    const R = containerWidth / 2 // r^2 = (w / 2)^2 + (h - offsetY)^2
    let scale = 1

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))),
        ),
        1,
      )
    }

    const textStyleStr = `width:${containerWidth}px;`
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };">${text}</div>`
  }

  const config: any = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.7,
    innerRadius: 0.9,
    color: ['#EF4444', '#10B981', '#F59E0B', '#3B82F6', '#6366F1'],
    meta: {
      value: {
        formatter: (v: any) => `${v} %`,
      },
    },

    style: {
      background: '#ffffff',
      borderRadius: 8,
      width: 400,
    },

    legend: {
      position: 'right',
      marker: {
        symbol: 'circle',
        style: {
          // stroke: 'red',
        },
      },
    },

    label: {
      fontSize: 50,
      color: '#34d011',
    },

    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container: any, view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect()
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2))
          const text = datum ? datum.type : 'Status'
          return renderStatistic(d, text, {
            fontSize: 23,
          })
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '32px',
        },
        customHtml: (container: any, view: any, datum: any, data: any) => {
          const { width } = container.getBoundingClientRect()
          const text = datum
            ? ` ${datum.value} %`
            : ` ${data.reduce((r: any, d: any) => r + d.value, 0)} %`
          return renderStatistic(width, text, {
            fontSize: 32,
          })
        },
      },
    },

    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  }
  return (
    <div className='w-[400px] h-[400px]'>
      <Pie {...config} />
    </div>
  )
}

export default DonatForTeams
