import clsx from 'clsx'


interface Tab {
  id?: any
  name?: any
  img?: any
}

interface TabsPanelProps {
  tabs: Tab[]
  setActiveTabId: (id: number) => void
  activeTabId: number
}

const TabsPanel = (props: TabsPanelProps) => {
  const { tabs, activeTabId, setActiveTabId } = props

  const handleClick = (tabId: number) => {
    setActiveTabId(tabId)
  }

  return (
    <nav className='flex  m-10 mr-10 px-10'>
      {tabs.map((tab) => (
        <button
          className={clsx(
            'border px-4 py-1 mr-[13%] flex rounded-md hover:bg-[#B5CEFD] cursor-pointer',
            tab.id === activeTabId ? 'bg-[#B5CEFD]' : '',
            tabs.length > 3 ? 'mr-[5%]' : 'mr-[13%]',
          )}
          key={tab.id}
          onClick={() => handleClick(tab.id)}
        >
          <p>{tab.name}</p>
        </button>
      ))}
    </nav>
  )
}

export default TabsPanel
