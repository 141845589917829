import PropTypes from 'prop-types'

interface ChartDividerLineProps {
  title: string
}

const ChartDividerLine: React.FC<ChartDividerLineProps> = ({ title }) => {
  return (
    <div className='col-span-2 flex items-center justify-between'>
      <p className='text-[#BEBEBE]'>{title}</p>
      <span className='border-w-full flex bg-[#E6E6E6] h-[1px] w-[88%] col-span-2 '></span>
    </div>
  )
}
ChartDividerLine.propTypes = {
  title: PropTypes.string.isRequired,
}
export default ChartDividerLine
