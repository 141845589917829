// Base

import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsersGroup } from 'feature/usersGroupSlice'
import clsx from 'clsx'
import { Pagination } from 'antd'

// Components
import { TruncatedText } from '@components/reusable/truncatedText/TruncatedText'

// Images

import tempImg from 'images/analitics/temperature.svg'
import noiseImg from 'images/analitics/noiseEar.svg'
import preasureImg from 'images/analitics/pressure.svg'
import humidityImg from 'images/analitics/humidity.svg'
import airImg from 'images/analitics/mask.svg'
import workerPicture from 'images/analitics/workerPicture.png'
import { convertToFahrenheit } from 'hooks/unitsOfMeasurment'
import { ApiBaseUrl } from 'app/environment'

const Teams = () => {
  const dispatch = useDispatch()
  // Initialize state variables

  const [value, setValue] = useState(0)
  const [workers, setWorkers] = useState<any[]>([])
  const [total, setTotal] = useState<any>('')
  const [page, setPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(5)
  const [eventSentEventsMessage, setEventSentEventsMessage] = useState('')

  // Fetch data from Redux store
  const selectedProjectInfo = useSelector((state: any) => state.projects.selectedProject)
  const userGroupsInfo = useSelector((state: any) => state.usersGroup.data)
  const settingsTemperature = useSelector(
    (state: any) => state.settings?.data.temperature_measurement,
  )
  const MAX_TIME_DIFFERENCE = 10000
  const [tipsterDataDict, setTipsterDataDict] = useState<any>({})
  // console.log('Teams ~ tipsterDataDict >', tipsterDataDict)
  // 646cf879cde01a987ff4891b

  // URL for tipster data
  const tipsterDataUrl = `https://18.193.23.230/api/project/636b981a9410a61ab2f848c5/tipsterdata`
  // const tipsterDataUrl = `https://api.magnus.plus/api/project/636b981a9410a61ab2f848c5/tipsterdata`
  const [lastReceivedDataTime, setLastReceivedDataTime] = useState<any>({})

  // Function to periodically check and clear data for inactive workers
  const clearInactiveWorkersData = () => {
    const currentTime = Date.now()

    setTipsterDataDict((prevDict: any) => {
      const updatedDict = { ...prevDict }

      for (const userId in prevDict) {
        if (Object.prototype.hasOwnProperty.call(prevDict, userId)) {
          const lastReceivedTime = lastReceivedDataTime[userId]
          const timeDifference = currentTime - lastReceivedTime

          if (timeDifference > MAX_TIME_DIFFERENCE) {
            delete updatedDict[userId] // Remove the worker's data from the copy
          }
        }
      }

      return updatedDict
    })
  }

  // Set up an interval to periodically clear data for inactive workers
  useEffect(() => {
    const interval = setInterval(clearInactiveWorkersData, MAX_TIME_DIFFERENCE)

    return () => {
      clearInterval(interval) // Clear the interval on component unmount
    }
  }, [])
  // Update the last received data time when new tipster data arrives
  useEffect(() => {
    const eventSource = new EventSource(tipsterDataUrl)

    eventSource.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      // console.log('useEffect ~ parsedData >', parsedData)
      let tipUserId: string

      switch (parsedData.message) {
        // ... (your existing switch cases)

        case 'tipster':
          setEventSentEventsMessage(parsedData.message)
          tipUserId = parsedData.tipster_data.tip_user_id

          // Update or add the tipster data in the dictionary
          setTipsterDataDict((prevDict: any) => ({
            ...prevDict,
            [tipUserId]: parsedData.tipster_data,
          }))

          // Update the last received data time for this device
          setLastReceivedDataTime((prevData: any) => ({
            ...prevData,
            [tipUserId]: Date.now(), // Record the current timestamp
          }))

          break
      }
    }

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error)
      eventSource.close()
    }

    return () => {
      console.log('Connection closed.')
      eventSource.close()
    }
  }, [])

  // Calculate the "engaged" status based on the time difference
  const calculateEngagedStatus = (userId: string) => {
    const lastReceivedTime = lastReceivedDataTime[userId] || 0
    const currentTime = Date.now()
    const timeDifference = currentTime - lastReceivedTime

    return timeDifference <= MAX_TIME_DIFFERENCE ? 'Engaged' : 'Not Engaged'
  }

  // Combine tipster data and user data with the "engaged" status
  const teamDataCombined = useMemo(() => {
    return userGroupsInfo.map((team: any) => {
      const newList = team.prj_user_group_list.map((employee: any) => {
        let updatedEmployee = { ...employee }

        if (tipsterDataDict[employee.user_id]) {
          updatedEmployee = {
            ...updatedEmployee,
            ...tipsterDataDict[employee.user_id],
          }
        } else {
          updatedEmployee.tipsterData = null
        }

        // Calculate and add the "engaged" status to the employee
        updatedEmployee.engagedStatus = calculateEngagedStatus(employee.user_id)

        return updatedEmployee
      })

      return {
        ...team,
        prj_user_group_list: newList,
      }
    })
  }, [userGroupsInfo, tipsterDataDict])
  // console.log("teamDataCombined ~ teamDataCombined >", teamDataCombined)

  // Filter data because in the database there are multiple types; we need 'team'
  const teamList = useMemo(() => {
    if (selectedProjectInfo && selectedProjectInfo.comp_prj_id) {
      return teamDataCombined.filter((obj: any) => obj.prj_user_group_type === 'team')
    }
    return []
  }, [selectedProjectInfo, userGroupsInfo, tipsterDataDict])
  // console.log('teamList ~ teamList >', teamList)

  useEffect(() => {
    if (selectedProjectInfo && selectedProjectInfo.comp_prj_id) {
      dispatch(getUsersGroup(selectedProjectInfo?.comp_prj_id))
    }
  }, [selectedProjectInfo, dispatch])

  // Pagination functionality
  useEffect(() => {
    if (teamList && teamList[value]) {
      setWorkers(teamList[value].prj_user_group_list)
      setTotal(teamList[value].prj_user_group_list.length)
    }
  }, [teamList, value])

  // Calculate pagination indexes and list of workers for current page

  const indexOfLastPage = postPerPage * page
  const indexOfFirstPage = indexOfLastPage - postPerPage
  const listOfWorkers = workers.slice(indexOfFirstPage, indexOfLastPage)

  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }

  // Different functions
  const ref = useRef<any>(null)

  // Dropdown functionality

  const [isOpen, setIsOpen] = useState(false)

  const node = useRef<HTMLDivElement>(null)
  const handleChangeTeam = (i: any) => {
    setValue(i)
    setIsOpen((prev) => !prev)
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (node.current?.contains(e.target as Node)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return (
    <div ref={ref as any} className='w-[1050px] h-[600px] z-10 bg-white rounded-md relative'>
      <div className='flex mb-6 mt-9 ml-7'>
        <div ref={node} className='relative'>
          <button
            className={clsx(
              'border border-blue px-2 py-[8px] rounded-3xl',
              isOpen ? 'bg-blue text-white' : 'bg-white',
            )}
            onClick={handleClick}
          >
            {teamList[value] && (
              <TruncatedText maxNum='15' text={teamList[value].prj_user_group_name} />
            )}
          </button>
          {isOpen && (
            <ul className='absolute top-12 left-[-20px] bg-white z-50 shadow-2xl rounded-lg'>
              {teamList.map((obj: any, i: number) => (
                <li
                  style={{ padding: '10px 0 10px 0', cursor: 'pointer' }}
                  key={i}
                  onClick={() => handleChangeTeam(i)}
                  className='hover:bg-hoverColor px-4 w-[240px] '
                >
                  <p className='my-2 ml-2'>{obj.prj_user_group_name}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div className='flex ml-5 mb-4'>
        <input type='checkbox' className='h-5 w-5 mr-3 cursor-pointer' />
        <p className='ml-6'>Image</p>
        <p className='ml-16'>Name</p>
        <p className='ml-24'>Occupation</p>
        <p className='ml-[72px]'>Status</p>

        <div className='ml-[80px] text-[#81C2FF] text-lg font-bold'>
          {settingsTemperature === 'C' ? <p className=''>°C</p> : <p className=''>°F</p>}
        </div>

        <img className='ml-[70px]' src={airImg} alt='airImg' />
        <img className='ml-[55px]' src={humidityImg} alt='humidityImg' />
        <img className='ml-[70px]' src={preasureImg} alt='preasureImg' />
        <img className='ml-[63px]' src={noiseImg} alt='noiseImg' />
      </div>
      <hr className='w-[95%] mx-auto my-0 mb-7' />

      <ul className='flex flex-col mt-8 ml-5'>
        {listOfWorkers.map((employee: any, index: any) => {
          return (
            <li className='flex items-center mb-5' key={index}>
              <input type='checkbox' className='h-5 w-5 mr-3 cursor-pointer' />
              <img className='ml-6' src={workerPicture} alt='workerPicture' />
              {/* <p>{(page - 1) * postPerPage + index + 1}</p> */}

              <TruncatedText className='ml-6 w-[150px]' maxNum='16' text={employee.user_name} />
              <TruncatedText
                className='ml-5 w-[150px]'
                maxNum='16'
                text={employee.user_occupation}
              />

              <p className='ml-5 w-[130px]'>{employee.engagedStatus}</p>
              {employee.engagedStatus === 'Not Engaged' ? (
                <div className='grid grid-cols-5 w-[43%]'>
                  <p className='text-[#2D99FF] ml-2'>-</p>
                  <p className='text-[#826AF9]'>- AQI</p>
                  <p className='text-[#2CD9C5]'>- %</p>
                  <p className='text-[#FF6C40]'>- hPa</p>
                  <p className='text-[#141BCD]'>- dB</p>
                </div>
              ) : (
                <div className='grid grid-cols-5 w-[43%]'>
                  <p className='text-[#2D99FF]'>
                    {settingsTemperature === 'C'
                      ? `${Math.floor(employee.env_temperature)}`
                      : `${convertToFahrenheit(Math.floor(employee.env_temperature))}`}
                  </p>

                  <p className='text-[#826AF9]  '>
                    {employee.env_air_quality
                      ? `${Math.floor(employee.env_air_quality)}AQI`
                      : '- AQI'}
                  </p>
                  <p className='text-[#2CD9C5] '>
                    {employee.env_air_quality ? `${Math.floor(employee.env_humidity)}%` : '- %'}
                  </p>
                  <p className='text-[#FF6C40] '>
                    {employee.env_air_quality ? `${Math.floor(employee.env_pressure)}hPa` : '- hPa'}
                  </p>
                  <p className='text-[#141BCD] '>
                    {employee.env_air_quality ? `${Math.floor(employee.env_noise)}dB` : '- dB'}
                  </p>
                </div>
              )}
            </li>
          )
        })}

        <Pagination
          className='absolute right-16 bottom-10'
          showQuickJumper
          onChange={(value: any) => setPage(value)}
          pageSize={postPerPage}
          total={total}
          simple
          onShowSizeChange={onShowSizeChange}
          current={page}
        />
      </ul>
    </div>
  )
}

export default Teams
