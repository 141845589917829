import { ApiBaseUrl } from 'app/environment'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import axiosInstance from 'axiosInstance'

export const getPhases = createAsyncThunk(
  'getPhases/phasesSlice',
  async (id: string, { rejectWithValue }) => {
    try {
      const url = `api/projects/${id}/phase`
      const response = await axiosInstance.get(url)

      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

interface initialStateType {
  data: []
  error: null
  loading: boolean
}

const initialState: initialStateType = {
  data: [],
  error: null,
  loading: false,
}

const phasesSlice = createSlice({
  name: 'phases',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPhases.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getPhases.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(getPhases.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload as null
      })
  },
})

export default phasesSlice.reducer
