import { useState, useEffect } from 'react';
import Project from '../reusable/project/Project';
import { useSelector } from 'react-redux';

const ManageProjects = () => {
  const companyProjects = useSelector((state: any) => state.projects?.data);
  const [sortedProjects, setSortedProjects] = useState(companyProjects);
  const [selectedProjectID, setSelectedProjectID] = useState<string | null>(() =>
    JSON.parse(localStorage.getItem('selectedProjectID') || 'null')
  );

  // Function to sort projects based on selectedProjectID
  const sortProjects = () => {
    if (selectedProjectID) {
      const sorted = [...companyProjects].sort((a, b) => {
        if (a.comp_prj_id === selectedProjectID) return -1;
        if (b.comp_prj_id === selectedProjectID) return 1;
        return 0;
      });
      setSortedProjects(sorted);
    } else {
      setSortedProjects(companyProjects);
    }
  };

  // Use useEffect to sort projects when companyProjects or selectedProjectID changes
  useEffect(() => {
    sortProjects();
  }, [companyProjects, selectedProjectID]);

  // Use useEffect to listen for changes in localStorage
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'selectedProjectID') {
        setSelectedProjectID(JSON.parse(event.newValue!));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Function to set the selected project ID and trigger sorting
  const handleSetSelectedProjectID = (id: string) => {
    localStorage.setItem('selectedProjectID', JSON.stringify(id));
    setSelectedProjectID(id);
  };

  return (
    <div>
      <div className='bg-[#F9FAFB] mt-10 w-[1000px] h-auto pb-5 rounded-md'>
        <div className='pt-4 flex justify-around'>
          <p className='font-bold text-lg'>Project List</p>
          {companyProjects.length && (
            <p className='font-semibold text-lg'>Total: {companyProjects.length}</p>
          )}
        </div>
        <span className='h-[1px] w-[86%] border-b border-[#E5E7EB] mx-auto pt-6 mb-6 flex justify-between'></span>
        <div className='center'>
          <div className='grid grid-cols-2 gap-x-16 gap-y-4'>
            {Array.isArray(sortedProjects) &&
              sortedProjects.length > 0 &&
              sortedProjects.map((project: any, i: number) => {
                const createdAtDate = new Date(project.comp_prj_registered);
                const year = createdAtDate.getFullYear();
                const month = String(createdAtDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
                const day = String(createdAtDate.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;

                return (
                  <div key={i} className={i === 0 ? 'border-2 border-black rounded-lg' : ''}>
                    <Project
                      status={'In progress'}
                      name={project.comp_prj_name}
                      createdAt={formattedDate}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageProjects;
