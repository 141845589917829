const data = [
  {
    category: 'Incident',
    date: 'Mon',
    value: 44,
  },
  {
    category: 'Incident',
    date: 'Tue',
    value: 50,
  },
  {
    category: 'Incident',
    date: 'Wen',
    value: 55,
  },
  {
    category: 'Incident',
    date: 'Thu',
    value: 59,
  },
  {
    category: 'Incident',
    date: 'Fri',
    value: 50,
  },
  {
    category: 'Incident',
    date: 'Sut',
    value: 45,
  },
  {
    category: 'Incident',
    date: 'Sun',
    value: 53,
  },

  {
    category: 'Accident',
    date: 'Mon',
    value: 55,
  },
  {
    category: 'Accident',
    date: 'Tue',
    value: 24,
  },
  {
    category: 'Accident',
    date: 'Wen',
    value: 55,
  },
  {
    category: 'Accident',
    date: 'Thu',
    value: 69,
  },
  {
    category: 'Accident',
    date: 'Fri',
    value: 30,
  },
  {
    category: 'Accident',
    date: 'Sut',
    value: 35,
  },
  {
    category: 'Accident',
    date: 'Sun',
    value: 40,
  },
]

import { Line } from '@ant-design/plots'

const AccidentIncidentLines = () => {
  const config: any = {
    data,
    xField: 'date',
    yField: 'value',
    seriesField: 'category',
    point: {
      shape: 'circle',
      size: 5,
    },

    smooth: true,
    xAxis: {
      grid: {
        line: {
          style: {
            lineWidth: 1,
            lineDash: [4, 4],
            strokeOpacity: 0.3,
            cursor: 'pointer',
          },
        },
      },
    },

    yAxis: {
      grid: {
        line: {
          style: {
            lineWidth: 1,
            lineDash: [4, 4],
            strokeOpacity: 0.5,
            cursor: 'pointer',
          },
        },
      },
    },
    legend: {
      position: 'bottom-left',
      marker: {
        // You can customize the legend marker here
        symbol: 'circle', // Choose from 'circle', 'square', 'diamond', 'triangle', 'star', 'cross', or provide a custom SVG path
      },
    },

    animation: {
      appear: {
        duration: 3000,
      },
    },

    color: ['#1979C9', '#D62A0D'],
  }

  return (
  
      <Line {...config} />
  
  )
}

export default AccidentIncidentLines
