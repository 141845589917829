import React, { useEffect, useState } from 'react'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
import axios from 'axios'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Link } from 'react-router-dom'

interface Manager {
  id: number
  name: string
}

interface Owner {
  id: number
  name: string
}

interface Contractor {
  id: number
  name: string
}

const CreateNewProject = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<any>()
  const [managers, setManagers] = useState<Manager[]>([])
  const [owners, setOwners] = useState<Owner[]>([])
  const [contractors, setContractors] = useState<Contractor[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const managersRes = await axios.get('/api/managers')
        const ownersRes = await axios.get('/api/owners')
        const contractorsRes = await axios.get('/api/contractors')
        setManagers(managersRes.data)
        setOwners(ownersRes.data)
        setContractors(contractorsRes.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log(data)
    axios.post('url', data)
      .then((response) => {
        // handle success
      })
      .catch((error) => {
        console.error('Error sending data:', error.response)
      })
  }

  return (
    <div className='flex font-inter h-screen w-screen text-[#374151] '>
      <Sidebar />
      <div className='flex flex-col w-[calc(100%-330px)]  relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto flex flex-col '>
          <div className='w-full max-w-[1200px] mx-auto my-0'>
            <div className='my-12'>
              <Breadcrumbs items={['Project', 'Create New Project']} />
            </div>

            <div className='h-full mb-12 '>
              <h1 className='text-2xl font-semibold my-12 ml-8'>Create a project</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-2 gap-y-6 ml-8 mb-12'>
                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='project-input'>
                      Project Name:
                    </label>
                    <input
                      className='bg-white w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='ex. Project 1'
                      {...register('project', { required: true, maxLength: 55 })}
                    />
                    {errors.project?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        Project name is required
                      </p>
                    )}
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='address-input'>
                      Address:
                    </label>
                    <input
                      className='bg-white w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='ex. Rahumae street 10b'
                      {...register('address', { required: true, maxLength: 55 })}
                    />
                    {errors.address?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        Address is required
                      </p>
                    )}
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='address-input'>
                      Type:
                    </label>
                    <input
                      className='bg-white w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='ex. Rahumae street 10b'
                      {...register('address', { required: true, maxLength: 55 })}
                    />
                    {errors.address?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        Address is required
                      </p>
                    )}
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='createdDate-input'>
                      Start Date:
                    </label>

                    <input
                      className='bg-white w-5/6 py-3 px-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='ex. 01.11.2023'
                      type='date'
                      {...register('createdDate', { required: true })}
                    />
                    {errors.createdDate?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        Start Date is required
                      </p>
                    )}
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='completionTime-input'>
                      End Date:
                    </label>
                    <input
                      className='bg-white w-5/6 py-3 px-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='ex. 01.11.2023'
                      type='date'
                      {...register('completionTime', { required: true })}
                    />
                    {errors.completionTime?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        End Date is required
                      </p>
                    )}
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='projectManager'>
                      Project Manager Name:
                    </label>
                    <select className='bg-white w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      {...register('projectManager', { required: true })}
                    >
                      {managers.map(manager => (
                        <option key={manager.id} value={manager.id}>{manager.name}</option>
                      ))}
                    </select>
                    {errors.projectManager && <p className='text-[#8b2d2d]' role='alert'>Project manager name is required</p>}
                  </div>


                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='projectOwner'>
                      Project Owner:
                    </label>
                    <select className='bg-white w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      {...register('projectOwner', { required: true })}
                    >
                      {owners.map(owner => (
                        <option key={owner.id} value={owner.id}>{owner.name}</option>
                      ))}
                    </select>
                    {errors.projectOwner && <p className='text-[#8b2d2d]' role='alert'>Project Owner is required</p>}
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='generalContractor'>
                      General Contractor:
                    </label>
                    <select className='bg-white w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      {...register('generalContractor', { required: true })}
                    >
                      {contractors.map(contractor => (
                        <option key={contractor.id} value={contractor.id}>{contractor.name}</option>
                      ))}
                    </select>
                    {errors.generalContractor && <p className='text-[#8b2d2d]' role='alert'>General contractor is required</p>}
                  </div>

                </div>
                <div className='ml-96'>
                  <Link to='/manage-projects'>
                    <button
                      type='button'
                      className='text-blue-600 border bg-blue text-white border-blue-600 rounded-lg px-6 py-2 mr-6'
                    >
                      Cancel
                    </button>
                  </Link>
                  <button
                    type='button'
                    className='text-blue-600 border text-white bg-blue border-blue-600 rounded-lg px-6 py-2 mr-6'
                  >
                    Create a project
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default CreateNewProject
