import GoogleMaps from '@components/mapsComponents/GoogleMaps'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import React, { useEffect, useState } from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { fetchEmployee } from '../../feature/delete-files-in-future/employyeSlice'
import { useSelector, useDispatch } from 'react-redux'
import DraggbleBlock from '@components/draggble/DraggbleBlock'
import { zonesInMaps } from '../../data/mapsPage/ZoneData'
// images
import topImg from '../../images/map/mapTopBlock.svg'
import layers from '../../images/map/layers.svg'
import coloredLayers from '../../images/map/coloredLayers.svg'
import openDragble from '../../images/map/openDrugbleComponent.svg'
import locationMark from '../../images/map/locationMark.svg'
import mylocation from '../../images/map/mylocation.svg'
import arrow from '../../images/navbar/arrowDown.svg'
import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'

interface zonesInMapsInterface {
  id: string
  color?: string
}

const layersItems = ['Thermal Mapping', '3D Map', '2D Map', 'Google Map']

const libraries: ('places' | 'visualization')[] = ['places']
const API_KEY = process.env.REACT_APP_API_KEY as string

const MapsPage = () => {
  const selectedProjectZones = useSelector(
    (state: any) => state.selectedProjectData?.data[0]?.prj_zones,
  )
  // console.log('MapsPage ~ selectedProjectZones >', selectedProjectZones)

  const [openZonesBlock, setOpenZonesBlock] = useState<boolean>(false)
  const [openLayersBlock, setOpenLayersBlock] = useState<boolean>(false)
  const [draggbleComponent, setDraggbleComponent] = useState<boolean>(false)
  const dispatch = useDispatch<any>()
  // const employees = useSelector<any>((state: any) => state.employees.employees)

  // useEffect(() => {
  //   // fetchEmployee from employyesSlice
  //   dispatch(fetchEmployee())
  // }, [dispatch])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries,
  })

  return (
    <div className='flex font-inter h-screen w-screen text-[#374151] '>
      <Sidebar />
      <div className='flex flex-col w-[calc(100%-330px)]  relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto flex flex-col '>
          <div className='my-8 ml-44'>
            <Breadcrumbs items={['Projects', 'Locations']} />
          </div>

          <div className='relative w-full  h-[67%] center'>
            <div className='absolute top-2.5 right-20 z-10 flex'>
              <div className='bg-white rounded-md center mr-2 w-12 cursor-pointer'>
                <img src={locationMark} alt='locationMark' />
              </div>
              <div
                onClick={(e) => setOpenZonesBlock((prev: any) => !prev)}
                className='relative cursor-pointer flex pl-16 pr-2 items-center  rounded-md justify-between  w-64 h-10 bg-white'
              >
                <p className='text-[#949FC2]'>Select zone</p>
                <img className=' w-8 h-8' src={arrow} alt='arrow' />
                {/* show zones on click */}
                {openZonesBlock && selectedProjectZones && (
                  <ul className='absolute top-12 right-0 rounded-sm'>
                    {selectedProjectZones.map((zone: any, i: number) => (
                      <li
                        className='hover:bg-slate-50 cursor-pointer border bg-white font-medium px-4 flex justify-between w-[258px] py-3 center'
                        key={i}
                      >
                        {zone.prj_zone_name.length > 22 ? (
                          <>
                            <span>{zone.prj_zone_name.slice(0, 22)}...</span>
                          </>
                        ) : (
                          <span>{zone.prj_zone_name.slice(0, 22)}</span>
                        )}
                        <span
                          style={{
                            width: '16px',
                            height: '16px',
                            backgroundColor: zone.prj_zone_color,
                          }}
                        ></span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            {isLoaded ? <GoogleMaps /> : <h2 className='center text-2xl'>Loading...</h2>}

            {/* btns on below map */}
            <div className='absolute flex flex-col  bottom-[104px] right-[11px]'>
              <div
                onClick={(e) => setDraggbleComponent((prev: any) => !prev)}
                className='w-[39px] h-10 bg-white rounded-sm center mb-4 cursor-pointer hover:bg-[#f1f5f5]'
              >
                <img src={openDragble} alt='openDragble' />
              </div>
              <div
                onClick={(e) => setOpenLayersBlock((prev: any) => !prev)}
                className='w-[39px] h-10 bg-white rounded-sm center mb-4 cursor-pointer hover:bg-[#f1f5f5]'
              >
                {openLayersBlock ? (
                  <img src={coloredLayers} alt='layers' />
                ) : (
                  <img src={layers} alt='layers' />
                )}
              </div>

              <div className='w-[39px] h-10 border-b bg-white rounded-sm center cursor-pointer hover:bg-[#f1f5f5]'>
                <img src={mylocation} alt='mylocation' />
              </div>
            </div>

            {/* show layers on click */}
            {openLayersBlock && (
              <ul className='absolute right-[60px] bottom-[42px]'>
                {layersItems.map((zone, i) => (
                  <li
                    onClick={(e) => console.log(zone)}
                    className='hover:bg-slate-50 h-10 w-64  cursor-pointer border bg-white rounded-sm  flex items-center justify-between pl-24 pr-6'
                    key={i}
                  >
                    {zone}
                  </li>
                ))}
              </ul>
            )}
            {/* {draggbleComponent && (
              <DraggbleBlock
                employees={employees}
                setLimit={{ right: 360, bottom: 200 }}
                className='absolute bottom-[38px] right-[360px]'
              />
            )} */}
          </div>
          {/* this is second block */}
          <div className='flex-1 center'></div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default MapsPage
