import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { defects, defects2, defects3, defects4, defectDisplay } from 'data/defectsPage/defects'
import InputWithIcon from '@components/reusable/inputWithIcon/InputWithIcon'
import search from 'images/draggble/search.svg'
import { Pagination } from 'antd'
// import 'antd/lib/pagination/style/index.css'

const DefectsTabContent = ({ activeTabId }: any) => {
  const [statusDisplay, setStatusDisplay] = useState(true)

  // pagination state and variables ************
  const [workers, setworkers] = useState<any>([])
  const [total, setTotal] = useState<any>('')
  const [page, setPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(5)

  useEffect(() => {
    if (defectDisplay) {
      setworkers(defectDisplay)
      setTotal(defectDisplay.length)
    }
  }, [defectDisplay])

  // pagination logic ************
  const indexOfLastPage = postPerPage * page
  const indexOfFirstPage = indexOfLastPage - postPerPage
  const listOfdefects = workers.slice(indexOfFirstPage, indexOfLastPage)

  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }
  // pagination code ************

  return (
    <div>
      <div className={clsx('w-full mb-32', activeTabId === 0 ? 'flex justify-between' : 'hidden')}>
        <div className='w-[770px] h-[700px] border bg-white rounded-md'>
          <div className='flex justify-between px-6 py-4'>
            <div className='grid grid-cols-3 w-3/5'>
              <p>Total</p>
              <p>Completed</p>
              <p>Pending</p>
              <p className='text-xl font-semibold'>142</p>
              <p className='text-xl font-semibold'>44</p>
              <p className='text-xl font-semibold'>12</p>
            </div>
            {/* <input type='text' placeholder='Defect No.1' className='border px-2 rounded-md' />
             */}
            <InputWithIcon
              type='text'
              placeholder='Defect No.1'
              img={search}
              className='w-[240px] bg-[#F1F1F1]'
            />
          </div>
          <span className='border-w-full flex bg-[#E6E6E6] h-[1px] mb-5'></span>
          <div className='center mb-4'>
            <div className='grid grid-cols-4 gap-x-[16%] items-center w-[95%] h-[35px] bg-[#E0E0E0] text-xs rounded-lg pl-8'>
              <p className=''>Type of defect</p>
              <p className=''>Date & Time</p>
              <p className=''>Asignee</p>
              <p className=''>Status</p>
            </div>
          </div>
          <div className='relative'>
            {/* list of defects */}
            <ul>
              {listOfdefects.map((defect: any, i: number) => {
                return (
                  <li key={i}>
                    <div className='grid grid-cols-4 text-[13px] ml-12'>
                      <div>
                        <div className='flex'>
                          <p className='mr-1'>{defect.id}.</p>
                          <p>{defect.name}</p>
                        </div>
                        <button className='text-[10px] text-blue'>See description</button>
                      </div>

                      <div className=''>
                        <p>{defect.date}</p>
                      </div>
                      <div className='pl-10'>
                        <p>{defect.assignee}</p>
                      </div>
                      <div className='pl-16'>
                        <p className='bg-green-400 inline-block whitespace-nowrap px-2 py-2 rounded-lg'>
                          {defect.status}
                        </p>
                        <button
                          onClick={() => setStatusDisplay((prev) => !prev)}
                          className='text-[10px] text-blue'
                        >
                          Change status
                        </button>
                      </div>
                    </div>
                    <span className='border-w-full flex bg-[#E6E6E6] h-[1px] my-2'></span>
                  </li>
                )
              })}
              <Pagination
                className='absolute right-16 top-[480px]'
                showQuickJumper
                onChange={(value: any) => setPage(value)}
                pageSize={postPerPage}
                total={total}
                simple
                onShowSizeChange={onShowSizeChange}
                current={page}
              />
            </ul>
            <div
              className={clsx(
                'absolute top-14 right-32 w-72 h-72 bg-white rounded-lg shadow-2xl',
                statusDisplay && 'hidden',
              )}
            >
              <div className='flex justify-around items-center mt-2 mb-8'>
                <p>Change status</p>
                <button
                  onClick={() => setStatusDisplay((prev) => !prev)}
                  className='text-[42px] rotate-[46deg]'
                >
                  +
                </button>
              </div>
              <div className='px-12 text-black'>
                <div className='flex justify-between  mb-5'>
                  <p className='bg-green-400 inline-block whitespace-nowrap px-2 py-2 rounded-lg'>
                    completed
                  </p>

                  <input type='checkbox' style={{ transform: 'scale(1.5)' }} />
                </div>
                <div className='flex justify-between mb-5'>
                  <p className='bg-amber-400 inline-block whitespace-nowrap px-2 py-2 rounded-lg'>
                    in progress
                  </p>
                  <input type='checkbox' style={{ transform: 'scale(1.5)' }} />
                </div>
                <div className='flex justify-between mb-5'>
                  <p className='bg-red-500 inline-block whitespace-nowrap px-2 py-2 rounded-lg'>
                    canceled
                  </p>
                  <input type='checkbox' style={{ transform: 'scale(1.5)' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=''>
          <div className='w-[330px] h-[370px] overflow-y-auto bg-white rounded-md border mb-20'>
            <h3 className='text-2xl center my-4'>Average Top Defects</h3>
            {defects.map((defect: any, i: any) => {
              return (
                <div className='flex' key={i}>
                  <span className='mx-3'>{i + 1}</span>
                  <p className='mr-4'>{defect.name.slice(0, 30)}</p>
                  <p>{defect.amount}</p>
                </div>
              )
            })}
          </div>
          <div className='w-[330px] h-[300px] overflow-y-auto bg-white rounded-md border'>
            <h1 className='text-xl center my-4'>Outstanding Overdue Defects</h1>
            <div className='flex flex-col pl-4'>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-5'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-5'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-5'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-5'>50 Defects</div>
            </div>
          </div>
        </div>
      </div>

      <div className={clsx('w-full mb-32', activeTabId === 1 ? 'flex justify-between' : 'hidden')}>
        <div className='w-[770px] h-[700px] border bg-white rounded-md'>
          <div className='flex justify-between'>
            <div className='grid grid-cols-3 w-3/5'>
              <p>Material defects</p>
              <p>Completed</p>
              <p>Pending</p>
              <p>66</p>
              <p>13</p>
              <p>5</p>
            </div>
            <input type='text' placeholder='Defect No.1' className='border' />
          </div>
          <p className='text-4xl'>{activeTabId}</p>
        </div>
        <div className=''>
          <div className='w-[330px] h-[370px] overflow-y-auto bg-white rounded-md border mb-20'>
            <h3 className='text-2xl center my-4'>Average Top Defects</h3>
            {defects2.map((defect: any, i: any) => {
              return (
                <div className='flex' key={i}>
                  <span className='mx-3'>{i + 1}</span>
                  <p className='mr-4'>{defect.name.slice(0, 30)}</p>
                  <p>{defect.amount}</p>
                </div>
              )
            })}
          </div>
          <div className='w-[330px] h-[300px] overflow-y-auto bg-white rounded-md border'>
            <h1 className='text-xl center my-4'>Outstanding Overdue Defects</h1>
            <div className='flex flex-col'>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx('w-full mb-32', activeTabId === 2 ? 'flex justify-between' : 'hidden')}>
        <div className='w-[770px] h-[700px] border bg-white rounded-md'>
          <div className='flex justify-between'>
            <div className='grid grid-cols-3 w-3/5'>
              <p>Equipment defects</p>
              <p>Completed</p>
              <p>Pending</p>
              <p>44</p>
              <p>33</p>
              <p>22</p>
            </div>
            <input type='text' placeholder='Defect No.1' className='border' />
          </div>
          <p className='text-4xl'>{activeTabId}</p>
        </div>
        <div className=''>
          <div className='w-[330px] h-[370px] overflow-y-auto bg-white rounded-md border mb-20'>
            <h3 className='text-2xl center my-4'>Average Top Defects</h3>
            {defects3.map((defect: any, i: any) => {
              return (
                <div className='flex' key={i}>
                  <span className='mx-3'>{i + 1}</span>
                  <p className='mr-4'>{defect.name.slice(0, 30)}</p>
                  <p>{defect.amount}</p>
                </div>
              )
            })}
          </div>
          <div className='w-[330px] h-[300px] overflow-y-auto bg-white rounded-md border'>
            <h1 className='text-xl center my-4'>Outstanding Overdue Defects</h1>
            <div className='flex flex-col'>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx('w-full mb-32', activeTabId === 3 ? 'flex justify-between' : 'hidden')}>
        <div className='w-[770px] h-[700px] border bg-white rounded-md'>
          <div className='flex justify-between'>
            <div className='grid grid-cols-3 w-3/5'>
              <p>Workship defects</p>
              <p>Completed</p>
              <p>Pending</p>
              <p>11</p>
              <p>2</p>
              <p>1</p>
            </div>
            <input type='text' placeholder='Defect No.1' className='border' />
          </div>
          <p className='text-4xl'>{activeTabId}</p>
        </div>
        <div className=''>
          <div className='w-[330px] h-[370px] overflow-y-auto bg-white rounded-md border mb-20'>
            <h3 className='text-2xl center my-4'>Average Top Defects</h3>
            {defects4.map((defect: any, i: any) => {
              return (
                <div className='flex' key={i}>
                  <span className='mx-3'>{i + 1}</span>
                  <p className='mr-4'>{defect.name.slice(0, 30)}</p>
                  <p>{defect.amount}</p>
                </div>
              )
            })}
          </div>
          <div className='w-[330px] h-[300px] overflow-y-auto bg-white rounded-md border'>
            <h1 className='text-xl center my-4'>Outstanding Overdue Defects</h1>
            <div className='flex flex-col'>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
              <div className='w-56 py-4 bg-red-400 rounded-2xl px-2 mb-2'>50 Defects</div>
              <div className='w-44 py-4 bg-green-500 rounded-2xl px-2 mb-2'>20 Defects</div>
              <div className='w-32 py-4 bg-amber-300 rounded-2xl px-2 mb-2'>10 Defects</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DefectsTabContent
