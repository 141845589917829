import { formatDate } from 'hooks/formatDate'

const SubcontractorsTabContent = ({ activeTabId, subcontractors }: any) => {
  return (
    <>
      <div className='w-full mx-auto font-semibold bg-white'>
        <div className='flex w-full h-[80px]'>
          <div className='flex-shrink w-[100px] flex-col border center'>
            <p className='text-center'>No</p>
          </div>
          <div className='flex-1 border center'>
            <p className='text-center'>Name</p>
          </div>
          <div className='flex-1 border center'>
            <p className='text-center'>Services</p>
          </div>
          <div className='flex-1 border center'>
            <p className='text-center'>Duration</p>
          </div>
          <div className='flex-1 flex-col border center'>
            <p className='text-center'>Tasks</p>
          </div>
        </div>
      </div>
      <div>
        {subcontractors.map((subcontractor: any, index: number) => {
          return (
            <div key={index} className='w-full mx-auto bg-white '>
              <div className='flex w-full'>
                <div className='flex-shrink flex flex-col border w-[100px] items-start min-h-[115px] max-h-32'>
                  <div className='pl-3 py-5'>
                    <p className='text-center'>{index + 1}</p>
                  </div>
                </div>
                <div className='flex-1 border '>
                  <div className='pl-3 py-5'>
                    <p className=''>{subcontractor.prj_collab_name}</p>
                  </div>
                </div>
                <div className='flex-1 border '>
                  <div className='pl-3 py-5'>
                    <p className=''>{subcontractor.prj_collab_services}</p>
                  </div>
                </div>
                <div className='flex-1 border'>
                  <div className='pl-3 py-5'>
                    <p className=''>{formatDate(subcontractor.prj_collab_finish)}</p>
                  </div>
                </div>
                <div className='flex-1 border'>
                  <div className='pl-3 py-5'>
                    <div className=''>
                      {subcontractor.prj_collab_tasks.map((task: any, i: number) => {
                        return <div key={i}>{task}</div>
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default SubcontractorsTabContent
