import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import {ApiBaseUrl} from '../../app/environment'

// this is env data fun which takes id of choosen project and returns its data or it takes id of project from local storage
export const fetchEnvironmentData = createAsyncThunk(
  'environment/fetchEnvironmentData',
  async function (projectId: string, { rejectWithValue }) {
    try {
      const projectFromLocalStorage = localStorage.getItem('selectedProject')
      let id
      if (projectFromLocalStorage) {
        const project = JSON.parse(projectFromLocalStorage)
        if (project && project._id) {
          id = project._id
        }
      }
      const url = `${ApiBaseUrl}/api/get/project/analytics/enivornment/${projectId || id}`
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const environmentSlice = createSlice({
  name: 'environment',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchEnvironmentData.pending.type](state) {
      state.status = 'pending'
      state.error = null
    },
    [fetchEnvironmentData.fulfilled.type](state, action) {
      state.status = 'fulfilled'
      state.data = action.payload
      state.error = null
      //   console.log('action >', action)
    },
    [fetchEnvironmentData.rejected.type](state, action) {
      state.status = 'rejected'
      state.data = []
      state.error = action.payload
    },
  },
})

export default environmentSlice.reducer
