import { useEffect, useState } from 'react'
import Modal from './Modal'
import addNewMemberIcon from '../../images/teams/plusAddNewMember.svg'
import axios from 'axios'
import { useForm, SubmitHandler } from 'react-hook-form'
import toast, { Toaster } from 'react-hot-toast'
import { ApiBaseUrl } from 'app/environment'
import DynamicPopup from '@components/reusable/popup/DynamicPopup'
import useSelection from 'antd/lib/table/hooks/useSelection'
import { useSelector } from 'react-redux'

const CreateNewTeamMember = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const selectedCompany = useSelector((state: any) => state.userInfo.selectedCompany?.company_id)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>()
  const onSubmit: SubmitHandler<FormData> = (data) => {
    setIsLoading(true)

    axios
      .post(`${ApiBaseUrl}/api/zitadel/user`, data, {
        params: {
          company_id: selectedCompany,
        },
      })
      .then((response) => {
        notifySeccess()
        reset()
        setIsOpen(false)
      })
      .catch((error) => {
        notifyFailier()
        console.error('Error sending data:', error)
        setIsOpen(false)
        reset()
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleClick = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }
  const notifySeccess = () => toast('User has been created successfully')
  const notifyFailier = () => toast('Something went wrong')

  return (
    <div>
      <button onClick={handleClick} className='center border px-2 py-1 bg-white rounded-lg h-8'>
        <img className='mr-2 w-3' src={addNewMemberIcon} alt='add new team member icon' />
        Add new team member
      </button>
      <Modal isOpen={isOpen} onClose={handleClose} className='customform'>
        {isLoading ? (
          <p className='text-2xl center'>Loading...</p> // Enclose the loading text within a <div>
        ) : (
          <>
            <button className='absolute right-5' onClick={handleClose}>
              <img
                className='mr-2 w-5 transform rotate-45'
                src={addNewMemberIcon}
                alt='add new team member icon'
              />
            </button>
            <h4 className='text-black font-semibold text-xl mt-10 ml-8 mb-6 '>
              Add a new team member
            </h4>

            <main>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='grid grid-cols-2 gap-y-6 ml-8 mb-12'>
                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='firstName-input'>
                      First Name:
                    </label>
                    <input
                      className='bg-[#F1F1F1] w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='ex.John'
                      {...register('firstName', { required: true, maxLength: 25 })}
                    />
                    {errors.firstName?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        First name is required
                      </p>
                    )}
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='lastName-input'>
                      Last Name:
                    </label>
                    <input
                      className='bg-[#F1F1F1] w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='ex.Johnes'
                      {...register('lastName', { required: true, maxLength: 25 })}
                    />
                    {errors.lastName?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        Last name is required
                      </p>
                    )}
                  </div>

                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='email-input'>
                      Email:
                    </label>
                    <input
                      className='bg-[#F1F1F1] w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='ex.johnes.john@gmail.com'
                      type='email'
                      {...register('email', { required: true })}
                    />
                    {errors.email?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        Email name is required
                      </p>
                    )}
                  </div>


                  <div className='flex flex-col'>
                    <label className='mb-1' htmlFor='occupation-input'>
                      Occupation:
                    </label>
                    <input
                      className='bg-[#F1F1F1] w-5/6 py-3 pl-3 text-black rounded-md focus:outline-none focus:ring focus:border-blue-300'
                      placeholder='Plumber'
                      {...register('occupation', { required: true, maxLength: 25 })}
                    />
                    {errors.occupation?.type === 'required' && (
                      <p className='text-[#8b2d2d]' role='alert'>
                        Occupation is required
                      </p>
                    )}
                  </div>

                </div>
                <div className='ml-96'>
                  <button className='text-blue-600 border border-blue-600 rounded-lg px-6 py-2 mr-6' onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    className='text-white border bg-blue border-blue-600 rounded-lg px-6 py-2'
                    type='submit'
                  >
                    Finish
                  </button>
                </div>
              </form>
            </main>

            {/* <Toaster
              toastOptions={{
                icon: '🙃',
                className: 'bg-black',
                style: {
                  borderRadius: '10px',
                  background: '#333',
                  color: '#fff',
                },
              }}
            /> */}
          </>
        )}
      </Modal>
    </div>
  )
}

export default CreateNewTeamMember
