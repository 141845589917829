import clsx from 'clsx'

interface Tab {
  id?: any
  name?: any
  img?: any
}

interface TabsProps {
  tabs: Tab[]
  setActiveTabId: (id: number) => void
  activeTabId: number
}

const Tabs = (props: TabsProps) => {
  const { tabs, activeTabId, setActiveTabId } = props

  const handleClick = (tabId: number) => {
    setActiveTabId(tabId)
  }

  return (
    <nav className='flex text-[#454444]'>
      {tabs.map((tab) => (
        <button
          className={clsx(
            'flex mr-8 cursor-pointer',
            tab.id === activeTabId ? 'border-b-[4px] border-blue' : '', // Add border-bottom to the active tab
          )}
          key={tab.id}
          onClick={() => handleClick(tab.id)}
        >
          {tab.img && (
            <img className='mr-2' src={tab.img} alt={tab.name} style={{ fill: '#B5CEFD' }} />
          )}
          <p
            className={clsx(
              tab.id === activeTabId ? 'text-black' : '', // Add border-bottom to the active tab
            )}
          >
            {tab.name}
          </p>
        </button>
      ))}
    </nav>
  )
}

export default Tabs
