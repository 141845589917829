import { useEffect, useRef, useState, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import notification from '../../../images/header/notification.svg';
import profileIcon from '../../../images/profile/Avatar.webp';
import toast, { Toaster } from 'react-hot-toast';
import help from '../../../images/header/help.svg';
import profilePicture from '../../../images/header/profilePicture.webp';
import { setSelectedProjectInfo, getProjects } from 'feature/projectsInfoSlice';
import { getSelectedProjectData } from 'feature/selectedProjectDataSlice';
import arrowDownWithoutFrames from 'images/navbar/arrowDownWithoutFrames.svg';
import { Popover } from 'antd';
import settingsIcon from 'images/profile/settings.svg';
import helpIcon from 'images/profile/help.svg';
import darkMoodIcon from 'images/profile/dark mode.svg';
import upgradeIcon from 'images/profile/upgrade.svg';
import signOutIcon from 'images/profile/sign out.svg';
import defaultUserImg from 'images/profile/defaultUserImg.webp';
import authConfig from 'authConfig';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { changeTemperatureUnit, getTemperatureUnit } from 'feature/userSettingsSlice';

const Header = memo(function Header() {
  const dispatch: any = useDispatch();

  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [modalShow, setModalShow] = useState(false);

  // Selectors
  const selectedCompany = useSelector((state: any) => state.userInfo.selectedCompany);
  const selectedProjectData = useSelector((state: any) => state.selectedProjectData?.data);
  const companyProjects = useSelector((state: any) => state.projects.data);
  const selectedProjectInfo = useSelector((state: any) => state.projects.selectedProject);
  const settingsTemperature = useSelector((state: any) => state.settings?.data.temperature_measurement);
  console.log('Header ~ settingsTemperature >', settingsTemperature);
  const settingsInfo = useSelector((state: any) => state.settings?.data);

  // Get temperature unit measurment value
  useEffect(() => {
    dispatch(getTemperatureUnit());
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(getProjects(selectedCompany.company_id));
      localStorage.setItem('companyIdForProjects', selectedCompany.company_id);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedProjectInfo) {
      dispatch(getSelectedProjectData(selectedProjectInfo.comp_prj_id));
    }
  }, [selectedProjectInfo]);

  useEffect(() => {
    setSelectedProject(selectedProjectInfo);
  }, [selectedProjectInfo]);

  const changeTemperatureValue = (value: 'C' | 'F') => {
    const userInfoFromDataBase = localStorage.getItem('userInfoFromDataBase');
    let userID;
    if (userInfoFromDataBase) {
      userID = JSON.parse(userInfoFromDataBase);
    }

    if (userID && value) {
      dispatch(changeTemperatureUnit({ userID, value }));
    }
  };

  // Click outside of modal
  const node = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: any) => {
    if (node.current && !node.current.contains(e.target)) {
      setModalShow(false);
    }
  };

  useEffect(() => {
    if (modalShow) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalShow]);

  const handleModalToggle = () => {
    setModalShow((prevModalShow) => !prevModalShow);
  };

  const notify = () => toast('WIP');

  // new code ---------------------------------------------

  const handleValueChange = (id: any) => {
    const selectedProjectFromDropdown = companyProjects.filter((project: any) => project._id == id);
    localStorage.setItem('selectedProject', JSON.stringify(selectedProjectFromDropdown[0]));
    localStorage.setItem('selectedProjectID', JSON.stringify(selectedProjectFromDropdown[0].comp_prj_id));
    dispatch(setSelectedProjectInfo(selectedProjectFromDropdown[0]));
    localStorage.setItem("status-", JSON.stringify('In progress'));
    localStorage.getItem("status-");
    window.location.reload(); // Refresh the page
  };

  const [userInfo, setUserInfo] = useState<any>(JSON.parse(localStorage.getItem('userInfo') || 'null'));

  // Log out functionality
  const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    ...authConfig,
  });

  const logOutUser = () => {
    userManager.signoutRedirect();
    localStorage.removeItem('userInfo');
  };

  const popoverContent = (
    <div className='w-[310px] text-lg font-medium'>
      <div className='pl-6 py-2'>
        {userInfo && (
          <div className='flex items-center'>
            <div className='mr-3'>
              <img className='w-14' src={defaultUserImg} alt='#' />
            </div>
            <div className=''>
              <p className='mr-1'>{userInfo.name}</p>
              <p className='text-[#999999]'>{userInfo.email}</p>
            </div>
          </div>
        )}
        <span className='border-2 center my-4'></span>
        <ul className='flex flex-col gap-y-6'>
          <li className='flex'>
            <img className='mr-2' src={settingsIcon} alt='Settings' />
            <p>Settings</p>
          </li>
          <li className='flex'>
            <img className='mr-2' src={helpIcon} alt='Help Center' />
            <p>Help Center</p>
          </li>
          <li className='flex'>
            <img className='mr-2' src={darkMoodIcon} alt='Dark Mode' />
            <p>Dark Mode</p>
          </li>
          <li className='flex'>
            <img className='mr-2' src={upgradeIcon} alt='Upgrade Plan' />
            <p>Upgrade Plan</p>
          </li>
          <div className=''>
            <p>Unit of measurement:</p>
            <div className='flex '>
              <label className='mr-3 cursor-pointer'>
                <input
                  className='cursor-pointer w-5'
                  type='radio'
                  value='celsius'
                  checked={settingsTemperature === 'C'}
                  onClick={() => changeTemperatureValue('C')}
                />
                Celsius
              </label>
              <label className='cursor-pointer'>
                <input
                  className='cursor-pointer w-5'
                  type='radio'
                  value='fahrenheit'
                  checked={settingsTemperature === 'F'}
                  onClick={() => changeTemperatureValue('F')}
                />
                Fahrenheit
              </label>
            </div>
          </div>
          <span className='border-2 center my-1'></span>
          <li onClick={logOutUser} className='flex cursor-pointer'>
            <img className='mr-2' src={signOutIcon} alt='Sign Out' />
            <p>Sign Out</p>
          </li>
        </ul>
      </div>
    </div>
  );

  // localStorage.setItem("status-", "In progress");
  const statusVar = (localStorage.getItem("status-") || "").replace(/["']/g, "");
  // const statusVar = "In Progress";

  return (
    <div className='flex flex-col justify-center bg-white min-h-[80px] sticky top-0 z-[1000]'>
      <Toaster
        toastOptions={{
          className: 'bg-black',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }}
      />
      <div className='flex justify-between items-center mx-6'>
        <div
          ref={node}
          onClick={handleModalToggle}
          className='relative z-30 flex justify-between px-2 py-2 cursor-pointer text-textDefaultColor'
        >
          <p style={{ width: "85px" }}>Project :</p>
          {selectedProject !== null && selectedProject.comp_prj_name ? (
            <p className='center w-[90%] text-base font-medium'>
              {selectedProject.comp_prj_name.length > 55
                ? `${selectedProject.comp_prj_name.slice(0, 55)}...`
                : selectedProject.comp_prj_name}
            </p>
          ) : (
            <p className=' center text-base font-medium'>Select Project</p>
          )}
          <div style={{ marginLeft: "15px" }} className='center w-3'>
            <img
              className={clsx('w-full h-full', modalShow && 'transform rotate-180')}
              src={arrowDownWithoutFrames}
              alt='arrow-down'
            />
          </div>
          <ul
            className={clsx(
              'absolute top-16 w-[340px] h-auto z-50 text-black bg-white rounded-lg shadow-2xl',
              modalShow ? 'block' : 'hidden',
            )}
          >
            {companyProjects &&
              companyProjects.map((project: any, i: any) => (
                <li
                  onClick={() => {
                    handleValueChange(project._id);
                  }}
                  className='hover:bg-[#2222] text-base list-none border-b pl-2 py-4'
                  key={i}
                >
                  {project.comp_prj_name}
                </li>
              ))}
          </ul>
        </div>
        <div
          className={clsx(
            'py-2 rounded-xl w-26 font-medium px-1',
            statusVar == 'Stopped' && 'bg-stopped text-[#991B1B]',
            statusVar == 'In progress' && 'bg-inProgress text-[#92400E]',
            statusVar == 'Finished' && 'bg-finished text-[#065F46]',
          )}
        >
          <p style={{ paddingLeft: "0.25rem", paddingRight: "0.25rem" }}>{statusVar}</p>
        </div>
        <div>
          <p>{selectedProjectData && selectedProjectData.comp_prj_id}</p>
        </div>
        <div>
          <button className='mr-4'>
            <img src={notification} alt='#' />
          </button>
          <button className='mr-4'>
            <img src={help} alt='#' />
          </button>
          <Popover placement='bottomLeft' content={popoverContent} trigger='click'>
            <button>
              <img className='w-12' src={defaultUserImg} alt='#' />
            </button>
          </Popover>
        </div>
      </div>
    </div>
  );
});

export default Header;
