import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiBaseUrl } from 'app/environment'
import axios from 'axios'
import axiosInstance from 'axiosInstance'

const initialState = {
  data: [],
  error: null,
  loading: false,
}

export const getParties = createAsyncThunk(
  'getParties/partiesSlice',
  async (id: string, { rejectWithValue }) => {
    try {
      const url = `/api/projects/${id}/parties`
      const response = await axiosInstance.get(url)
      console.log('response >', response)
    
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const createContractor = createAsyncThunk(
  'createContractor/partiesSlice',
  async ({ data, projectId }: any, { rejectWithValue, dispatch }) => {
    console.log('projectId >', projectId)
    console.log('data >', data)
    try {
      console.log('works')
      const url = `/api/projects/${projectId}/parties`
      const response = await axiosInstance.post(url, data)
      console.log(response)
      dispatch(addContractor(response.data))
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)

const partiesSlice = createSlice({
  name: 'parties',
  initialState,
  reducers: {
    addContractor: (state: any, action) => {
      const newContractor = action.payload
      state.data.push(newContractor)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParties.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getParties.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(getParties.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as null
        state.data = []
      })
  },
})

export const { addContractor } = partiesSlice.actions

export default partiesSlice.reducer
