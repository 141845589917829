import React, { useEffect, useState } from 'react'
import axios from 'axios'

import sun from '../../images/weather/icon3.svg'
import rain from '../../images/weather/icon2.svg'
import clouds from '../../images/weather/icon1.svg'
import { useSelector } from 'react-redux'

type WeatherData = {
  name: string
  main: {
    temp: number
    feels_like: number
    humidity: number
  }
  weather: {
    main: string
  }[]
  wind: {
    speed: number
  }
}

const API_KEY = process.env.REACT_APP_WEATHER_API_KEY as string

const WeatherApp = ({ props }: any) => {
  const selectedProjectLocation = useSelector(
    (state: any) => state.selectedProjectData?.data[0]?.prj_location?.city,
  )

  const [data, setData] = useState<WeatherData>()
  const [city, setCity] = useState<string>('')

  const weatherImg = data?.weather[0].main

  useEffect(() => {
    const locationOfSelectedProject = localStorage.getItem('selectedProjectData')

    if (selectedProjectLocation) {
     
      setCity(selectedProjectLocation)
      return // Stop further execution
    }

    if (!selectedProjectLocation && locationOfSelectedProject) {
      console.log('works 2')
      const location = JSON.parse(locationOfSelectedProject)
      setCity(location[0].prj_location.city)
      return // Stop further execution
    }
  }, [selectedProjectLocation])

  useEffect(() => {
    if (city) {
      const url = `https://api.openweathermap.org/data/2.5/weather?q=${selectedProjectLocation}&units=metric&appid=${API_KEY}`
      axios
        .get<WeatherData>(url)
        .then((response) => {
          setData(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [city])

  return (
    <div className=' h-48 w-32 bg-[#3189DC] text-white  shadow-md rounded-lg mr-2 center flex-col'>
      <div className='flex flex-col justify-around'>
        <div className='center'>
          {/* {'Clouds' == data.weather && <img src={sun} alt='sunny' />} */}
          {/* {weatherImgs.map((icon) => data.weather[0].main)} */}
          {weatherImg === 'Clouds' ? (
            <img src={clouds} alt='sunny' />
          ) : weatherImg === 'Rain' ? (
            <img src={rain} alt='sunny' />
          ) : weatherImg === 'Sun' ? (
            <img src={sun} alt='sunny' />
          ) : (
            <img src={clouds} alt='sunny' />
          )}
        </div>

        <div className='center'>{data?.weather ? <p>{data.weather[0].main}</p> : null}</div>
        <div className='center'>{data?.main ? <h1>{data.main.temp.toFixed()}°C</h1> : null}</div>
      </div>
      {/* </div> */}
    </div>
  )
}

export default WeatherApp
