// base
import { useEffect, useMemo, useState, memo, useLayoutEffect } from 'react'
import clsx from 'clsx'
import toast from 'react-hot-toast'
import { SidebarLink, SidebarLinkImg, SubmenuLink } from './SidebarItems'
import { useSelector, useDispatch } from 'react-redux'
import Popup from '@components/analyticsComponents/Popup'

// images

import dashboard from 'images/navbar/dashboard.svg'
import userAvatar from 'images/navbar/avatar-of-user.svg'
import userBtnOpenMenu from 'images/navbar/btn-sidebar-for-user.svg'
import outline from 'images/navbar/outline.svg'
import reports from 'images/navbar/reports.svg'
import locked from 'images/navbar/locked.svg'
// import lock from 'images/navbar/lock.svg'
import findwork from 'images/navbar/findwork.svg'
// import error from 'images/navbar/error.svg'
// import environment from 'images/navbar/environment.svg'
import arrowDown from 'images/navbar/arrowDown.svg'
import calendar from 'images/navbar/calendarIcon.svg'
import help from 'images/navbar/help.svg'
import company from 'images/navbar/companyIcon.svg'
import project from 'images/navbar/projectIcon.svg'
import message from 'images/navbar/messageImg.svg'

const Sidebar = memo(function Sidebar() {
  const dispatch: any = useDispatch()

  const userInformation = useSelector((state: any) => state.userInfo.data)
  const selectedCompany = useSelector((state: any) => state.userInfo.selectedCompany?.company_name)

  const [openCompanyMenu, setOpenCompanyMenu] = useState(
    sessionStorage.getItem('openCompanyMenu') === 'true',
  )
  const [openProjectMenu, setOpenProjectMenu] = useState(
    sessionStorage.getItem('openProjectMenu') === 'true',
  )
  const [openOutlineMenu, setOpenOutlineMenu] = useState(
    sessionStorage.getItem('openOutlineMenu') === 'true',
  )
  const [openReportsMenu, setOpenReportsMenu] = useState(
    sessionStorage.getItem('openReportsMenu') === 'true',
  )
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('')

  // Functionality to save value modal windows in session storage
  useEffect(() => {
    sessionStorage.setItem('openCompanyMenu', String(openCompanyMenu))
    sessionStorage.setItem('openProjectMenu', String(openProjectMenu))
    sessionStorage.setItem('openOutlineMenu', String(openOutlineMenu))
    sessionStorage.setItem('openReportsMenu', String(openReportsMenu))
  }, [openProjectMenu, openOutlineMenu, openReportsMenu, openCompanyMenu])

  const selectedCompanyFromLocalStorage = localStorage.getItem('selectedCompany')
  useLayoutEffect(() => {
    const selectedCompanyFromLocalStorage = localStorage.getItem('selectedCompany')

    if (selectedCompanyFromLocalStorage) {
      const selectedCompany = JSON.parse(selectedCompanyFromLocalStorage)
      setSelectedCompanyName(selectedCompany.company_name)
    }
  }, [dispatch, selectedCompanyFromLocalStorage])

  // Check if company is  true
  const activeUserCompanies = useMemo(() => {
    if (userInformation && userInformation.user_companies) {
      return userInformation.user_companies.filter((company: any) => company.active)
    }
    return []
  }, [userInformation])

  // If company is active then return
  const companies = useMemo(() => {
    return activeUserCompanies.map((company: any) => company.company_name)
  }, [activeUserCompanies])

  const notify = () => toast('WIP')
  const notify2 = () => toast('Select a Project')

  const companyMenuItems = [
    { title: 'Employees', pathName: '/teams', className: '' },
    { title: 'Devices', pathName: '#', className: 'mb-0' },
  ]
  const projectMenuItems = [
    { title: 'Manage projects', pathName: '/manage-projects', className: ' mb-3.5' },
    { title: 'Resources', pathName: '/resources', className: ' mb-3.5' },
    { title: 'Locations', pathName: '/maps', className: ' mb-3.5' },
    { title: 'Contractors', pathName: '/contractors', className: 'mb-3.5' },
    { title: 'Tasks', pathName: '/tasks', className: ' mb-3.5' },
    { title: 'Analytics', pathName: '/analytics', className: 'mb-0' },
  ]
  const outlineMenuItems = [
    { title: 'Challenges', pathName: '#', className: 'mb-3.5' },
    { title: 'Analytics', pathName: '#', className: 'mb-3.5' },
    { title: 'Deficiencies', pathName: '/outline/defects', className: 'mb-0' },
  ]

  const reportsMenuItems = [
    { title: 'Customize', pathName: '#', className: 'mb-3.5' },
    { title: 'Daily Report', pathName: '#', className: ' mb-3.5' },
    { title: 'Team Presence', pathName: '#', className: 'mb-0' },
  ]

  const renderSubmenuLinks = (items: any) => {
    return items.map((item: any, index: any) => {
      return (
        <SubmenuLink
          key={index}
          title={item.title}
          pathName={item.pathName}
          className={item.className}
          onClick={(!item.pathName || item.pathName === '#') ? () => toast('WIP') : undefined}
        />
      )
    })
  }

  return (
    <div className='w-[330px] h-screen fixed top-0 left-0 bg-white '>
      <div className='relative w-full h-full scrollbar'>
        <div className=''>
          <ul className='flex flex-col pb-4 mb-4'>
            <div className='sticky top-0 left-0 bg-white z-[1000] h-[115px]  text-center'>
              <div style={{ background: "#1F242F", color: "#fff", padding: "15px", marginBottom: "5px" }} className='CompanyTextDiv'>
                <h2>Company</h2>
              </div>
              <div>
                <div className='pl-4 pr-2 cursor-pointer relative flex  w-[100%]'>
                  <div className='w-[60px] h-[60px] mr-1'>
                    <img className='w-full h-full' src={userAvatar} alt='user-avatar' />
                  </div>

                  <div className=' text-textDefaultColor'>
                    {companies && <Popup topicNameItems={companies} />}
                  </div>

                  <button className=''>
                    <img className='' src={userBtnOpenMenu} alt='userBtnOpenMenu' />
                  </button>
                </div>
                <div className='center'>
                  <p className=' text-[12px] text-[#9CA3AF]'>Magnus+ ver. 1.3.3</p>
                </div>
              </div>
            </div>

            <SidebarLink img={dashboard} title='Dashboard' imgAlt='dashboard' link='/dashboard' />
            <SidebarLinkImg
              onClick={() => setOpenCompanyMenu((prev) => !prev)}
              btnImg={arrowDown}
              btnAlt='arrowDown'
              img={company}
              imgAlt='Company'
              title='Company'
            />

            <div
              className={clsx(
                ' ease-out duration-500 transform -translate-y-full  transition-transform',
                'mb-4',
                openCompanyMenu ? 'flex pl-[62px] -translate-y-0' : 'hidden',
              )}
            >
              <span className='bg-[#E5E7EB] w-0.5 mr-4'></span>
              <ul className='flex flex-col'>{renderSubmenuLinks(companyMenuItems)}</ul>
            </div>

            <SidebarLinkImg
              onClick={() => setOpenProjectMenu((prev) => !prev)}
              btnImg={arrowDown}
              btnAlt='arrowDown'
              img={project}
              imgAlt='Projects'
              title='Projects'
            />

            <div
              className={clsx(
                ' ease-out duration-500 transform -translate-y-full  transition-transform',
                'mb-4',
                openProjectMenu ? 'flex pl-[62px] -translate-y-0' : 'hidden',
              )}
            >
              <span className='bg-[#E5E7EB] w-0.5 mr-4'></span>
              <ul className='flex flex-col'>{renderSubmenuLinks(projectMenuItems)}</ul>
            </div>

            <SidebarLinkImg
              onClick={() => setOpenOutlineMenu((prev) => !prev)}
              btnImg={arrowDown}
              btnAlt='arrowDown'
              img={outline}
              imgAlt='outline'
              title='Outline'
            />

            <div
              className={clsx(
                ' ease-out duration-500 transform -translate-y-full  transition-transform',
                'mb-4',
                openOutlineMenu ? 'flex pl-[62px] -translate-y-0' : 'hidden',
              )}
            >
              <span className='bg-[#E5E7EB] w-0.5 mr-4'></span>
              <ul className='flex flex-col'>{renderSubmenuLinks(outlineMenuItems)}</ul>
            </div>

            <SidebarLinkImg
              onClick={() => setOpenReportsMenu((prev) => !prev)}
              btnImg={arrowDown}
              btnAlt='arrowDown'
              img={reports}
              imgAlt='reports'
              title='Reports'
            />

            <div
              className={clsx(
                ' ease-out duration-500 transform -translate-y-full  transition-transform',
                'mb-4',
                openReportsMenu ? 'flex pl-[62px] -translate-y-0' : 'hidden',
              )}
            >
              <span className='bg-[#E5E7EB] w-0.5 mr-4'></span>
              <ul className='flex flex-col'>{renderSubmenuLinks(reportsMenuItems)}</ul>
            </div>

            <p className='text-[#D1D5DB] ml-8'>APPS</p>

            <SidebarLink
              onClick={notify}
              img={calendar}
              title='Calendar'
              imgAlt='calendar'
              link='/calendar'
            />

            <SidebarLink onClick={notify} img={message} title='Messages' imgAlt='email' link='#' />

            <p className='text-[#D1D5DB] ml-8'>SAFETY</p>
            {/* <SidebarLink img={person} title='Employees/Teams' imgAlt='teams' link='/teams' /> */}

            <SidebarLink img={help} title='FAQ' imgAlt='FAQ' link='/faq' />

            <SidebarLinkImg
              onClick={notify}
              btnImg={locked}
              btnAlt='arrowDown'
              img={findwork}
              imgAlt='find work'
              title='Find Work'
            />
          </ul>
        </div>
      </div>
    </div>
  )
})
Sidebar.displayName = 'Sidebar'

export default Sidebar
