// This is company's projects list information slice

import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import axiosInstance from 'axiosInstance'

interface initialStateType {
  data: any
  projectStatus: string | number
  loading: boolean
  error: null
  selectedProject: any
}
const initialState: initialStateType = {
  data: [],
  projectStatus: '',
  error: null,
  loading: false,
  selectedProject: null,
}
export const getProjects = createAsyncThunk('projectDataSlice/getProjects', async (id: any) => {
  
  try {
    const url = `/api/company/${id}/projects`
    const response = await axiosInstance.get(url)
    return response.data
  } catch (error) {
    return error
  }
})

export const setSelectedProjectInfo = createAction('projects/setSelectedProject')

const projectsInfoSlice = createSlice({
  name: 'projectDataSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjects.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null

        // save  selectedProject and if it belongs to company functionality
        const selectedProjectLocalStorageJSON = localStorage.getItem('selectedProject')
        const selectedCompanyFromLocalStorage = selectedProjectLocalStorageJSON
          ? JSON.parse(selectedProjectLocalStorageJSON)
          : null

        const selectedCompanyIdFromLocalStorage = localStorage.getItem('companyIdForProjects')
        // in order to save the selectedProject in refresh and if selectedProject belongs to selectedCompany
        if (
          selectedCompanyFromLocalStorage &&
          selectedCompanyIdFromLocalStorage === selectedCompanyFromLocalStorage.comp_id
        ) {
          state.selectedProject = selectedCompanyFromLocalStorage
        } else {
          state.selectedProject = null
        }
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.error = action.payload as null
        state.loading = false
        state.data = []
      })
      .addCase(setSelectedProjectInfo, (state, action: any) => {
        const selectedProjectLocalStorageJSON = localStorage.getItem('selectedProject')
        const selectedProjectFromLocalStorage = selectedProjectLocalStorageJSON
          ? JSON.parse(selectedProjectLocalStorageJSON)
          : null

        // if not dispatch then setSelectedProjectInfo = selectedProjectFromLocalStorage
        // if dispatch from header then we set what user choosen
        if (selectedProjectLocalStorageJSON) {
          state.selectedProject = selectedProjectFromLocalStorage
        } else {
          state.selectedProject = action.payload
        }
      })
  },
})

export default projectsInfoSlice.reducer
