import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

// const mockTopicNameItems = [
//   { id: '1', name: 'Topic 1' },
//   { id: '2', name: 'Topic 2' },
//   { id: '3', name: 'Topic 3' },
//   // ... more mock data ...
// ]
interface Option {
  id: string
  name: string
}

interface Props {
  topicNameItems: Option[]
  img1?: string
  img1Dynamic?: string
  img2Dynamic?: string
  img2?: string
  classNameBtn?: string
  selectedTextStyle?: string
  initialTextSelect?: string
  dropdownStyle?: string
  dropdownPosition?: string
  initialTextSelectDynamic?: string
  onSelectItem?: any
}

const DynamicPopup: React.FC<Props> = ({
  topicNameItems,
  classNameBtn,
  selectedTextStyle,
  initialTextSelect,
  initialTextSelectDynamic,
  dropdownStyle,
  dropdownPosition,
  onSelectItem,
  img2,
}) => {
  const [topicName, setTopicName] = useState<any>(initialTextSelect || initialTextSelectDynamic)
  const [isOpen, setIsOpen] = useState(false)
  const node = useRef<HTMLDivElement>(null)

  const handleTextSelection = (selectedObj: any) => {
    setTopicName(selectedObj.name)
    onSelectItem(selectedObj.id)
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (node.current?.contains(e.target as Node)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return (
    <div ref={node} className='flex relative'>
      <button
        type='button'
        onClick={handleClick}
        className={clsx(
          'border border-blue flex items-center',
          classNameBtn ? classNameBtn : 'w-full h-8 px-2 py-1 rounded-3xl mr-5',
        )}
      >
        {topicName !== null && topicName.name ? (
          <p className={clsx('text-white', selectedTextStyle ? selectedTextStyle : 'text-black')}>
            {topicName.name.length > 20 ? `${topicName.name.slice(0, 20)}...` : topicName.name}
          </p>
        ) : (
          <p
            className={clsx(
              'text-black font-medium',
              selectedTextStyle ? selectedTextStyle : 'ml-4 text-black',
            )}
          >
            {topicName}
          </p>
        )}

        {isOpen && (
          <ul
            className={clsx(
              'flex flex-col absolute  bg-white w-52  shadow-2xl drop-shadow-2xl z-10',
              dropdownPosition ? dropdownPosition : 'top-[50px] left-[-120px]',
            )}
          >
            {topicNameItems.map((option, i) => (
              <li
                onClick={() => handleTextSelection(option)}
                key={i}
                className='hover:bg-[#EFEEF1] py-2 border-b last:mb-0'
              >
                {option.name}
              </li>
            ))}
          </ul>
        )}
        {img2 && <img src={img2} alt={img2} />}
      </button>
    </div>
  )
}

export default DynamicPopup
