// Base

import { useEffect, useState } from 'react'
import { deleteTask, getSelectedTask, getTasks } from 'feature/tasksSlice'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { ApiBaseUrl } from 'app/environment'
import axios from 'axios'
import { formatDate } from 'hooks/formatDate'
import { Link } from 'react-router-dom'
import { getPhases } from 'feature/phasesSlice'
// Components

import toast from 'react-hot-toast'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
import { TruncatedText } from '@components/reusable/truncatedText/TruncatedText'
import { Select, Pagination, Popconfirm } from 'antd'
import SkillsDropdown from '@components/tasksComponents/SkillsDropdown'

// Images
import taskIcon from 'images/tasks/taskName.svg'
import ownerIcon from 'images/tasks/astrisk.svg'
import stackholdersIcon from 'images/tasks/stackholders.svg'
import statusIcon from 'images/tasks/status.svg'
import dependencyIcon from 'images/tasks/octagon.svg'
import deleteIcon from 'images/tasks/delete.svg'
import editIcon from 'images/tasks/edit.svg'
import messageIcon from 'images/tasks/messages.svg'
import phaseIcon from 'images/tasks/phaseIcon.svg'
import emailIcon from 'images/tasks/mail.svg'
import arrowDownWithoutFrames from 'images/navbar/arrowDownWithoutFrames.svg'
import { Plus } from 'lucide-react'

const TasksPage = () => {
  const dispatch: any = useDispatch()

  const [topicName, setTopicName] = useState<any>('Phases')
  const [postPerPage, setPostPerPage] = useState(5)
  const [page, setPage] = useState(1)
  const [tasks, setTasks] = useState<any[]>([])

  const [total, setTotal] = useState<any>('')
  const [sortedTasks, setSortedTasks] = useState<any[]>()
  const projectId = useSelector((state: any) => state.projects.selectedProject?.comp_prj_id)
  const selectedProject = useSelector((state: any) => state.projects.selectedProject?.comp_prj_id)
  const tasksInfo = useSelector((state: any) => state.tasksInfo.data)
  const phasesData = useSelector((state: any) => state.phases.data)

  useEffect(() => {
    if (selectedProject) {
      dispatch(getTasks(selectedProject))
      dispatch(getPhases(selectedProject))
    }
  }, [selectedProject])

  const onEditTask = (id: any) => {
    const taskData = { projectId: selectedProject, taskId: id }
    dispatch(getSelectedTask(taskData))

    sessionStorage.setItem('taskId', id)
  }

  const onDelete = async (id: any) => {
    try {
      await axios.delete(`${ApiBaseUrl}/api/projects/${projectId}/task/${id}`)
      notifyEditSeccess()
      dispatch(deleteTask(id))
    } catch (error) {
      notifyFailier()
      console.error('Error deleting task:', error)
    }
  }

  const notifyEditSeccess = () => toast('Task deleted')
  const notifyFailier = () => toast('Something went wrong')

  // Pagination functionality

  useEffect(() => {
    if (tasksInfo && sortedTasks) {
      setTasks(tasksInfo)
      setTotal(sortedTasks.length)
    }
  }, [tasksInfo, sortedTasks])
  // Calculate pagination indexes and list of workers for current page

  const indexOfLastPage = postPerPage * page
  const indexOfFirstPage = indexOfLastPage - postPerPage

  let listOfTasks
  // console.log('TasksPage ~ listOfTasks >', listOfTasks)

  if (sortedTasks) {
    listOfTasks = sortedTasks.slice(indexOfFirstPage, indexOfLastPage)
  } else {
    listOfTasks = []
  }

  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }

  // Sort by phases

  useEffect(() => {
    const selectedPhase = () => {
      if (topicName === 'Phases' || topicName === 'All phases') {
        // If the initial state is "Phases," display all tasks
        setSortedTasks(tasks)
      } else {
        const phase = phasesData.find((phase: any) => phase.name === topicName)
        if (phase) {
          const filteredTasks = tasks.filter((task: any) => {
            return phase.tasks.some((phaseTask: any) => phaseTask.oid === task._id)
          })
          setSortedTasks(filteredTasks)
        } else {
          setSortedTasks([])
        }
      }
    }

    selectedPhase()
  }, [topicName, phasesData, tasks])

  return (
    <div className='flex font-inter h-screen w-screen text-[#374151] '>
      <Sidebar />
      <div className='flex flex-col w-[calc(100%-330px)]  relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto flex flex-col '>
          <div className='w-full max-w-[1200px] mx-auto my-0 relative'>
            <div className='my-12'>
              <Breadcrumbs items={['Projects', 'Tasks']} />
            </div>
            {/* <div className='flex items-center mb-6'>
              <p className='mr-5 font-semibold text-lg'>Categories:</p>
              <Select
                defaultValue='All'
                style={{ width: 200, fontSize: '26px' }}
                options={[
                  { value: 'All', label: 'All' },
                  { value: 'Exterior Finishes', label: 'Exterior Finishes' },
                  { value: 'Demolition', label: 'Demolition' },
                  { value: 'Foundation', label: 'Foundation' },
                  { value: 'Interior Finishes', label: 'Interior Finishes' },
                  { value: 'Utilities', label: 'Utilities' },
                  { value: 'Structures', label: 'Structures' },
                ]}
              />
            </div> */}
            <div className='h-28 w-full flex items-center px-5 justify-between bg-white rounded-md mb-8'>
              <div className='flex items-center'>
                <SkillsDropdown
                  topicNameItems={phasesData}
                  dropdownPosition='top-[60px] left-[-20px]'
                  topicName={topicName}
                  img1={phaseIcon}
                  img2={arrowDownWithoutFrames}
                  setTopicName={setTopicName}
                  prefix=''
                />
              </div>

              <Link to='/create-task'>
                <button className='h-12 w-12 bg-blue center rounded-xl'>
                  <Plus color='white' />
                </button>
              </Link>
            </div>
            <div className='bg-white'>
              <div className='w-full mx-auto text-xl font-medium'>
                <div className='flex w-full h-[80px]'>
                  <div className='flex-1 border center'>
                    <img className='mr-1 mb-1' src={taskIcon} alt='task name' />
                    <p className='text-center'>Task name</p>
                  </div>
                  <div className='flex-1 border center'>
                    <img className='mr-1 mb-1' src={ownerIcon} alt='owner' />
                    <p className='text-center'>Owner</p>
                  </div>

                  <div className='flex-1 border center'>
                    <img className='mr-1 mb-1' src={stackholdersIcon} alt='stackholders' />
                    <p className='text-center'>Stakeholders</p>
                  </div>
                  <div className='flex-shrink border w-[180px] center'>
                    <img className='mr-1 mb-1' src={statusIcon} alt='status' />
                    <p className='text-center'>Status</p>
                  </div>
                  <div className='flex-1 border center'>
                    <img className='mr-1 mb-1' src={dependencyIcon} alt='dependency' />
                    <p className='text-center'>Dependencies</p>
                  </div>
                </div>
              </div>
              <div className='relative'>
                {listOfTasks.map((task: any, index: number) => {
                  return (
                    <div key={index} className='w-full mx-auto'>
                      <div className='flex w-full'>
                        <div className='flex-1 border'>
                          <div className='pl-3 pt-5 pr-1'>
                            <div className='mb-3 font-medium'>
                              <p className=''>{task.task_name}</p>
                            </div>
                            <div className='flex items-center'>
                              <button
                                className='mr-3'
                                type='button'
                                onClick={() => onEditTask(tasksInfo[index]._id)}
                              >
                                <Link to='/edit-task'>
                                  <img src={editIcon} alt='edit' />
                                </Link>
                              </button>
                              <Popconfirm
                                title='Delete the task'
                                description='Are you sure to delete this task?'
                                okText='Yes'
                                cancelText='No'
                                onConfirm={() => onDelete(tasksInfo[index]._id)}
                              >
                                <button type='button'>
                                  <img src={deleteIcon} alt='delete' />
                                </button>
                              </Popconfirm>
                            </div>
                          </div>
                        </div>
                        <div className='flex-1 border font-medium'>
                          <div className='pl-3 pt-5'>
                            <p className='mb-2'>{task.task_owner?.user_name}</p>
                            <div className='flex items-center'>
                              <button className='mr-3' type='button'>
                                <img src={messageIcon} alt='message' />
                              </button>
                              <button type='button'>
                                <img src={emailIcon} alt='mail' />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className='flex-1 border flex items-start font-medium'>
                          <div className='flex flex-col'>
                            {task.task_employees.map((emp: any, i: any) => {
                              return (
                                <div className='my-5 ml-2' key={i}>
                                  <TruncatedText
                                    className=''
                                    maxNum='22'
                                    text={emp.employee_company_name}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        </div>
                        <div className='flex-shrink  border w-[180px] pl-3 pt-5 pb-3'>
                          <p className=''>{formatDate(task.start_date)}</p>

                          <p className='mb-2'>{formatDate(task.finish_date)}</p>
                          <div
                            className={clsx(
                              'font-semibold inline-block px-2 py-1 rounded-lg ',
                              task.task_status == 'Overdue' && 'bg-[#E74C3C] text-white',
                              task.task_status == 'Active' && 'bg-[#3498DB] text-white',
                              task.task_status == 'Upcomming' && 'bg-[#FCD920] text-[#  #222222]',
                              task.task_status == 'Completed' && 'bg-[#2ECC71] text-white',
                              task.task_status == 'In progress' && 'bg-[#34219E] text-white',
                            )}
                          >
                            <p className='w-full center'>{task.task_status}</p>
                          </div>
                        </div>
                        <div className='flex-1 border'>
                          <div className='pl-3 pt-5'>
                            <p>No dependency</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            {listOfTasks && (
              <div className='text-right mt-16 mb-24'>
                <Pagination
                  className=''
                  onChange={(value: any) => setPage(value)}
                  pageSize={postPerPage}
                  total={total}
                  onShowSizeChange={onShowSizeChange}
                  current={page}
                />
              </div>
            )}
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default TasksPage
