import { Navigate } from 'react-router-dom'
import ButtonLogin from './reusable/buttonLogin/ButtonLogin'
import bgImg from 'images/signIn/bg.webp'
import { useEffect } from 'react'
import { userInfo } from 'os'
const Login = ({ auth, handleLogin, userManager }: any) => {
  return (
    <>
      {auth === null && <div>Loading...</div>}
      {auth === false && (
        <div
          className='w-full h-screen relative bg-no-repeat bg-center bg-cover'
          style={{ backgroundImage: `url(${bgImg})` }}
        >
          <div className='mx-auto max-w-[1300px] pl-5 pt-36 max-[1600px]:pl-24'>
            <div className='flex flex-col w-[620px] z-20 font-normal text-base max-[1600px]:text-xs'>
              {/* <div className='flex flex-col absolute top-[230px] left-[290px] w-[620px] z-20 font-normal text-base max-[1600px]:text-sm'> */}
              <div className='mb-4'>
            
              </div>

         
              <div>
                <ButtonLogin
                  onClick={() => handleLogin()}
                  className='w-64 h-14 max-[1600px]:w-44  max-[1600px]:h-10'
                  text='Sign in'
                  id='21313'
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {auth && <Navigate to='/dashboard' />}
    </>
  )
}

export default Login
