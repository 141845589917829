import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import teamsImg from '../../images/teams/teamsImg.png'
import CreateNewTeamMember from '@components/modalProjectInfo/CreateNewTeamMember'
import Employyes from '@components/teamsComponents/Employyes'
import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'

const TeamPage = () => {
  return (
    <div>
      <nav>
        <Sidebar />
      </nav>
      <div className='flex flex-col w-[calc(100%-330px)] relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto mt-12 mb-28'>
          <div className='w-full max-w-[1200px] mx-auto my-0 '>
            <div className='flex justify-between mb-7'>
              <div>
                <Breadcrumbs items={['Company', 'Employees']} />
              </div>
              <CreateNewTeamMember />
            </div>
            <div className='grid'>
              <Employyes />
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default TeamPage
