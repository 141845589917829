import clsx from 'clsx'
import { Link } from 'react-router-dom'

export const SidebarLinkImg = (props: any) => {
  return (
    <li
      key={props.key}
      onClick={props.onClick}
      className={clsx(
        props.className
          ? props.className
          : 'w-10/12 flex items-center justify-between cursor-pointer',
      )}
    >
      <div className='h-12 px-12 flex items-center '>
        <div className='mr-2'>
          <img className='w-7 h-auto' src={props.img} alt={props.imgAlt} />
        </div>
        <span className='text-base font-medium text-[#6B7280]'>{props.title}</span>
      </div>
      <button>
        <img className='w-8 h-auto' src={props.btnImg} alt={props.btnAlt} />
      </button>
    </li>
  )
}

export const SidebarLink = (props: any) => {
  return (
    <li
      style={{ marginTop: "30px" }}
      key={props.key}
      onClick={props.onClick}
      className={clsx(
        props.className ? props.className : 'w-10/12 flex items-center justify-between',
      )}
    >
      <Link className='h-12 px-12  flex items-center cursor-pointer' to={props.link}>
        <div className='mr-2'>
          <img className='w-7 h-auto' src={props.img} alt={props.imgAlt} />
        </div>
        <span className='text-base font-medium text-[#6B7280]'>{props.title}</span>
      </Link>
    </li>
  )
}

export const SubmenuLink = (props: any) => {
  return (
    <li
      onClick={props.onClick}
      // key={props.key}
      className={clsx(
        props.className ? props.className : 'mb-3.5',
        window.location.pathname === props.pathName && 'font-bold',
      )}
    >
      <Link to={props.pathName}>{props.title}</Link>
    </li>
  )
}
