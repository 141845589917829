import { configureStore, combineReducers } from '@reduxjs/toolkit'
import employyeSlice from 'feature/delete-files-in-future/employyeSlice'
// import authSlice from '../feature/auth'
// import projectSlice from 'feature/projectData'
import projectsInfoSlice from 'feature/projectsInfoSlice'
// import tipstertSlice from 'feature/tipsterData'
// import projectManagerSlice from '../feature/projectManager'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import environmentSlice from 'feature/delete-files-in-future/environmentSlice'
import defectsMeasurementSlice from 'feature/delete-files-in-future/defectsMeasurementSlice'
import timeMeasurementSlice from 'feature/delete-files-in-future/timeMeasurementSlice'
import userInfoSlice from 'feature/userInfoSlice'
import selectedProjectDataSlice from 'feature/selectedProjectDataSlice'
import usersGroupSlice from 'feature/usersGroupSlice'
import analyticsEnvSlice from 'feature/analyticsEnvSlice'
import employyesSlice from 'feature/employyesSlice'
import tasksSlice from 'feature/tasksSlice'
import partiesSlice from 'feature/partiesSlice'
import resourcesSlice from 'feature/resourcesSlice'
import userSettingsSlice from 'feature/userSettingsSlice'
import phasesSlice from 'feature/phasesSlice'
import skillsSlice from 'feature/skillsSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}
const reducers = combineReducers({
  // user: authSlice,
  projects: projectsInfoSlice,
  employees: employyeSlice,
  environment: environmentSlice,
  defectsMeasurement: defectsMeasurementSlice,
  timeMeasurement: timeMeasurementSlice,
  userInfo: userInfoSlice,
  selectedProjectData: selectedProjectDataSlice,
  usersGroup: usersGroupSlice,
  analyticsEnv: analyticsEnvSlice,
  employyes: employyesSlice,
  tasksInfo: tasksSlice,
  parties: partiesSlice,
  resources: resourcesSlice,
  settings: userSettingsSlice,
  phases: phasesSlice,
  skills: skillsSlice,
})
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
