import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { setSelectedCompany } from 'feature/userInfoSlice'
import arrawDownImg from 'images/popup/arrawDown.svg'
import { TruncatedText } from '@components/reusable/truncatedText/TruncatedText'

// const mockTopicNameItems = [
//   { id: '1', name: 'Topic 1' },
//   { id: '2', name: 'Topic 2' },
//   { id: '3', name: 'Topic 3' },
//   // ... more mock data ...
// ]
interface Option {
  id: string
  name: string
}

interface Props {
  topicNameItems: Option[]
  img1?: string
  img1Dynamic?: string
  img2Dynamic?: string
  img2?: string
  classNameBtn?: string
  selectedTextStyle?: string
  initialTextSelect?: string
  dropdownStyle?: string
  dropdownPosition?: string
  initialTextSelectDynamic?: string
  onSelectItem?: any
  setTopicName?: any
  topicName?: any
  prefix?: string
}

const SkillsDropdown: React.FC<Props> = ({
  topicNameItems,
  classNameBtn,
  selectedTextStyle,
  initialTextSelect,
  dropdownStyle,
  dropdownPosition,
  img1,
  img2,
  topicName,
  setTopicName,
  prefix,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const node = useRef<HTMLDivElement>(null)

  const handleTextSelection = (selectedObj: any) => {
    console.log('handleTextSelection ~ selectedObj >', selectedObj)
    if (prefix) {
      setTopicName(selectedObj[`${prefix}_name`])
    } else {
      setTopicName(selectedObj.name)
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (node.current?.contains(e.target as Node)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return (
    <div ref={node} className='flex relative'>
      <button
        type='button'
        onClick={handleClick}
        className='border border-blue pl-4 pr-2 py-1 rounded-2xl'
      >
        <div className='flex items-center'>
          {img1 && <img className='mr-2' src={img1} alt={img1} />}
          {topicName ? (
            <p className='text-black'>
              {topicName.length > 14 ? `${topicName.slice(0, 14)}..` : topicName}
            </p>
          ) : (
            <p className='text-black font-medium'>{topicName}</p>
          )}
          {img2 && <img className='mx-3' src={img2} alt={img2} />}
        </div>

        {isOpen && (
          <ul className='absolute top-[60px] left-[0px] bg-white w-[330px]  shadow-2xl drop-shadow-2xl z-10'>
            <li
              onClick={() => handleTextSelection({ name: 'All phases' })}
              className='hover:bg-[#EFEEF1] py-2 w-full border-b pl-8 last:mb-0 flex flex-col items-start'
            >
              <p className='inline-block relative px-2 py-1 text-black rounded-lg'>All phases</p>
            </li>
            {topicNameItems.map((option: any, i: number) => (
              <li
                onClick={() => handleTextSelection(option)}
                key={i}
                className='hover:bg-[#EFEEF1] py-2 w-full border-b pl-8 last:mb-0 flex flex-col items-start'
              >
                {prefix ? (
                  <p
                    style={{ background: option[`${prefix}_color`] }}
                    className='inline-block relative px-2 py-1 text-black rounded-lg'
                  >
                    {' '}
                    {option[`${prefix}_name`]}
                  </p>
                ) : (
                  <p
                    style={{ background: option.color }}
                    className='inline-block relative px-2 py-1 text-black rounded-lg'
                  >
                    {' '}
                    {option.name}
                  </p>
                )}
              </li>
            ))}
          </ul>
        )}
      </button>
    </div>
  )
}

export default SkillsDropdown
