import TabsPanel from '@components/analyticsComponents/TabsPanel'
import RoundedColumnPlot from '@components/reusable/charts/roundedColumnPlot/RoundedColumnPlot'
import Donat from '@components/reusable/charts/donat/Donat'
import React, { useEffect, useRef, useState } from 'react'
import environmentImg from '../../images/analitics/environment.svg'
import personImg from '../../images/analitics/person.svg'
import projectsImg from '../../images/analitics/projects.svg'
import safetyImg from '../../images/analitics/safety.svg'
import clsx from 'clsx'
import GaugePlot from '@components/reusable/charts/gaugePlot/GaugePlot'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import Header from '@components/reusable/header/Header'
import Footer from '@components/reusable/footer/Footer'
import WeatherAppForcast from '@components/weatherApp/WeatherAppForcast'
import toast, { Toaster } from 'react-hot-toast'
import Teams from '@components/analyticsComponents/Teams'
import { useJsApiLoader } from '@react-google-maps/api'
import { weekly, hourly, monthly } from 'data/charts/chart'
import { Area } from '@ant-design/plots'
import { AlertCircle } from 'lucide-react'
import GoogleMaps from '@components/mapsComponents/GoogleMaps'
import { useSelector, useDispatch } from 'react-redux'
import { getUsersGroup } from 'feature/usersGroupSlice'
import AccidentIncidentLines from '@components/reusable/charts/accidentIncidentLines/AccidentIncidentLines'
import SafetyPieChart from '@components/reusable/charts/safetyPieChart/SafetyPieChart'
import arrowDown from 'images/navbar/arrowDownWithoutFrames.svg'
import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
// import { defaultCenter, tabs } from '../../data/analyticsPage/analyticsPageData'
import Dropdown from '@components/analyticsComponents/Dropdown'


interface center {
  lat: number
  lng: number
}

// tabs
export const tabs = [
  {
    id: 0,
    name: 'Environment',
    img: environmentImg,
  },
  {
    id: 1,
    name: 'Teams',
    img: personImg,
  },
  {
    id: 2,
    name: 'Contractors',
    img: personImg,
  },
  {
    id: 3,
    name: 'Safety & Health',
    img: safetyImg,
  },
  {
    id: 4,
    name: 'Projects',
    img: projectsImg,
  },
]

export const defaultCenter: center = {
  lat: 59.419961,
  lng: 24.711284,
}

const libraries: ('places' | 'visualization')[] = ['places']
const API_KEY = process.env.REACT_APP_API_KEY as string

const AnalyticsPage = () => {
  const selectedProjectZones = useSelector(
    (state: any) => state.selectedProjectData?.data[0]?.prj_zones,
  )
  const [openZonesBlock, setOpenZonesBlock] = useState<boolean>(false)
  const [selectedzoneName, setSelectedzoneName] = useState<any>()

  const selectzone = (name: any) => {
    setSelectedzoneName(name)
    setOpenZonesBlock(false)
  }
  const handlezones = () => {
    setOpenZonesBlock((prev) => !prev)
  }

  const [activeTabId, setActiveTabId] = useState<number>(tabs[0].id)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
    libraries,
  })

  const [data, setData] = useState<any[]>([])
  const [selectedOptionTimePeriod, setSelectedOptionTimePeriod] = useState<string>('Weekly')

  useEffect(() => {
    let selectedData: any[] = []

    if (selectedOptionTimePeriod === 'Daily') {
      selectedData = hourly
    }
    if (selectedOptionTimePeriod === 'Weekly') {
      selectedData = weekly
    }
    if (selectedOptionTimePeriod === 'Monthly') {
      selectedData = monthly
    }

    setData(selectedData)
  }, [selectedOptionTimePeriod])
  const config = {
    data,
    xField: 'date',
    yField: 'scales',
    padding: [50, 0, 25, 30],
    yAxis: {
      grid: {
        line: {
          style: {
            stroke: 'black',
            lineWidth: 0,
            lineDash: [0, 0],
            strokeOpacity: 0,
            shadowColor: 'black',
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            cursor: 'pointer',
          },
        },
      },
    },
    point: {
      shape: 'circle',
      size: 5,
    },
    isStack: true,
  }

  const renderChart = (label: any) => {
    return (
      <div className='w-[280px] h-[180px] border rounded-md relative'>
        <span className='text-sm font-semibold text-[#494570] absolute left-2 top-2'>{label}</span>
        <Area {...config} smooth={true} />
      </div>
    )
  }
  return (
    <div>
      <nav>
        <Sidebar />
      </nav>
      <div className='flex flex-col w-[calc(100%-330px)] relative left-[330px] h-screen'>
        <Header />
        <div></div>
        <main className='flex-auto mt-12'>
          <div className='w-full max-w-[1200px] mx-auto my-0 '>
            <div className=''>
              <div className='mb-8'>
                <Breadcrumbs items={['Projects', 'Analytics']} />
              </div>
              <div className='flex items-center'>
                <h1 className='text-[20px] font-semibold mr-6'>Analytics</h1>

                <Dropdown
                  setSelectedOptionTimePeriod={setSelectedOptionTimePeriod}
                  selectedOptionTimePeriod={selectedOptionTimePeriod}
                />
              </div>
            </div>
            <div className='my-12'>
              {/* tabs */}
              <TabsPanel tabs={tabs} activeTabId={activeTabId} setActiveTabId={setActiveTabId} />
            </div>

            <div className={clsx('w-full mb-32', activeTabId === 0 ? 'grid' : 'hidden')}>
              {/* <RoundedColumnPlot /> */}
              <div className='bg-white h-[500px] flex  mb-32'>
                <div className='ml-3'>
                  <div className='mt-6 mb-4'>
                    <p className='mb-2'>Analytics</p>

                    <h5 className='text-[22px] mb-2 font-bold text-[#1E1B39]'>Enivornment</h5>
                    <hr />
                  </div>
                  {/* env chart */}
                  <div className='grid grid-cols-3 grid-rows-auto h-auto gap-x-2 gap-y-2 w-[840px]'>
                    {renderChart('Temperature')}
                    {renderChart('Air Quality')}
                    {renderChart('Humidity')}
                    {renderChart('Pressure')}
                    {renderChart('Noise')}
                    <div className='flex relative'>
                      {renderChart('CO2')}
                      <img className='absolute left-12 top-4' src={arrowDown} alt='Arrow' />
                    </div>
                  </div>
                </div>

                <div className='w-full h-full flex flex-col justify-between relative'>
                  {/* popup */}
                  <button onClick={handlezones} className='mt-6 flex items-center'>
                    {selectedzoneName ? (
                      <p className='text-[22px] font-bold'>{selectedzoneName}</p>
                    ) : (
                      <p className='text-[22px] font-bold'>Select zone</p>
                    )}
                    <div className='pointer-events-none  flex items-center px-2 text-gray-700'>
                      <svg
                        className='fill-current h-4 w-4'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 20 20'
                      >
                        <path d='M10 12L4 6h12l-6 6z' className='text-gray-600'></path>
                      </svg>
                    </div>
                  </button>
                  {openZonesBlock && selectedProjectZones && (
                    <ul className='absolute top-[65px] left-[-10px] rounded-sm z-50'>
                      {selectedProjectZones.map((zone: any, i: number) => (
                        <li
                          className='hover:bg-slate-50 cursor-pointer border bg-white font-medium px-4 flex justify-between w-[258px] py-3 center'
                          key={i}
                          onClick={() => selectzone(zone.prj_zone_name)}
                        >
                          {zone.prj_zone_name.length > 22 ? (
                            <>
                              <span>{zone.prj_zone_name.slice(0, 22)}...</span>
                            </>
                          ) : (
                            <span>{zone.prj_zone_name.slice(0, 22)}</span>
                          )}
                          <span
                            style={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: zone.prj_zone_color,
                            }}
                          ></span>
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className='w-full h-5/6'>
                    {/* google maps  */}
                    {isLoaded ? (
                      <GoogleMaps center={defaultCenter} />
                    ) : (
                      <h2 className='center text-2xl'>Loading...</h2>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={clsx('mb-32', activeTabId === 1 ? 'grid' : 'hidden')}>
              <Teams />
            </div>

            <div className={clsx('w-3/6  mb-32', activeTabId === 2 ? 'grid' : 'hidden')}>
              <div className='w-[1000px] h-[600px] z-30 bg-white rounded-md center text-3xl'>
                Contractors
              </div>
            </div>

            <div
              className={clsx(
                'grid-cols-3 mb-96 relative gap-y-10',
                activeTabId === 3 ? 'grid' : 'hidden',
              )}
            >
              <div className='h-[365px] w-[700px] bg-white col-span-2 rounded-lg'>
                <div className='px-6 mt-3 mb-3'>
                  <div className='flex justify-between '>
                    <h3 className='font-semibold text-lg'>Incidents / Accidents</h3>
                    <button>
                      <AlertCircle size={20} />
                    </button>
                  </div>
                  <p>Lorem, ipsum dolor.</p>
                </div>
                <div className='h-[90%] px-5 pb-2'>
                  <AccidentIncidentLines />
                </div>
              </div>
              <div className='w-[400px]'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae alias quibusdam
                blanditiis aperiam atque beatae ab quos? Dicta suscipit dolore dolorem cumque
                exercitationem aliquid. Repellendus ipsum sit molestiae quisquam tempore.
              </div>
              {/* weather app*/}
              <div className='w-[550px] h-[315px] relative center bg-white rounded-lg shadow-md col-span-2'>
                <div className='absolute left-7 top-4'>
                  <p>Analytics</p>
                  <h5 className='font-bold'>Safety & Health Risk</h5>
                </div>

                <GaugePlot />

                {/* <WeatherAppForcast /> */}
              </div>
              <div className='h-[455px] w-[350px] bg-white col-span-1 rounded-lg'>
                <div className='mt-2 mb-[-15px] px-6'>
                  <div className='flex justify-between '>
                    <h3 className='font-semibold text-lg'>Safety Rounds</h3>
                    <button>
                      <AlertCircle size={20} />
                    </button>
                  </div>
                  <p>Lorem, ipsum dolor.</p>
                </div>
                <div>
                  <SafetyPieChart />
                </div>
              </div>
            </div>
            <div className={clsx('w-3/6 mb-24', activeTabId === 4 ? 'grid' : 'hidden')}>
              {/* <Donat projectsDonatData={projectsDonatData} /> */}
              <div className='w-[1000px] h-[600px] z-30 bg-white rounded-md center text-3xl'>
                Projects
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default AnalyticsPage
