import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Tabs from './Tabs'
// import GoogleMaps from '@components/mapsComponents/GoogleMaps'
import { useJsApiLoader } from '@react-google-maps/api'
import defaultUserImg from 'images/profile/defaultUserImg.webp'
import phaseIcon from 'images/tasks/phaseIcon.svg'
import DynamicPopup from '@components/reusable/popup/DynamicPopup'
import { getPhases } from 'feature/phasesSlice'
import arrowDownWithoutFrames from 'images/navbar/arrowDownWithoutFrames.svg'
import plusIcon from 'images/tasks/plusIcon.svg'
import { getSelectedTask } from 'feature/tasksSlice'
import { X } from 'lucide-react'
import doneIcon from 'images/tasks/done.svg'
import conflictIcon from 'images/tasks/conflict.svg'
import GoogleMapsZoneArea from '@components/tasksComponents/GoogleMapsZoneArea'

import clsx from 'clsx'
import GeneralProjectInfoTabContent from './GeneralProjectInfoTabContent'
// import AssigneTabContent from './AssigneTabContent'

interface center {
  lat: number
  lng: number
}

const defaultCenter: center = {
  lat: 59.419961,
  lng: 24.711284,
}
// tabs
export const tabs = [
  {
    id: 0,
    name: 'General Project Information',
  },
  {
    id: 1,
    name: 'Phases',
  },
  {
    id: 2,
    name: 'Location',
  },
  {
    id: 3,
    name: 'Teams',
  },
  {
    id: 4,
    name: 'List of skills',
  },
]

const OnEditOrCreateProject = () => {
  const dispatch: any = useDispatch()
  const [activeTabId, setActiveTabId] = useState<number>(tabs[0].id)
  const [doneOrConflict, setDoneOrConflict] = useState<number>()

  const phasesData = useSelector((state: any) => state.phases.data)
  const selectedProject = useSelector((state: any) => state.projects.selectedProject?.comp_prj_id)
  const selectedTaskSkills = useSelector(
    (state: any) => state.tasksInfo.selectedTask[0]?.task_skills_requires,
  )
  const selectedTaskInfo = useSelector((state: any) => state.tasksInfo.selectedTask[0])
  console.log('OnEditOrCreate ~ selectedTaskInfo >', selectedTaskInfo)

  const selectedTaskId = sessionStorage.getItem('taskId')

  useEffect(() => {
    if (selectedProject) {
      dispatch(getSelectedTask({ taskId: selectedTaskId, projectId: selectedProject }))
      dispatch(getPhases(selectedProject))
    }
  }, [selectedProject, selectedTaskId])

  return (
    <div className='flex font-inter h-screen w-screen text-[#374151] '>
      <Sidebar />
      <div className='flex flex-col w-[calc(100%-330px)]  relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto'>
          <div className='w-full max-w-[1200px] mx-auto my-0'>
            <div className='my-12'>
              <Breadcrumbs items={['Tasks', 'Edit project']} />
            </div>

            <div className='mb-8'>
              <div className='flex justify-between'>
                <h2 className='text-2xl font-medium mb-6'>Project No.1</h2>
              </div>

              <div className='flex items-center'>
                <div className='w-12'>
                  <img src={defaultUserImg} alt='user-icon' />
                </div>
                <div className='ml-2'>
                  <p className='text-base'>Lazar Jevric</p>
                  <p className='text-base text-[#6C6C6C]'>Project Manager @Phare Technologies</p>
                </div>
              </div>
            </div>

            <div className='py-5 rounded-lg bg-white pl-5 mb-12'>
              <Tabs tabs={tabs} activeTabId={activeTabId} setActiveTabId={setActiveTabId} />
            </div>

            {/* General Task Info Tab */}

            <div className={clsx('w-full', activeTabId === 0 ? 'block' : 'hidden')}>
              <GeneralProjectInfoTabContent />
            </div>

            <div className={clsx('w-full', activeTabId === 1 ? 'block' : 'hidden')}>Phases</div>

            <div className={clsx('w-full', activeTabId === 2 ? 'block' : 'hidden')}>Location</div>
            <div className={clsx('w-full', activeTabId === 3 ? 'block' : 'hidden')}>Teams</div>
            <div className={clsx('w-full', activeTabId === 4 ? 'block' : 'hidden')}>
              List of skills
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default OnEditOrCreateProject
