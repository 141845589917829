import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import axiosInstance from 'axiosInstance'

const initialState = {
  data: [],
  error: null,
  loading: false,
  selectedTask: [],
}

export const getTasks = createAsyncThunk(
  'getTasks/tasksSlice',
  async (id: any, { rejectWithValue }) => {
    try {
      // const url = `${ApiBaseUrl}/api/projects/${id}/task/`
      const url = `/api/projects/${id}/task/`
      const response = await axiosInstance.get(url)
      return response.data // Return only the serializable response data
    } catch (error: any) {
      const errorData = {
        message: error.message,
        status: error.response?.status,
        // Include any other relevant data as needed
      }
      return rejectWithValue(errorData) // Return serializable error data
    }
  },
)

export const createTask = createAsyncThunk(
  'createTask/tasksSlice',
  async ({ data, selectedProject }: any, { dispatch }) => {
    try {
      const url = `/api/projects/${selectedProject}/task`
      const response = await axiosInstance.post(url, data)
      dispatch(addNewTask(response.data))
      return response.data
    } catch (error) {
      console.log(error)
    }
  },
)

export const getSelectedTask = createAsyncThunk(
  'getTask/tasksSlice',
  async ({ taskId, projectId }: any, { rejectWithValue }) => {
    try {
      const url = `/api/projects/${projectId}/task/${taskId}`
      const response = await axiosInstance.get(url)
      sessionStorage.setItem('taskForEdit', JSON.stringify(response.data))
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const updateTask = createAsyncThunk(
  'updateTask/tasksSlice',
  async ({ selectedProject, taskId, editedTask }: any, { rejectWithValue }) => {
    console.log("editedTask >", editedTask)
    try {
      const url = `/api/projects/${selectedProject}/task/${taskId}`
      const response = await axiosInstance.put(url, editedTask)

      return response.data
    } catch (error) {
      rejectWithValue(error)
    }
  },
)

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    deleteTask: (state, action) => {
      const deletedTaskId = action.payload
      state.data = state.data.filter((task: any) => task._id !== deletedTaskId)
    },
    addNewTask: (state: any, action) => {
      const newTask = action.payload
      state.data.push(newTask)
    },
    updatedTask: (state: any, action) => {
      const id = sessionStorage.getItem('usedTaskId')

      const updatedTask = action.payload
      const findIndex = state.data.find((task: any): any => task._id == id)

      state.data[findIndex] = updatedTask
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTasks.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
        state.error = null
      })

      .addCase(getTasks.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload as null
      })

      .addCase(getSelectedTask.fulfilled, (state, action) => {
        state.selectedTask = action.payload
        state.loading = false
        state.error = null
      })
  },
})

export const { deleteTask, addNewTask, updatedTask } = tasksSlice.actions // Export the new action

export default tasksSlice.reducer
