import { getUserInfo } from 'feature/userInfoSlice'
import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch } from 'react-redux'



const Footer = () => {
  // console.log('footer');
  // const dispatch: any = useDispatch()
  // const [userInfo, setUserInfo] = useState<any>(
  //   JSON.parse(localStorage.getItem('userInfo') || 'null'),
  // )

  // useEffect(() => {
  //   if (userInfo && userInfo.sub) {
  //     console.log('render userInfo')
  //     dispatch(getUserInfo(userInfo.sub))
  //     localStorage.setItem('userID', JSON.stringify(userInfo.sub))
  //   }
  // }, [])
  const notify = () => toast('WIP')
  return (
    <footer className=' px-20 py-3 border-t h-16 '>
      <div className=' flex justify-between '>
        <p>2024 © Phare Technologies, Linz, Austria & Tennesee USA</p>
        <div onClick={notify} className='flex cursor-pointer'>
          <p className='mr-4'>Pricing</p>
          <p className='mr-4'>Documentation</p>
          <p>Contact us</p>
        </div>
      </div>
      {/* <Toaster
        toastOptions={{
          icon: '🙃',
          className: 'bg-black',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }}
      /> */}
    </footer>
  )
}

export default React.memo(Footer);
