import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiBaseUrl } from 'app/environment'
import axios from 'axios'
import axiosInstance from 'axiosInstance'

interface initialStateType {
  data: []
  projectStatus: string | number
  loading: boolean
  error: null
  selectedCompany: any
}
const initialState: initialStateType = {
  data: [],
  projectStatus: '',
  error: null,
  loading: false,
  selectedCompany: null,
}

export const getUserInfo = createAsyncThunk(
  'project-status/data',
  async (id: any, { rejectWithValue }) => {
    try {
      // const url = `${ApiBaseUrl}/api/get/user/215619828881293569/info`
      const url = `/api/get/user/${id}/info`
      const responce = await axiosInstance.get(url)
      localStorage.setItem('userInfoFromDataBase', JSON.stringify(responce.data))
      return responce.data
    } catch (error: any) {
      return rejectWithValue(error)
    }
  },
)

export const setSelectedCompany = createAction('projects/setSelectedCompany')

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state, action) => {
        state.error = null
        state.loading = true
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loading = false
        state.error = null
        state.data = action.payload
        const companyFromLocalStorage = localStorage.getItem('selectedCompany')

        if (companyFromLocalStorage) {
          state.selectedCompany = JSON.parse(companyFromLocalStorage)
        } else {
          // there will be a list of companies the point it to display only active === true, but in this case it will display first company doenst matter false or true
          state.selectedCompany = action.payload.user_companies[0]
        }
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.error = action.payload as null
        state.loading = false
        state.data = []
      })
      .addCase(setSelectedCompany, (state, action: any) => {
        const companyFromLocalStorage = localStorage.getItem('selectedCompany')
        state.selectedCompany = action.payload
        localStorage.setItem('selectedCompany', JSON.stringify(action.payload))
      })
  },
})

export default userInfoSlice.reducer
