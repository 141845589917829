import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, MarkerF, OverlayView, Polygon } from '@react-google-maps/api'
import { defaultTheme } from './Theme'
import { zone4, zone2options, zone3options } from '../../data/mapsPage/PolygonData'
import { useSelector } from 'react-redux'
import icon from 'images/navbar/findwork.svg'

const CircleMarker = ({ lat, lng }: any) => {
  return (
    <div
      style={{
        width: '20px', // Customize the circle size
        height: '20px', // Customize the circle size
        borderRadius: '50%', // Make it a circle
        background: 'blue', // Customize the circle color
        border: '2px solid white', // Add a border for better visibility
        cursor: 'pointer', // Show cursor as pointer
      }}
    />
  )
}

const containerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
}

interface MapOptions {
  panControl: boolean
  styles: any
  streetViewControl: boolean
  rotateControl: boolean
  clickableIcon: boolean
  zoomControl: boolean
  scrollwheel: boolean
  navigationControl: boolean
  mapTypeControl: boolean
  scaleControl: boolean
  draggable: boolean
  disableDefaultUI: boolean
  disableDoubleClickZoom: boolean
  keyboardShortcuts: boolean
  fullscreenControl: boolean
}

const defaultOptions: MapOptions = {
  panControl: true,
  styles: defaultTheme,
  streetViewControl: false,
  rotateControl: false,
  clickableIcon: true,
  zoomControl: true,
  scrollwheel: true,
  navigationControl: false,
  mapTypeControl: false, // 3d map
  scaleControl: false,
  draggable: true,
  disableDefaultUI: true,
  disableDoubleClickZoom: true,
  keyboardShortcuts: false,
  fullscreenControl: true,
}
interface Coordinate {
  lat: number
  lng: number
}

const zone1options = {
  fillColor: '#3f88dc',
  fillOpacity: 0.6,
  strokeColor: '#141caa',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 2,
}

const zone4options = {
  fillColor: '#ffffff',
  fillOpacity: 0.4,
  strokeColor: '#0a0906',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
}

function GoogleMaps({center}: any) {
  
  
  const [polygonCoordinates, setPolygonCoordinates] = useState<Coordinate[] | null>(null)
  const [polygonZoneCoordinates, setPolygonZoneCoordinates] = useState<any>(null)

  const [cente, setCenter] = useState<Coordinate | undefined>()
  // console.log('GoogleMaps ~ cente >', cente)

  const selectedProjectData = useSelector((state: any) => state.selectedProjectData?.data)
  // console.log("GoogleMaps ~ selectedProjectData >", selectedProjectData)
  const selectedProjectDatazone = useSelector(
    (state: any) => state.selectedProjectData?.data[0]?.prj_zones,
  )

  // Coordinates to center the map on the map
  const selectedProjectDataCenter = useSelector(
    (state: any) => state.selectedProjectData?.data[0]?.prj_area[0]?.prj_area_defaultCenter,
  )

  // !----------------------------------------------------------------------------------------------
  const convertCoordinatesToNumberszone = useCallback((coordinatesArray: any) => {
    if (!coordinatesArray) {
      return []
    }
    return coordinatesArray.map((coord: any) => ({
      lat: parseFloat(coord.lng),
      lng: parseFloat(coord.lat),
    }))
  }, [])

  useEffect(() => {
    if (selectedProjectDatazone) {
      const convertedCoordinates = selectedProjectDatazone.map((item: any) =>
        convertCoordinatesToNumbers(item.prj_zone_coord),
      )
      setPolygonZoneCoordinates(convertedCoordinates)
    }
  }, [convertCoordinatesToNumberszone, selectedProjectDatazone])
  // !----------------------------------------------------------------------------------------------

  // Convert to Number function of area polygon coordinates of project
  const convertCoordinatesToNumbers = useCallback((coordinatesArray: any) => {
    if (!coordinatesArray) {
      return []
    }
    return coordinatesArray.map((coord: any) => ({
      lat: parseFloat(coord.lng),
      lng: parseFloat(coord.lat),
    }))
  }, [])

  // Conver to Number function for center atribut of Google Map
  const convertStringToNumber = useCallback((obj: any) => {
    const convertedObj = { ...obj }
    convertedObj.lat = parseFloat(obj.lng)
    convertedObj.lng = parseFloat(obj.lat)
    return convertedObj
  }, [])
  useEffect(() => {
    if (selectedProjectDataCenter) {
      const convertedCenter = convertStringToNumber(selectedProjectDataCenter)
      setCenter(convertedCenter)
    }
  }, [convertStringToNumber, selectedProjectDataCenter])

  useEffect(() => {
    if (selectedProjectData) {
      const convertedCoordinates = selectedProjectData.map((item: any) =>
        item.prj_area.map((area: any) => convertCoordinatesToNumbers(area.prj_area_coord)),
      )
      setPolygonCoordinates(convertedCoordinates[0])
    }
  }, [convertCoordinatesToNumbers, selectedProjectData])
  const convertToPolygonOptions = useCallback((color: string) => {
    return {
      fillColor: color,
      fillOpacity: 0.6,
      strokeColor: '#141caa',
      strokeOpacity: 2,
      strokeWeight: 1,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 2,
    }
  }, [])

  useEffect(() => {
    if (selectedProjectDatazone) {
      const convertedCoordinates = selectedProjectDatazone.map((item: any) =>
        convertCoordinatesToNumberszone(item.prj_zone_coord),
      )
      setPolygonZoneCoordinates(
        convertedCoordinates.map((coordinates: any, index: number) => ({
          coordinates,
          options: convertToPolygonOptions(selectedProjectDatazone[index].prj_zone_color),
        })),
      )
    }
  }, [convertCoordinatesToNumberszone, selectedProjectDatazone, convertToPolygonOptions])

  // ----------------------------------------------------------------
  const mapRef = useRef<any>(undefined)

  const onLoad = useCallback((map: any) => {
    mapRef.current = map
  }, [])

  // const onUnmount = useCallback(function callback(map: any) {
  //   mapRef.current = undefined
  // }, [])

  // delete code below tipster data for test
  const [listening, setListening] = useState(false)
  const [processId, setProcessId] = useState('')
  // console.log('Drafts ~ processId >', processId)
  const [message, setMessage] = useState('')

  const [tipsterData, setTipsterData] = useState<any>(null)
  // if (tipsterData) {
  //   console.log('GoogleMaps ~ tipsterData >', tipsterData.gps_latitude)
  //   console.log('GoogleMaps ~ tipsterData >', tipsterData.gps_longitude)
  // }

  const tipsterDataUrl = `http://localhost:4000/api/project/636b981a9410a61ab2f848c5/tipsterdata`

  useEffect(() => {
    const eventSource = new EventSource(tipsterDataUrl)

    eventSource.onmessage = (event) => {
      const parsedData = JSON.parse(event.data)
      // console.log('useEffect ~ parsedData >', parsedData)
      switch (parsedData.message) {
        case 'init':
          // console.log('Initial Event received')
          setProcessId(parsedData.processId)
          break
        case 'heartbeat':
          // console.log('Heartbeat received')
          setMessage(parsedData.message)
          break
        case 'tipster':
          setMessage(parsedData.message)
          setTipsterData(parsedData.tipster_data)
          break
      }
    }

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error)
      eventSource.close()
    }

    return () => {
      console.log('Connection closed.')
      eventSource.close()
    }
  }, [])

  return (
    <GoogleMap
      id='overlay-view-example'
      mapContainerStyle={containerStyle}
      center={cente}
      zoom={16}
      onLoad={onLoad}
      options={defaultOptions}
    >
      {/* {polygonZoneCoordinates && polygonZoneCoordinates.length > 0 ? (
        polygonZoneCoordinates.map((coordinates: any, index: any) => (
          <Polygon key={index} paths={coordinates} options={zone1options} />
        ))
      ) : (
        <p>Null</p>
      )} */}
      {polygonZoneCoordinates && polygonZoneCoordinates.length > 0 ? (
        polygonZoneCoordinates.map(({ coordinates, options }: any, index: number) => (
          <Polygon key={index} paths={coordinates} options={options} />
        ))
      ) : (
        <p>Null</p>
      )}
      {/* <MarkerF
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: 'red',
          fillOpacity: 0.8,
          strokeColor: 'white',
          strokeWeight: 2,
        }}
        position={{ lat: 48.3104665, lng: 14.3038328 }}
      /> */}
      {tipsterData && (
        <MarkerF
          icon={{
            path: google.maps.SymbolPath.CIRCLE,
            scale: 6,
            fillColor: 'black',
            fillOpacity: 0.8,
            strokeColor: 'white',
            strokeWeight: 2,
          }}
          position={{
            lat: parseFloat(tipsterData.gps_latitude),
            lng: parseFloat(tipsterData.gps_longitude),
          }}
        />
      )}

      {/* <MarkerF
        position={{ lat: 48.3099242, lng: 14.3027921 }}
        icon={{
          url: icon,
        }}
      /> */}
      {/* <MarkerF
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: 'purple',
          fillOpacity: 0.8,
          strokeColor: 'white',
          strokeWeight: 2,
        }}
        position={{ lat: 48.3099242, lng: 14.3027921 }}
      /> */}
      <MarkerF
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: 'red',
          fillOpacity: 0.8,
          strokeColor: 'white',
          strokeWeight: 2,
        }}
        position={{ lat: 48.3107092, lng: 14.3049164 }}
      />

      <MarkerF
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: 'blue',
          fillOpacity: 0.8,
          strokeColor: 'white',
          strokeWeight: 2,
        }}
        position={{ lat: 48.3101847, lng: 14.3044121 }}
      />
      <MarkerF
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: 'yellow',
          fillOpacity: 0.8,
          strokeColor: 'white',
          strokeWeight: 2,
        }}
        position={{ lat: 60.1968733, lng: 24.92434 }}
      />
      <MarkerF
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: 'green',
          fillOpacity: 0.8,
          strokeColor: 'white',
          strokeWeight: 2,
        }}
        position={{ lat: 60.1968731, lng: 24.92452 }}
      />
      <MarkerF
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: 'red',
          fillOpacity: 0.8,
          strokeColor: 'white',
          strokeWeight: 2,
        }}
        position={{ lat: 60.1968701, lng: 24.92428 }}
      />

      <MarkerF
        icon={{
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: 'black',
          fillOpacity: 0.8,
          strokeColor: 'white',
          strokeWeight: 2,
        }}
        position={{ lat: 60.1968301, lng: 24.92412 }}
      />
      {polygonCoordinates && <Polygon paths={polygonCoordinates} options={zone4options} />}
    </GoogleMap>
  )
}

export default GoogleMaps
