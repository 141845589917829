import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'


const initialState = {
  data: [],
  loading: false,
  error: null,
}

export const getUsersGroup = createAsyncThunk(
  'getUsersGroup/usersGroup',
  async (id: string, { rejectWithValue }) => {
    try {
      const ida = '636b981a9410a61ab2f848c5'

      const url = `/api/project/${id}/usergroups`
      const response = await axiosInstance.get(url)
      return response.data
    } catch (error: any) {
      const serializedError = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        // You can include additional properties from the error object if needed
      }
      throw rejectWithValue(serializedError) // Use "throw" to handle errors
    }
  },
) as any // Use "as any" to override the inferred return type

const usersGroupSlice = createSlice({
  name: 'usersGroup',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersGroup.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getUsersGroup.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(getUsersGroup.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload as null // Set the serialized error object as the state error
      })
  },
})

export default usersGroupSlice.reducer
