import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiBaseUrl } from 'app/environment'
import axios from 'axios'
import axiosInstance from 'axiosInstance'

// interface RootState {
//   userSettings: {
//     data: {
//       temperature_measurement: string; // adjust the type accordingly
//       pressure_measurement: string; // adjust the type accordingly
//     };
//     error: null | any; // adjust the type accordingly
//     loading: boolean;
//   };
//   // Add other slices if you have them
// }

interface InitialStateType {
  data: []
  error: null
  loading: boolean
}

const initialState: InitialStateType = {
  data: [],
  error: null,
  loading: false,
}
export const getTemperatureUnit = createAsyncThunk(
  'getTemperatureUnit/userSettingsSlice',
  async (_, { rejectWithValue }) => {
    try {
      const id = '64671fe1fc4b83a28f31570c'
      const url = `/api/user/${id}/settings`
      const response = await axiosInstance.get(url)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const changeTemperatureUnit = createAsyncThunk(
  'changeTemperatureUnit/userSettings',
  async ({ userID, value }: any, { rejectWithValue, dispatch }) => {
    console.log('value >', value)
    console.log('userID >', userID._id)

    try {
      const url = `/api/user/64671fe1fc4b83a28f31570c/settings`
      const data = {
        temperature: value,
      }
      console.log('data >', data)

      const response = await axiosInstance.put(url, data)
      dispatch(updateTemperatureUnit(data))
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    updateTemperatureUnit: (state: any, action) => {
      const newTemperatureValue = { temperature_measurement: action.payload.temperature }
      state.data = newTemperatureValue
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemperatureUnit.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(getTemperatureUnit.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(getTemperatureUnit.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as null
      })
      // .addCase(changeTemperatureUnit.fulfilled, (state, action) => {
      //   state.data = action.payload
      //   state.loading = false
      //   state.error = null
      // })
  },
})

export const { updateTemperatureUnit } = userSettingsSlice.actions

export default userSettingsSlice.reducer

// export const changeTemperatureUnit = createAsyncThunk(
//   'changeTemperatureUnit/userSettings',
//   async (unit: any, { rejectWithValue, dispatch, getState }) => {
//     const state = getState() as any;
//     const settings = state.settings.data;
//     console.log("settings >", settings)
//     try {
//       const changeValue = {
//         ...settings,
//         temperature: unit,
//         // Preserve the existing pressure_measurement value
//         pressure_measurement: settings.pressure_measurement,
//       };
//       console.log('changeValue >', changeValue);
//       const id = '64671fe1fc4b83a28f31570c';

//       const url = `/api/user/${id}/settings`;
//       const response = await axiosInstance.put(url, changeValue);
//       dispatch(updateTemperatureUnit(changeValue));
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   },
// );
