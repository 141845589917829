// components

import Footer from '@components/reusable/footer/Footer';
import Header from '@components/reusable/header/Header';
import Sidebar from '@components/reusable/sidebar/Sidebar';
// import toast, { Toaster } from 'react-hot-toast';

// styles
import './faq.css';

const faq = () => {

  // const notify = () => toast('WIP');
  const faqs = [
    { id: 1, question: "Will it help me identify problem areas beforehand?", answer: "Pre-determined geo-zones, project management tools, and known hazards help calculate conditional risks." },
    { id: 2, question: "How can I leverage the digitization of Health & Safety in construction?", answer: "By integrating an end-to-end solution." },
    { id: 3, question: "Does the solution align with OSH goals?", answer: "The solution was designed with OSH, HSSE, ISO45001, and NEBOSH Standards in mind." },
    { id: 4, question: "Are there change management challenges for business integration?", answer: "Challenges are always present during system integration. Using the 7 Tenants of Lean Construction will mitigate some of them." },
    { id: 5, question: "Can I manage the project schedule?", answer: "Yes, the platform facilitates project schedule management by introducing task dependencies, timelines, and grouping for clarity." },
    { id: 6, question: "Will the solution help me stay on track?", answer: "Yes, the solution includes a notification system and transparent reports to help users stay on track. Customizable reports are available for different project parties’ requirements." },
    { id: 7, question: "Can I manage all my resources?", answer: "Resource management is considered and the platform allows for the efficient tracking of resource usage. There's a mechanism in place for quick updates and balance between resource inclusion and simplicity." },
    { id: 8, question: "Will I have visibility to all the team members?", answer: "Yes, the platform ensures visibility to all teams through the dashboard analysis. Various metrics including team attendance and safety risk contribute to effective project monitoring." },
    { id: 9, question: "At what level of project detail can I see and manage?", answer: "The platform provides a detailed dashboard for deep analysis ensuring clarity and effective problem identification." },
    { id: 10, question: "Will the real-time information help me make good decisions?", answer: "Real-time data monitoring includes environment factors and team-related information. The information is analyzed in real-time providing notifications for potential issues and good decision-making." },
    { id: 11, question: "Of what value is the historical information?", answer: "Historical information is valuable for efficiency improvement, analysis of what worked well, and areas for improvement. It also serves compliance purposes aiding in rating companies based on their track record." },
    { id: 12, question: "Can I assist in safety and health compliance?", answer: "It provides analytics for incidents, safety rounds, and generates reports as proof of adherence to regulations." },
    { id: 13, question: "Does the workplace environment affect the project schedule?", answer: "Yes, the workplace environment can impact project schedules, especially in construction projects. Magnus+ provides insights into nature-related delays, aids in proactive planning, and minimizes the negative impact of environment factors on project schedules." },
    { id: 14, question: "Will there be financial implications without hazardous environment information?", answer: "Yes, without information on hazardous environments there can be financial implications due to accidents. Magnus+ helps prevent accidents which in turn avoids negative financial impacts on projects." },
    { id: 15, question: "Will the labor be impacted by the change in procedure?", answer: "The impact on labor is expected to be minimal with the main change being the requirement to wear a safety device. However, it is emphasized that this change is for the betterment of their safety and health." },
    { id: 16, question: "What amount of additional training will the laborer require?", answer: "The laborers will require minimal additional training, mainly focusing on accepting and understanding the purpose of the safety device. Training will emphasize the advocacy for safety and some the device usage details." },
    { id: 17, question: "How will the solution laborer increase productivity?", answer: "Productivity increases safer work conditions thus minimizing absentees due to workplace accidents. It ensures that laborers feel better equipped and protected in the workplace, enabling them to work more efficiently without compromising their well-being." },
    { id: 18, question: "Will the adding a new PPE device burden the labor’s functions?", answer: "No, because the device is non-intrusive and it does not restrict any normal job functional movements." }
  ];
  

  return (
    <div className=' font-inter h-screen w-screen text-[#374151] '>
      <nav>
        <Sidebar />
      </nav>
      <div className='flex flex-col w-[calc(100%-330px)] relative left-[330px] h-screen'>
        <Header />
        <main style={{ padding: "0 55px" }} className='flex-auto my-12'>
          {/* <div onClick={notify} className='mb-12 center cursor-pointer'>
            <img className='h-[95px] ' src={dashboard} alt='dashboard' />
          </div> */}
          <section className="faq-section">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-3">

                  <div className="faq-title text-center pb-3">
                    <h2>FAQ</h2>
                  </div>
                </div>
                <div className="col-md-6 offset-md-3">
                <div className="col-md-6 offset-md-3">
                <div className="faq" id="accordion">
                  {faqs.map(faq => (
                    <div className="card" key={faq.id}>
                      <div className="card-header" id={`faqHeading-${faq.id}`}>
                        <div className="mb-0">
                          <h5 className="faq-title" data-toggle="collapse" data-target={`#faqCollapse-${faq.id}`} data-aria-expanded="false" data-aria-controls={`faqCollapse-${faq.id}`}>
                            <span className="badge">{faq.id}</span> {faq.question}
                          </h5>
                        </div>
                      </div>
                      <div id={`faqCollapse-${faq.id}`} aria-labelledby={`faqHeading-${faq.id}`} data-parent="#accordion">
                        <div className="card-body">
                          <p>{faq.answer}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              </div>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default faq