import axios from 'axios'
import {ApiBaseUrl} from '../../app/environment'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchDefectsData = createAsyncThunk(
  'fetchDefectsData',
  async function (projectId: any, { rejectWithValue }) {
    try {
      const projectFromLocalStorage = localStorage.getItem('selectedProject')
      let id
      if (projectFromLocalStorage) {
        const project = JSON.parse(projectFromLocalStorage)
        if (project && project._id) {
          id = project._id
        }
      }
      const url = `${ApiBaseUrl}/api/get/project/analytics/safety-and-health/${
        projectId || id
      }`
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)


const defectsMeasurementSlice = createSlice({
  name: 'defectsMeasurementSlice',
  initialState: {
    data: [],
    error: null,
    status: 'idle',
  },
  reducers: {},
  extraReducers: {
    //  fetchDefectsData

    [fetchDefectsData.pending.type](state) {
      state.status = 'pending'
      state.error = null
    },
    [fetchDefectsData.fulfilled.type](state, action) {
      state.status = 'fulfilled'
      state.data = action.payload
      state.error = null
    },
    [fetchDefectsData.rejected.type](state, action) {
      state.status = 'rejected'
      state.error = action.payload
      state.data = []
    },


  },
})

export default defectsMeasurementSlice.reducer
