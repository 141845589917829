import tabImg from 'images/temporaryDrafts/tab.svg'
import tab1Img from 'images/temporaryDrafts/tab1.svg'


export const tabs = [
  {
    id: 0,
    name: 'All defects',
    img: tabImg,
  },
  {
    id: 1,
    name: 'Material defects',
    img: tab1Img,
  },
  {
    id: 2,
    name: 'Equipment defects',
    img: tab1Img,
  },
  {
    id: 3,
    name: 'Workship defects',
    img: tab1Img,
  },
]