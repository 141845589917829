const Loader = () => {
  return (
    <div className='w-full h-screen center'>
      <div className='newtons-cradle'>
        <div className='newtons-cradle__dot'></div>
        <div className='newtons-cradle__dot'></div>
        <div className='newtons-cradle__dot'></div>
        <div className='newtons-cradle__dot'></div>
      </div>
    </div>
  )
}

export default Loader
