import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
import { Tabs } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import { useEffect, useMemo, useState } from 'react'
import { getResources } from 'feature/resourcesSlice'
import { useDispatch, useSelector } from 'react-redux'
import TabsPanel from '@components/analyticsComponents/TabsPanel'
import clsx from 'clsx'
import personImg from 'images/navbar/person.svg'
import MaterialsTabContent from '@components/resourcesComponents/MaterialsTabContent'
import ToolsTabContent from '@components/resourcesComponents/ToolsTabContent'
import HeavyEquipmentTabContent from '@components/resourcesComponents/HeavyEquipmentTabContent'

// tabs
const tabs = [
  {
    id: 0,
    name: 'Materials',
  },
  {
    id: 1,
    name: 'Hand Tools',
  },
  {
    id: 2,
    name: 'Heavy equipment',
  },
]

const ResourcesPage = () => {
  const dispatch: any = useDispatch()
  const selectedProject = useSelector((state: any) => state.projects.selectedProject?.comp_prj_id)
  const [activeTabId, setActiveTabId] = useState<number>(tabs[0].id)
  const resources = useSelector((state: any) => state.resources.data)
  
  const materials = useMemo(() => {
    return resources.filter((obj: any) => obj.prj_res_type === 'materials')
  }, [resources])

  const tools = useMemo(() => {
    return resources.filter((obj: any) => obj.prj_res_type === 'tools')
  }, [resources])

  const heavyEquipment = useMemo(() => {
    return resources.filter((obj: any) => obj.prj_res_type === 'heavy equipment')
  }, [resources])

  useEffect(() => {
    dispatch(getResources())
  }, [selectedProject])
  return (
    <div className='flex font-inter h-screen w-screen text-[#374151] '>
      <Sidebar />
      <div className='flex flex-col w-[calc(100%-330px)]  relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto flex flex-col '>
          <div className='w-full max-w-[1200px] mx-auto my-0'>
            <div className='my-12'>
              <Breadcrumbs items={['Projects', 'Resources']} />
            </div>

            <div>
              <div className='mb-12'>
                <TabsPanel tabs={tabs} activeTabId={activeTabId} setActiveTabId={setActiveTabId}/>
              </div>
              <div className={clsx('w-full', activeTabId === 0 ? 'block' : 'hidden')}>
                <MaterialsTabContent data={materials} />
              </div>

              <div className={clsx('w-full', activeTabId === 1 ? 'block' : 'hidden')}>
                <ToolsTabContent data={tools}/>
              </div>
              <div className={clsx('w-full', activeTabId === 2 ? 'block' : 'hidden')}>
                <HeavyEquipmentTabContent data={heavyEquipment}/>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default ResourcesPage
