import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Pagination } from 'antd'
// import 'antd/lib/pagination/style/index.css'

import workerPicture from '../../images/analitics/workerPicture.png'
import { getUsersGroup } from 'feature/usersGroupSlice'
import clsx from 'clsx'
import { TruncatedText } from '@components/reusable/truncatedText/TruncatedText'
import { getEmployyes } from 'feature/employyesSlice'

const Employyes = () => {
  const dispatch: any = useDispatch()
  const selectedCompany = useSelector((state: any) => state.userInfo.selectedCompany?.company_id)
  const employeesData = useSelector((state: any) => state.employyes.data)

  const [value, setValue] = useState(0)
  const [workers, setWorkers] = useState<any[]>(() => {
    // Retrieve data from session storage on initial render
    const storedData = sessionStorage.getItem('employeesData')
    return storedData ? JSON.parse(storedData) : []
  })
  const [total, setTotal] = useState<number>(() => {
    // Retrieve total from session storage on initial render
    const storedTotal = sessionStorage.getItem('employeesTotal')
    return storedTotal ? JSON.parse(storedTotal) : 0
  })
  const [page, setPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(5)

  useEffect(() => {
    dispatch(getEmployyes(selectedCompany))
  }, [selectedCompany, dispatch])

  // Store employyes in sessionStorage
  useEffect(() => {
    sessionStorage.setItem('employeesTotal', JSON.stringify(total))
  }, [total])
  // Save employees data and total to session storage whenever they change
  useEffect(() => {
    sessionStorage.setItem('employeesData', JSON.stringify(workers))
  }, [workers])

  // Pagination functionality
  useEffect(() => {
    if (employeesData && employeesData.length) {
      setWorkers(employeesData)
      setTotal(employeesData.length)
    }
  }, [employeesData])

  const indexOfLastPage = postPerPage * page
  const indexOfFirstPage = indexOfLastPage - postPerPage

  const onShowSizeChange = (current: any, pageSize: any) => {
    setPostPerPage(pageSize)
  }
  const listOfWorkers = workers.slice(indexOfFirstPage, indexOfLastPage)

  // Different functions
  const ref = useRef<any>(null)

  // Dropdown functionality

  const [isOpen, setIsOpen] = useState(false)

  const node = useRef<HTMLDivElement>(null)
  const handleChangeTeam = (i: any) => {
    setValue(i)
    setIsOpen((prev) => !prev)
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (node.current?.contains(e.target as Node)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])
  return (
    <div ref={ref as any} className='w-[1100px] h-[600px] z-10 bg-white rounded-md relative'>
      <div className='flex mb-6 mt-9 ml-7'>
        <div ref={node} className='relative'></div>
      </div>
      <div className='flex ml-8 mb-4 font-medium text-[#343A40] text-sm'>
        <input type='checkbox' className='h-5 w-5 mr-3 cursor-pointer' />
        <p className='ml-6'>Image</p>
        <p className='ml-6'>Name</p>
        <p className='ml-[131px]'>Occupation</p>
        <p className='ml-[95px]'>Status</p>

        <p className='ml-[70px]'>Zone</p>
      </div>
      <hr className='w-[95%] mx-auto my-0 mb-7' />

      <ul className='flex flex-col mt-8 ml-8'>
        {listOfWorkers.map((employee: any, index: any) => {
          return (
            <li className='flex items-center mb-5' key={index}>
              <input type='checkbox' className='h-5 w-5 mr-3 cursor-pointer' />
              <img className='ml-6' src={workerPicture} alt='workerPicture' />
              {/* <p>{(page - 1) * postPerPage + index + 1}</p> */}
              <TruncatedText className='ml-6 w-[70px]' maxNum='9' text={employee.employee_name} />
              <TruncatedText className='w-[70px]' maxNum='9' text={employee.employee_surname} />
              <TruncatedText
                className='ml-8 w-[150px]'
                maxNum='16'
                text={employee.employee_occupation}
              />
              <p className='ml-8 '>Active</p>
              <TruncatedText
                className='ml-20 w-[20px]'
                maxNum='3'
                text={employee.employee_working_hours}
              />
              <TruncatedText className='ml-36 w-[20px]' maxNum='3' text={employee.employee_zone} />
              <TruncatedText
                className='ml-36 w-[20px]'
                maxNum='3'
                text={employee.employee_trainings_completed}
              />

              <TruncatedText
                className='w-[20px]'
                maxNum='3'
                text={employee.employee_trainings_total}
              />
            </li>
          )
        })}
        <Pagination
          className='absolute right-16 bottom-10'
          showQuickJumper
          onChange={(value: any) => setPage(value)}
          pageSize={postPerPage}
          total={total}
          simple
          onShowSizeChange={onShowSizeChange}
          current={page}
        />
      </ul>
    </div>
  )
}

export default Employyes
