// import { getEnvironmentData } from 'worker_threads';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ApiBaseUrl } from 'app/environment'
import axios from 'axios'
import axiosInstance from 'axiosInstance'

interface initialStateType {
  data: any[]
  loading: boolean
  error: null | any
}

const initialState: initialStateType = {
  data: [],
  loading: false,
  error: null,
}

export const getEnvData = createAsyncThunk(
  'analyticsEnvSlice/getEnvironmentData',
  async (id: string, { rejectWithValue }) => {
    // console.log('id >', id)

    try {
    //   const ida = '636b981a9410a61ab2f848c5'
      const url = `/api/project/${id}/analytics/hourly`
      const response = await axiosInstance.get(url)
      return response.data
    } catch (error: any) {
        return rejectWithValue(error.message);
    }
  },
)

const analyticsEnvSlice = createSlice({
  name: 'analyticsEnv',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEnvData.pending, (state, action) => {
        state.loading = true
        state.error = null
      })
      .addCase(getEnvData.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = null
      })
      .addCase(getEnvData.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload; // Now, the payload contains the error message
      })
  },
})

export default analyticsEnvSlice.reducer
