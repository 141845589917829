import Loader from '@components/reusable/loader/Loader'
import React, { useState } from 'react'

function Example() {
  const [selectedImage, setSelectedImage] = useState(null)

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0]
    setSelectedImage(file)
  }

  return (
    <div className='w-full h-screen bg-[#00020]'>
     <Loader/>
    </div>
  )
}

export default Example
