import Footer from '@components/reusable/footer/Footer'
import Header from '@components/reusable/header/Header'
import Sidebar from '@components/reusable/sidebar/Sidebar'
import Breadcrumbs from '@components/reusable/breadcrums/Breadcrumbs'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { getParties } from 'feature/partiesSlice'
import TabsPanel from '@components/analyticsComponents/TabsPanel'
import clsx from 'clsx'
import personImg from 'images/navbar/person.svg'
import SubcontractorsTabContent from '@components/contractorsComponents/SubcontractorsTabContent'
import ConsultansTabContent from '@components/contractorsComponents/ConsultansTabContent'
import NonCompanyPersonnelTabContent from '@components/contractorsComponents/NonCompanyPersonnelTabContent'
import AddContractorModal from '@components/contractorsComponents/AddContractorModal'

// tabs
const tabs = [
  {
    id: 0,
    name: 'Subcontractors',
    img: personImg,
  },
  {
    id: 1,
    name: 'Consultants',
    img: personImg,
  },
  {
    id: 2,
    name: 'Non-Company Personnel',
    img: personImg,
  },
]
const ContractorsPage = () => {
  const dispatch: any = useDispatch()
  // console.log('new code')
  const [activeTabId, setActiveTabId] = useState<number>(tabs[0].id)
  const [isOpen, setIsOpen] = useState(false)
  const parties = useSelector((state: any) => state.parties.data)
  // console.log('ContractorsPage ~ parties >', parties)
  const selectedProject = useSelector((state: any) => state.projects.selectedProject?.comp_prj_id)

  const subcontractors = useMemo(() => {
    if (Array.isArray(parties)) {
      return parties.filter((obj: any) => obj.prj_collab_type === 'subcontractor')
    }
    return []
  }, [parties])

  const consultants = useMemo(() => {
    if (Array.isArray(parties)) {
      return parties.filter((obj: any) => obj.prj_collab_type === 'consultant')
    }
    return []
  }, [parties])

  const nonCompanyPersonnels = useMemo(() => {
    if (Array.isArray(parties)) {
      return parties.filter((obj: any) => obj.prj_collab_type === 'non-company')
    }
    return []
  }, [parties])

  useEffect(() => {
   
    dispatch(getParties(selectedProject))
  }, [selectedProject])

  return (
    <div className='flex font-inter h-screen w-screen text-[#374151] '>
      <Sidebar />
      <div className='flex flex-col w-[calc(100%-330px)]  relative left-[330px] h-screen'>
        <Header />

        <main className='flex-auto flex flex-col '>
          <div className='w-full max-w-[1200px] mx-auto my-0'>
            <div className='my-12'>
              <Breadcrumbs items={['Projects', 'Contractors']} />
            </div>
            <div>
              <AddContractorModal isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
            <div>
              <div className='mb-12'>
                <TabsPanel tabs={tabs} activeTabId={activeTabId} setActiveTabId={setActiveTabId} />
              </div>
              <div className={clsx('w-full', activeTabId === 0 ? 'block' : 'hidden')}>
                <SubcontractorsTabContent
                  activeTabId={activeTabId}
                  subcontractors={subcontractors}
                />
              </div>

              <div className={clsx('w-full', activeTabId === 1 ? 'block' : 'hidden')}>
                <ConsultansTabContent activeTabId={activeTabId} consultants={consultants} />
              </div>
              <div className={clsx('w-full', activeTabId === 2 ? 'block' : 'hidden')}>
                <NonCompanyPersonnelTabContent
                  activeTabId={activeTabId}
                  nonCompanyPersonnels={nonCompanyPersonnels}
                />
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </div>
  )
}

export default ContractorsPage
